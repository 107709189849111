<template>
    <div class="webinar-registration">
        <Header isNotFixed="true"></Header>
        <WebinarRegistrtionHeader></WebinarRegistrtionHeader>
        <WebinarDescr></WebinarDescr>
        <div class="webinar-registration__cloud-header">
            <img src="/images/registration-webinar/light-blue-cloud-header.svg">
        </div>
        <WelcomeLandingAudience></WelcomeLandingAudience>
        <RegistrationStartCounter secondary="true"></RegistrationStartCounter>
        <div class="webinar-registration__cloud-header">
            <img src="/images/registration-webinar/light-blue-cloud-header.svg">
        </div>
        <RegistrationReviews></RegistrationReviews>
        <WelcomeLandingAuthor></WelcomeLandingAuthor>
        <div class="adv-section">
            <img src="/images/registration-webinar/adv-section.png" width="128px">
            <div class="adv-section__text">
                На вебинар уже записалось
                <span class="purple">более 2000</span> человек.
                Все участники получат ценные бонусы и подарки от автора
            </div>
        </div>
        <SelectTime></SelectTime>
        <div class="webinar-registration__cloud-header">
            <img src="/images/registration-webinar/light-blue-cloud-header.svg">
        </div>
        <WelcomeLandingFAQ></WelcomeLandingFAQ>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import WelcomeLandingAudience from '@/components/secondary-pages/welcome-landing/WelcomeLandingAudience.vue';
import WelcomeLandingAuthor from '@/components/secondary-pages/welcome-landing/WelcomeLandingAuthor.vue';
import WelcomeLandingFAQ from '@/components/secondary-pages/welcome-landing/WelcomeLandingFAQ.vue';
import SelectTime from '@/components/secondary-pages/webinar-registration/SelectTime.vue';
import WebinarRegistrtionHeader from '@/components/secondary-pages/webinar-registration/WebinarRegistrtionHeader.vue';
import WebinarDescr from '@/components/secondary-pages/webinar-registration/WebinarDescr.vue';
import RegistrationReviews from '@/components/secondary-pages/webinar-registration/RegistrationReviews.vue';
import RegistrationStartCounter from '@/components/secondary-pages/webinar-registration/RegistrationStartCounter.vue';

export default {
  name: 'BaseWebinarRegistration',
  components: {
    Header,
    WelcomeLandingAudience,
    WelcomeLandingAuthor,
    SelectTime,
    WelcomeLandingFAQ,
    Footer,
    WebinarRegistrtionHeader,
    WebinarDescr,
    RegistrationReviews,
    RegistrationStartCounter,
  },
  data() {
    return {
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  mounted() {
  },
  methods: {
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";

body {
    background: red;
}

.adv-section {
    margin: 20px 0;
    text-align: center;
    padding: 0 24px;

    &__text {
        line-height: 28px;
        margin-top: 25px;
        color: #447DF3;
        font-size: 20px;
        font-weight: 500;

        span.purple {
            color: #CC4FC7;
        }
    }
}

.webinar-registration {
    background: $white;

    .base-header {
        background: $white;
    }

    &__cloud-header {
        img {
            max-width: 100%;
            display: block;
        }
    }
}
</style>
