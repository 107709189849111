<template>
    <div class="single-payment-section"  v-if="hasPayed || !hideNotPayed">
        <div :id="sectionID"
             class="landing-title-group__hidden-marker"
        ></div>
        <div class="module-card__section">
            <div class="module-card__content">

                <div class="module-card__title">{{data.singleTitle}}</div>

                <template>
                    <div class="module-card__list">
                      <template v-if="isSingleMeditation">
                          <CartItem
                            v-for="(meditation, index) in finalNoPayedMeditations"
                            :meditation="meditation"
                            :hideNotPayed='hideNotPayed'
                            :key="index"
                          ></CartItem>
                      </template>

                      <template v-if="isCabinet">
                        <CartItem
                          v-for="(meditation, index) in finalNoPayedMeditations"
                          :meditation="meditation"
                          :hideNotPayed='hideNotPayed'
                          :key="index"
                        ></CartItem>
                      </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import CartItem from '@/components/secondary-pages/welcome-landing/CartItem.vue';
import axios from 'axios';

export default {
  name: 'ModulePaymentItem',
  props: {
    data: Object,
    dataKey: Number,
    hideNotPayed: Boolean,
    hidePlayer: Boolean,
  },
  components: {
    CartItem,
  },
  data() {
    return {
      complete: false,
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      isErrorVisible: false,
      isViewContentSended: [],
    };
  },
  computed: {
    hasPayed() {
      let hasPayed = false;

      this.data.list.forEach((item) => {
        if (item.isPayed) {
          hasPayed = true;
        }
      });

      return hasPayed;
    },
    firstItemCount() {
      return this.$route.name === 'New' ? 3 : 0;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isFemaleSexuality() {
      return this.dataKey === 3;
    },
    saleCoof() {
      if (this.dataKey === 3) {
        return 1.538461;
      }

      return 1;
    },
    sectionID() {
      let id = '';
      if (this.dataKey === 0) {
        id = 'pregnancyPlanning';
      }
      if (this.dataKey === 1) {
        id = 'preparingForPregnancyPlanning';
      }
      if (this.dataKey === 2) {
        id = 'firstStepsBeforePregnancyPlanning';
      }
      if (this.dataKey === 3) {
        id = 'femaleSexuality';
      }

      return id;
    },
    finalNoPayedMeditations() {
      if (this.isSingleMeditation) {
        return this.data.list.filter((data) => data.type !== 'webinar');
      }

      return this.data.list;
    },
    finalNoPayedWebinars() {
      return this.data.list.filter((data) => data.type !== 'mediatation');
    },
    isSingleMeditation() {
      return this.$route.name === 'Finalprice';
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    priceBaseSale() {
      return Math.round(this.data.priceForPending / this.saleCoof);
    },
    priceBase() {
      return this.data.priceForPending;
    },
    priceForPending() {
      return this.data.priceForPending;
    },
    isSettingsReady() {
      return this.$store.state.general.isSettingsReady;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  beforeDestroy() {
    this.$store.commit('setPlayerDescr', '');
  },
  methods: {
    priceWithSale(price) {
      return price * 0.5;
    },
    showType(type) {
      if (type === 'mediatation') {
        return 'Медитация';
      }

      return 'Вебинар';
    },
    getUrl(groupName, url) {
      return `/meditation-group/${groupName}/${url}`;
    },
    checkIsViewContentSended(id) {
      let isInArray = false;

      this.isViewContentSended.forEach((itemId) => {
        if (id === itemId) {
          isInArray = true;
        }
      });

      return isInArray;
    },
    showReviewsDialog(groupID, medID, id, price) {
      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('setCurrentTemplate', 'review');
      this.$store.commit('showDialog');

      if (!this.checkIsViewContentSended(id)) {
        this.isViewContentSended.push(id);
        const currentUrl = this.$store.getters.getLocalUrl;
        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));

        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'ViewContent', {
            content_ids: [id.toString()],
            currency: 'USD',
            content_type: 'product',
            value: price,
          }, {
            eventID: initiateEventID,
          });
        }

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: price,
            eventName: 'ViewContent',
            email: this.getUserMail,
            id: initiateEventID,
          });
      }
    },
    isInCart(id) {
      return this.cartList.includes(id);
    },
    checkVisibility(type) {
      if (this.isSingleMeditation && type === 'webinar') {
        return false;
      }

      return true;
    },
    addToCart(id, price) {
      this.$store.commit('addToCart', id);

      this.$store.commit('setCurrentTemplate', 'cart-sale');
      this.$store.commit('showDialog');

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'AddToCart', {
          content_ids: [id.toString()],
          content_type: 'product',
          currency: 'USD',
          value: price,
        }, {
          eventID: initiateEventID,
        });
      }

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'AddToCart',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
    getUserData() {
      this.$store.commit('setUpdateMeditationSource', 'cabinet');
      this.$store.dispatch('updateMeditations');
    },
    getMainColor(groupID) {
      let mainColor = '';

      this.meditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      return mainColor;
    },
    showPreview(id) {
      let groupID = '';
      let medID = '';
      let price = 0;

      this.meditationsList.forEach((item) => {
        item.list.forEach((itemList) => {
          if (itemList.id === id) {
            groupID = item.groupName;
            medID = itemList.url;
            price = itemList.discrPrice;
            this.$store.commit('setPlayerDescr', itemList.discrText);
          }
        });
      });

      document.documentElement.style.setProperty('--main-color', this.getMainColor(groupID));

      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('showPlayer');

      if (!this.checkIsViewContentSended(id)) {
        this.isViewContentSended.push(id);

        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
        const currentUrl = this.$store.getters.getLocalUrl;

        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'ViewContent', {
            content_ids: [id.toString()],
            currency: 'USD',
            content_type: 'product',
            value: price,
          }, {
            eventID: initiateEventID,
          });
        }

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: price,
            eventName: 'ViewContent',
            email: this.getUserMail,
            id: initiateEventID,
          });
      }
    },
    startPayment(priceToPay, priceOld, type, moduleName) {
      if (this.priceBaseSale === 0) {
        this.isErrorVisible = true;
        return false;
      }

      this.$store.commit('markSingleMeditationsForPay', moduleName);
      this.$store.commit('setSinglePagePayment', true);
      this.$store.commit('setLandingPaymentPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentFullPrice', priceOld);
      this.$store.commit('showPayment');

      return true;
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

 .single-payment-section {
     position: relative;
     width: 100%;

     .landing-title-group__hidden-marker {
         top: -10px;
     }
 }

 .single-payment-section {
     padding-top: 20px;
 }

.module-card {
    &__error {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        margin: 25px 0;
        color: $main-red;
    }

    &__content {
        background: $white;
        border-radius: 10px 10px 0 0;
        position: relative;
        z-index: 8;
    }

    &__avialible {
        position: absolute;
        height: 31px;
        line-height: 31px;
        color: $white;
        left: 0;
        top: -15px;
        padding: 0 24px;
        border-radius: 0 16px 16px 0;
        font-size: 14px;
        font-weight: 500;
        background: #1AC655;
    }

    &__item {
        box-sizing: border-box;
        overflow: hidden;
        margin: 10px 0 20px 0;
        width: 100%;
        position: relative;

        a:visited {
            color: $main-blue;
        }

        &.isPayed {
            background: #F3F5F9;
        }

        &:not(.isPayed).isNotPayedHidden {
            display: none;
        }

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: 10px 0 10px 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 5px;
        padding-left: 5px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }
    }

    &__item {
        background: #EEF2FF;
        border-radius: 10px;
        padding: 13px 22px;
        margin: 7px 0;
    }

    &__price-cover {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    &__price-future {
        color: $main-red;
        font-size: 19px;
    }

    &__price-base {
        text-decoration: line-through;
        margin-left: 10px;
    }

    &__price {
        font-size: 16px;
        font-weight: 600;
        color: $main-blue;
        display: flex;
        align-items: center;
    }

    &__actions {
        display: flex;

        & > button {
            margin-left: 10px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__item-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 22px;
        color: $black!important;
    }

    &__item-time {
        font-size: 12px;
        line-height: 18px;
        color: #908C8C;
        margin-bottom: 5px;
    }

    &__item-reviews {
        font-size: 12px;
        line-height: 18px;
        color: $main-blue;
        text-decoration: underline;
    }

    &__list {
        overflow: hidden;
        transition: all 200ms;
        max-height: 3000px;
        padding: 15px 0 0 0;

        @media only screen and (max-width: 767px) {
            margin-bottom: 1px;
        }

        &.hidden {
            max-height: 0;
            padding: 0 0 0 10px;
        }

        .underlined {
            .module-card__list-text {
                text-decoration: underline;
            }
        }

        .es-checkbox-label {
            width: 20px;
            height: 20px;
            border: solid $main-blue 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1px;

            @media only screen and (max-width: 767px) {
                top: 4px;
            }

            &.isChecked {
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    background: url("/images/landing/check-icon-blue.svg") no-repeat 50% 50%;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
        }

        &.isCheckList {
            li {
                cursor: pointer;

                &:before {
                    display: none;
                }
            }
        }

        li {
            list-style: none;
            padding-left: 35px;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 20px;

            a {
                color: $main-blue;
            }

            @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .light {
                font-weight: 400;
                line-height: 30px;

                @media only screen and (max-width: 767px) {
                    line-height: 26px;
                }
            }

            &:before {
                position: absolute;
                width: 20px;
                height: 20px;
                background: url("/images/check-icon-green.svg");
                content: '';
                left: 0;
                top: 4px;
            }
        }
    }
}
</style>
