<template>
    <div class="welcome-audience"  :class="{'bright-blue': isLandingRegistration}">
        <div class="landing-title-group">
            <div id="audienceSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <template v-if="isWebinarRegistration">
                <div class="landing-title-group__small">РЕГИСТРАЦИЯ НА ВЕБИНАР</div>
                <div class="landing-title-group__mid">Для кого вебинар</div>
            </template>
            <template v-else>
                <div class="landing-title-group__small">МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ</div>
                <div class="landing-title-group__mid">Для кого курс</div>
            </template>

        </div>

        <div class="welcome-audience__slider glide">
            <div data-glide-el="track" class="glide__track">
                <div class="glide__slides">
                    <div class="welcome-audience__slider-item glide__slide">
                        <div class="welcome-audience__slider-image">
                            <img src="/images/landing/audience_1.jpg">
                        </div>
                        <div class="welcome-audience__slider-text">
                            Для тех, у кого никогда
                            <span class="blue">не было беременности</span>
                            и малыш не приходит
                        </div>
                    </div>

                    <div class="welcome-audience__slider-item glide__slide">
                        <div class="welcome-audience__slider-image">
                            <img src="/images/landing/audience_2.jpg">
                        </div>
                        <div class="welcome-audience__slider-text">
                            Для тех, у кого есть дети, но не получается
                            <span class="blue">повторно забеременеть</span>
                        </div>
                    </div>

                    <div class="welcome-audience__slider-item glide__slide">
                        <div class="welcome-audience__slider-image">
                            <img src="/images/landing/audience_3.jpg">
                        </div>
                        <div class="welcome-audience__slider-text">
                            Для тех, кто <span class="blue">готовится к ЭКО</span>
                            первый раз или проходит ЭКО повторно
                        </div>
                    </div>

                    <div class="welcome-audience__slider-item glide__slide">
                        <div class="welcome-audience__slider-image">
                            <img src="/images/landing/audience_4.jpg">
                        </div>
                        <div class="welcome-audience__slider-text">
                            <span class="blue">Кто забеременел</span>,
                            но не чувствует себя в безопасности
                        </div>
                    </div>

                    <div class="welcome-audience__slider-item glide__slide">
                        <div class="welcome-audience__slider-image">
                            <img src="/images/landing/audience_5.jpg">
                        </div>
                        <div class="welcome-audience__slider-text">
                            Для тех, кто хочет
                            <span class="blue">отпустить свои страхи</span>
                            и жить в гармонии и любви
                        </div>
                    </div>

                </div>
            </div>
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>

                <div class="welcome-audience__dots glide__bullets" data-glide-el="controls[nav]">
                    <button class="glide__bullet" data-glide-dir="=0"></button>
                    <button class="glide__bullet" data-glide-dir="=1"></button>
                    <button class="glide__bullet" data-glide-dir="=2"></button>
                    <button class="glide__bullet isMobile isTablet" data-glide-dir="=3"></button>
                    <button class="glide__bullet isMobile" data-glide-dir="=4"></button>
                </div>

                <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'WelcomeLandingAbout',
  data() {
    return {
      glide: false,
    };
  },
  computed: {
    isLandingRegistration() {
      return this.$route.name === 'mnj-webinar';
    },
    isWebinarRegistration() {
      return this.$route.name === 'mnj-webinar';
    },
  },
  methods: {
  },
  mounted() {
    this.glide = new Glide('.glide', {
      perView: 3,
      gap: 20,
      peek: { before: 0, after: 0 },
      animationDuration: 300,
      startAt: 0,
      rewind: false,
      bound: true,
      breakpoints: {
        600: {
          perView: 2,
          peek: { before: 0, after: 100 },
        },
        460: {
          perView: 1,
          peek: { before: 0, after: 100 },
        },
      },
    }).mount({ Controls, Swipe, Breakpoints });
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";

.welcome-audience {
    padding: 50px 24px;
    background: $light-blue;

    &.bright-blue {
        background: #edf1ff;
        padding: 1px 24px 50px 24px;

        .landing-title-group {
            margin-top: 10px;
        }

        .landing-title-group__mid {
            color: #447DF3;
        }

        .welcome-audience__slider-item:first-child,
        .welcome-audience__slider-item:nth-child(4){
            .welcome-audience__slider-image {
                border-radius: 50%;
            }
        }

        .welcome-audience__slider-image {
            border: dashed 1px #0D5AF9;
            display: inline-block;
            padding: 6px;
            max-width: 75%;
            border-radius: 10px;

            img {
                display: block;
                max-width: 100%;
            }
        }

        .welcome-audience__dots .glide__bullet {
            border: 5px solid #edf1ff;
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 20px 24px;
    }

    .glide__arrows {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .glide__arrow {
            background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 10px;
            border: 0;
            position: relative;
            top: 1px;
            flex-shrink: 0;
            cursor: pointer;

            @media only screen and (max-width: 767px) {
                display: none;
            }

            &--right {
                transform: rotate(180deg);
            }
        }
    }

    &__dots {
        text-align: center;
        margin: 0 20px;

        .glide__bullet {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 2px;
            background: rgba($main-blue, 0.1);
            border: 5px solid $light-blue;
            padding: 0;
            box-sizing: unset;
            cursor: pointer;

            &.isMobile {
                display: none;

                @media only screen and (max-width: 460px) {
                    display: inline-block;
                }
            }

            &.isTablet {
                display: none;

                @media only screen and (max-width: 600px) {
                    display: inline-block;
                }
            }
        }

        .glide__bullet--active {
            background: $main-blue;
        }
    }

    &__slider-image {
        margin-bottom: 10px;

        img {
            max-width: 70%;
            transform: translate3d(0,0,0);

            @media only screen and (max-width: 767px) {
                max-width: 100%;
                max-height: 220px;
            }
        }
    }

    &__slider-text {
        font-family: $textFont;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__slider-item {
        width: 215px;
        margin-right: 25px;

        &:nth-child(1) {
            .welcome-audience__slider-image img{
                border-radius: 50%;
            }
        }

        &:nth-child(4) {
            .welcome-audience__slider-image img{
                border-radius: 50%;
            }
        }
    }
}
</style>
