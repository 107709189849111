<template>
    <div class="recomendations-list">
        <div class="recomendations-list__title">Вас может заинтересовать:</div>
        <div class="recomendations-list__cover">
            <RecomendationItem
                    v-for="(item, index) in recomendationsList"
                    :key="index"
                    :meditation="item"
                    :dataKey="index"
            ></RecomendationItem>
        </div>
    </div>
</template>

<script>
import RecomendationItem from '@/components/cabinet/RecomendationItem.vue';

export default {
  name: 'Recomendations',
  components: {
    RecomendationItem,
  },
  data() {
    return {
      playerDescr: '',
    };
  },
  computed: {
    isPlayerVisible() {
      return this.$store.state.player.isVisible;
    },
    newFullMeditationInfo() {
      return this.$store.state.meditations.newMeditationsList;
    },
    fullMeditationInfo() {
      return this.$store.state.meditations.meditationsList;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    recomendationsList() {
      const list = [];

      this.fullMeditationInfo.forEach((item) => {
        item.list.forEach((itemMed) => {
          if (!itemMed.isPayed) {
            list.push(itemMed);
          }
        });
      });

      this.newFullMeditationInfo.forEach((item) => {
        item.list.forEach((itemMed) => {
          if (!itemMed.isPayed) {
            list.push(itemMed);
          }
        });
      });

      list.sort((a) => {
        if (a.isNew) {
          return -1;
        }

        return 0;
      });

      return list;
    },
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style  lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/landing";
 @import "../../assets/scss/common/forms";

.recomendations-list {
    padding: 30px 0;
    background: #f6f8fe;
    padding-top: 25px;
    margin-bottom: 25px;

    .recomendations-list__cover {
        padding-right: 15px;
    }

    &__cover {
        margin-left: 13px;
        margin-top: 25px;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &__title {
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        padding: 0 0 0 13px;
    }
}
</style>
