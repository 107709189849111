<template>
    <div class="payment-questions">
        <div class="landing-content">
            <div class="guidebook-title">
                ЕСЛИ ВОЗНИКНУТ ВОПРОСЫ ПО ОПЛАТЕ
            </div>

            <div class="payment-questions__content-center margin-bottom">
                <p>
                    Напишите нам в бот, и служба заботы поможет<br>
                    во всём разобраться
                </p>

                <button class="es-button blue">Нужна помощь</button>
            </div>

            <div class="guidebook-title">
                Какие есть способы оплаты?
            </div>

            <div class="payment-questions__content-center">
                <p>
                    Мы постарались сделать как можно больше вариантов,
                    чтобы каждый желающий из любой страны мог
                    без проблем оплатить курс.
                </p>
            </div>

            <div class="payment-questions__content-block">
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>Оплата через платежную форму Тинькофф.</b></p>
                        <p>Можно оплатить через TinkoffPAY без ввода данных карты или
                            ввести данные карты любого российского банка.
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-1.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>Оплата через платежную форму Сбербанк.</b></p>
                        <p>
                            Можно оплатить через SberPay
                            без ввода данных карты или ввести
                            данные карты любого российского банка.
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-2.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>Система быстрых платежей СБП.</b></p>
                        <p>
                            Достаточно просто отсканировать QR-код
                            — оплата откроется в приложении
                            вашего банка. Не нужно вводить данные карты.
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-3.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__content-title">
                    Для карт выпущенных в РФ:
                </div>
            </div>

            <div class="payment-questions__content-block">
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>Оплата в тенге через платежную форму JetPay.</b></p>
                        <p>Подходит для Казахстана, Беларуси и других стран СНГ.
                            Стоимость конвертируется в тенге по
                            курсу на день оплаты.
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-4.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p>
                            <b>Оплата в долларах через
                                платежную систему Stripe.</b>
                        </p>
                        <p>
                            Подходит для Украины, стран ЕС,
                            Америки, Азии и других. Не подходит для карт,
                            выпущенных в России. Для Беларуси оплаты
                            проходят по картам банков, на которые не
                            наложены санкции.
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-5.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>
                            Оплата в долларах через
                            платежную систему ИнтеллектМани.
                        </b></p>
                        <p>
                            Подходит для всех карт,
                            кроме выпущенных в РФ.
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-6.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>
                            Оплата через платежную систему PayPal.
                        </b></p>
                        <p>
                            Подходит для всех, у кого есть PayPal
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-7.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__content-title">
                    Для карт выпущенных не в РФ:
                </div>
            </div>

            <div class="guidebook-title">
                НЕ ПРОХОДИТ ОПЛАТА ИЛИ<br>
                ВОЗНИК ВОПРОС?
            </div>

            <div class="payment-questions__content-center">
                <p>
                    Напишите в нашу
                    <a href="https://t.me/helpElenaSiaglo_bot">Службу заботы</a>
                    ваш вопрос
                </p>
                <p>
                    <b>Служба заботы отвечает каждый день</b><br>
                    с 9:00 до 19:00 мск. Вам ответят в течение 5-10 минут.
                </p>
            </div>

            <div class="payment-questions__content-sided">
                <div class="payment-questions__left-side">
                    <b>ВНЕСЛИ ПРЕДОПЛАТУ 5000 ₽ <br>
                        КАК ДОПЛАТИТЬ ОСТАТОК?</b>
                </div>
                <div class="payment-questions__right-side">
                    <p>
                        Курс стартует 03 апреля в 11:00 мск.
                        Доплатить остаток можно <br>
                        до 02 апреля 23:59 <br>
                        Ссылка на доплату уже есть в вашем почтовом ящике.
                    </p>
                    <p>
                        <b>НЕ НАШЛИ ССЫЛКУ НА ОПЛАТУ?</b> <br>
                        <a href="https://t.me/helpElenaSiaglo_bot">Напишите в Службу заботы.</a>
                    </p>
                </div>
            </div>

            <div class="guidebook-title">
                ЕСЛИ ВОЗНИКНУТ ВОПРОСЫ<br>
                ПО РАССРОЧКЕ
            </div>

            <div class="payment-questions__content-center">
                <p>
                    Беспроцентная рассрочка на срок до <b>24 месяцев</b>
                    без первоначального взноса. <br>
                    Воспользоваться рассрочкой могут только граждане РФ.
                </p>
                <p>
                    Если вы не из РФ (СНГ, Европа, другие страны),
                    вы можете воспользоваться нашей <br>
                    доверительной
                    рассрочкой на <b>3 месяца</b>. Для этого напишите в
                    <a href="https://t.me/helpElenaSiaglo_bot">Службу заботы</a>,
                    и наши менеджеры расскажут,
                    как это сделать.
                </p>
            </div>

            <div class="payment-questions__content-block">
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>
                            Беспроцентная рассрочка от банка Тинькофф.
                            Доступна на 6, 12 и 24 месяца без
                            первоначального взноса.
                        </b></p>
                        <p>
                            Также доступен вариант покупки в кредит,
                            в котором покупатель сам может гибко
                            выбрать условия (срок или размер платежа).
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-8.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>
                            Беспроцентная рассрочка «Покупай со Сбером»
                            от Сбербанка. Доступна
                            на 6, 12 и 24 месяца без первоначального взноса.
                        </b></p>
                        <p>
                            Также доступен вариант
                            покупки в кредит, в котором покупатель
                            сам может гибко выбрать условия
                            (срок или размер платежа).
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-9.jpg" width="330">
                    </div>
                </div>
                <div class="payment-questions__hr">
                    <span></span>
                </div>
                <div class="payment-questions__content-article">
                    <div>
                        <p><b>
                            Беспроцентная рассрочка «Всегда.Да»
                            от Хоум Кредит Банка. Доступна на
                            6, 12 и 24 месяца без первоначального взноса.
                        </b></p>
                        <p>
                            Также доступен вариант покупки в
                            кредит, в котором покупатель сам
                            может гибко выбрать условия
                            (срок или размер платежа).
                        </p>
                    </div>
                    <div class="payment-questions__content-article-image">
                        <img src="/images/guidebook/shedule-10.jpg" width="330">
                    </div>
                </div>
            </div>

            <div class="guidebook-title">
                ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ ПРО<br>
                ДОВЕРИТЕЛЬНУЮ РАССРОЧКУ
            </div>

            <div class="payment-questions__content-center thin">
                <p class="nomargin">
                    <b>
                        Мы можем предоставить нашу внутреннюю
                        доверительную рассрочку:
                    </b>
                </p>

                <ul>
                    <li>
                        гражданам РФ, если все три
                        вышеперечисленных банка
                        вам отказали предоставить рассрочку
                    </li>
                    <li> гражданам всех других стран.</li>
                </ul>

                <p>
                    <b>Какие условия внутренней рассрочки?</b>
                    Вся сумма вашего заказа будет разбита
                    на 3 равные части. Первую часть вы
                    должны внести сразу. Вторую часть — через месяц.
                    Третью часть — через два месяца. В случае просрочки
                    второго или третьего платежа, доступ к курсу
                    будет автоматически закрыт.
                    После оплаты доступ снова откроется.
                </p>
                <p>
                    Чтобы купить курс по внутренней
                    доверительной рассрочке, напишите
                    в Службу заботы и наши менеджеры
                    вышлют ссылку на оплату первой части.
                </p>

                <div class="payment-questions__hr">
                    <span></span>
                </div>
            </div>

            <div class="guidebook-title mobile-center">
                ДО ВСТРЕЧИ НА КЛЮЧЕ К МАТЕРИНСТВУ!<br>
                ЕЛЕНА И КОМАНДА
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PaymentQuestions',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.payment-questions {
    padding: 10px 0;

    &__content-center {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;

        @media only screen and (max-width: 500px) {
            font-size: 14px;
            text-align: left;
        }

        &.margin-bottom {
            margin-bottom: 100px;

            @media only screen and (max-width: 768px) {
                margin-bottom: 50px;
            }
        }

        p {
            margin: 30px 0;

            &.nomargin {
                margin-bottom: 0;
            }
        }

        b {
            font-weight: 600;
        }

        a {
            color: #447DF3;
        }

        .es-button {
            border-radius: 20px;
            max-width: 200px;
            display: inline-block;
            height: 40px;
            line-height: 40px;
            font-family: 'Montserrat';
        }

        ul {
            padding-left: 10px;
        }

        li {
            list-style: outside;
            margin: 2px 0 0 10px;
            position: relative;
        }

        &.thin {
            max-width: 630px;
            margin: auto;
            text-align: left;
        }

        .payment-questions__hr {
            margin: 80px 0 40px 0;
        }
    }

    &__content-article {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 50px 50px 50px 30px;
        width: 100%;
        box-sizing: border-box;

        &:first-child {
            padding-top: 80px;

            @media only screen and (max-width: 768px) {
                padding-top: 50px;
            }
        }

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            padding-top: 10px;
        }

        & > div {
            width: 50%;

            @media only screen and (max-width: 768px) {
                width: 100%;
                padding-top: 10px;
            }

            @media only screen and (max-width: 500px) {
                padding-top: 1px;
            }
        }
    }

    &__content-article-image {
        text-align: right;

        @media only screen and (max-width: 768px) {
            text-align: center;

            img {
                max-width: 100%;
            }
        }
    }

    &__content-title {
        width: 700px;
        height: 76px;
        background: #292935;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 15px;
        line-height: 76px;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        margin: -40px auto 30px -350px;
        color: $white;
        position: absolute;
        left: 50%;
        top: 0;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
            height: auto;
            margin-top: -24px;
            line-height: 24px;
            padding: 15px 15px;
            box-sizing: border-box;
            width: 80%;
            left: 50%;
            margin-left: -40%;
        }
    }

    &__content-block,
    &__content-sided {
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 20px;
        position: relative;
        margin: 150px 0 50px 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 120px;
        background: $white;

        @media only screen and (max-width: 500px) {
            font-size: 14px;
            margin-bottom: 70px;
            margin-top: 70px;
        }

        b {
            font-weight: 600;
        }

        p {
            margin: 20px 0;

            &.nomargin {
                margin: 0;
            }
        }

        ul {
            padding-left: 10px;
        }

        li {
            list-style: outside;
            margin: 2px 0 0 10px;
            position: relative;
        }

        a {
            color: #447DF3;
        }
    }

    &__content-sided {
        display: flex;
        align-items: center;
        margin: 80px 0;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            margin: 50px 0;
        }

        & > div {
            width: 50%;
            padding: 50px 30px;

            @media only screen and (max-width: 768px) {
                width: 100%;
                box-sizing: border-box;
                padding: 30px;
            }

        }
    }

    &__right-side {
        background: #e4e8f5;
        border-radius: 0 20px 20px 0;

        @media only screen and (max-width: 768px) {
            border-radius: 0 0 20px 20px;
        }
    }

    &__hr {
        position: relative;
        text-align: center;

        span {
            width: 8px;
            height: 8px;
            display: inline-block;
            background: #D1D6E7;
            border-radius: 50%;
            position: relative;
            top: -15px;
            left: -1px;
        }

        &:before,
        &:after {
            width: 46%;
            position: absolute;
            height: 1px;
            background: #D1D6E7;
            content: '';
            left: 0;
            top: 0;
        }

        &:after {
            left: auto;
            right: 0;
        }

        @media only screen and (max-width: 500px) {
            span  {
                height: 0;
            }

            &:before,
            &:after {
                width: 50%;
            }
        }
    }
}
</style>
