<template>
    <div class="consultant" :style="{background: info.bgColor} ">
        <div :id="info.markerId"
             class="landing-title-group__hidden-marker"
        ></div>
        <div class="consultant__content">
            <div class="consultant__image">
                <img :src="info.image">
            </div>
            <div class="consultant__title-block">
                <div class="consultant__title">{{info.fullName}}</div>
                <div class="consultant__position" v-html="info.position"></div>
            </div>
            <div class="consultant__descr" v-html="info.mainDescr"></div>
            <div class="consultant__gift" v-if="info.isFreeMeditation">
                + 2 любых медитации<br> в подарок
            </div>
            <div class="consultant__price-old"
                 v-if="info.markerId === 'elenaScrollMarker'"
            >
              {{info.oldPrice}} ₽
            </div>
            <div class="consultant__price">{{info.price}} ₽</div>
            <div class="consultant__price-descr">
                Индивидуальная консультация ({{info.consultationTime}})
            </div>
            <button class="es-button" @click="scrollToSteps()">{{info.buttonTitle}}</button>

            <div class="consultant__feedback-title">{{info.reviewTitle}}</div>

            <div class="consultant__reviews-list glide-video" :id="info.glideId">
                <div data-glide-el="track" class="glide__track">
                    <div class="glide__slides">
                        <div class="consultant__review-item"
                             v-for="(item, index) in info.reviewsList"
                             :key="index"
                        >

                            <img :src="item.link" v-if="item.type === 'image'">
                            <div class="consultant__review-item-video"
                                 v-if="item.type === 'video'"
                            >
                                <iframe
                                        width="100%"
                                        height="380"
                                        :src="getLink(item.link)"
                                        frameborder="0"
                                        modestbranding='1'
                                        allowfullscreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="glide__arrows" data-glide-el="controls">
                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>

                    <div class="consultant__dots glide__bullets" data-glide-el="controls[nav]">
                        <button class="glide__bullet"
                                v-for="(item, index) in info.reviewsList"
                                :data-glide-dir='setDotData(index)'
                                :key="index"
                        ></button>
                    </div>

                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
                </div>
            </div>
        </div>

        <div class="consultant__border"></div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'ConsultantLandingListItem',
  components: {
  },
  props: {
    info: Object,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
    setTimeout(() => {
      this.glide = new Glide(`#${this.info.glideId}`, {
        perView: 3,
        gap: 20,
        peek: { before: 0, after: 0 },
        animationDuration: 300,
        rewind: false,
        breakpoints: {
          720: {
            perView: 1,
            peek: { before: 0, after: 80 },
          },
        },
      }).mount({ Controls, Swipe, Breakpoints });
    }, 300);
  },
  methods: {
    getLink(id) {
      return `https://www.youtube.com/embed/${id}?modestbranding=1&rel=0`;
    },
    setDotData(index) {
      return `=${index}`;
    },
    scrollToSteps() {
      const hiddenElement = document.querySelector('#stepsSectionScrollMarker');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";

.consultant {
    margin: 140px 0 200px 0;
    padding: 40px 24px 40px 24px;
    background: $white;
    border-radius: 30px;
    position: relative;

    &:last-child {
        margin-bottom: 0;

        .consultant__border {
            display: none;
        }
    }

    .landing-title-group__hidden-marker {
        top: -120px;
    }

    .landing-title-group__mid {
        line-height: 26px;
        color: $main-blue;
    }

    &__content {
        position: relative;
        z-index: 2;
        text-align: center;
    }

    &__image {
        width: 230px;
        height: 230px;
        border-radius: 50%;
        border: solid 3px $white;
        margin: -150px auto 15px auto;
        overflow: hidden;

        img {
            max-width: 100%;
        }
    }

    &__title-block {
       position: relative;
       margin: 25px 0 20px 0;

        &:before {
            width: 135px;
            height: 72px;
            border: 1px solid rgba(255, 255, 255, 0.66);
            content: '';
            border-radius: 50%;
            transform: rotate(33.89deg);
            position: absolute;
            left: 50%;
            top: -10px;
            margin-left: -68px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 10px;
    }

    &__position {
        font-size: 12px;
        line-height: 120%;
    }

    &__descr {
        margin: 15px 0;
        font-size: 14px;
        line-height: 20px;
    }

    &__gift {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 25px 0 20px;
    }

    &__price {
        font-weight: 600;
        font-size: 34px;
        line-height: 120%;
        color: $main-blue;
        margin-bottom: 10px;
    }

    &__price-old {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 2px;
        text-decoration: line-through;
    }

    &__price-descr {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $main-blue;
        margin-bottom: 20px;
    }

    .es-button {
        font-weight: 500;
        font-size: 16px;
    }

    &__feedback-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        margin-top: 40px;
    }

    &__reviews-list {
        margin-top: 20px;
    }

    &__reviews-list-inc {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
    }

    &__review-item {
        width: 190px;
        margin-right: 10px;
        background: $lightGray;
        border-radius: 8px;
        height: 380px;
        flex-shrink: 0;

        img {
            max-width: 100%;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__review-item-video {
        position: relative;

        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__border {
        border-top: solid 1px $main-blue;
        width: 100px;
        margin: 50px auto 0 auto;
        position: absolute;
        bottom: -50px;
        left: 50%;
        margin-left: -50px;
    }

    .glide__arrows {
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        display: flex;

        .glide__arrow {
            background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 5px;
            border: 0;
            position: relative;
            top: 1px;
            flex-shrink: 0;
            cursor: pointer;

            &--right {
                transform: rotate(180deg);
            }
        }
    }

    &__dots {
        text-align: center;
        margin: 0 10px;

        .glide__bullet {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 2px;
            background: rgba($main-blue, 0.1);
            border: none;
            padding: 0;
            box-sizing: unset;
            cursor: pointer;
        }

        .glide__bullet--active {
            background: $main-blue;
        }

        &:last-child {
            display: none;
        }
    }
}
</style>
