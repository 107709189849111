<template>
    <div class="course-review">
        <div class="course-review__inc">
            <div class="course-review__hr">
                <div class="course-review__hr-line"></div>
                <div class="course-review__hr-dot"></div>
                <div class="course-review__hr-line"></div>
            </div>
            <div class="course-landing-title">Отзывы о работе с Еленой</div>
            <div class="course-review__descr">
                Это первый поток курса «Ключ к материнству».
                Поэтому, предлагаем вам посмотреть старые видео
                и скриншоты с отзывами, которые прислали после
                медитаций и личных терапий с Еленой.
            </div>
            <div class="course-review__descr-large">
                На этом курсе будет <strong>комплексная
                работа</strong> со всех сторон. На протяжении
                6 недель вас будут <strong>«вести за ручку»</strong>,
                давать правильные ключики,
                чтобы приблизить к заветной мечте.
                Ничего похожего раньше никто не делал.
            </div>
            <div class="course-review__list">
                <div class="course-review__list-item">
                    <div class="course-review__item-title">Зарина Адылова</div>
                    <div class="course-review__item-insta">@__zarart__</div>
                    <div class="course-review__item-content">
                        <p>Очень доверилась Елене, прошла личную терапию, слушала все медитации.</p>
                        <strong>Результат:</strong><br>
                        <ul>
                            <li>исчезли все внутренние конфликты, обиды и страхи;</li>
                            <li>прошло сильнейшее акне, от которого не могла избавиться 13 лет;</li>
                            <li>избавилась от хронического цистита;</li>
                            <li>через 2 месяца после работы с Еленой забеременела;</li>
                            <li>благополучно родила доченьку Жасмин.</li>
                        </ul>
                        <p><strong>Смотрите видео со счастливой историей Зарины:</strong></p>

                        <div class="course-review__video">
                            <iframe
                                    width="100%"
                                    height="400"
                                    src="https://www.youtube.com/embed/k16UP27_4Hg?modestbranding=1&rel=0"
                                    frameborder="0"
                                    allowfullscreen
                                    modestbranding='0'
                                    v-if="bgVideosVisability"
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div class="course-review__list-item">
                    <div class="course-review__item-title">Ирина Шавина</div>
                    <div class="course-review__item-insta">@shavina_irina</div>
                    <div class="course-review__item-content">
                        <p>
                            Не спала ночами, был сильный страх смерти и проблемы
                            с зачатием и вынашиванием.
                            Прошла личную терапию с Еленой, слушала
                            медитации:
                        </p>
                        <strong>Результат:</strong><br>
                        <ul>
                            <li>проработала много страхов и блоков;</li>
                            <li>
                                через 3 месяца после работы с
                                Еленой забеременела, старшему сыну на тот момент было 6 лет;
                            </li>
                            <li>в 39 лет снова стала мамой, благополучно родила доченьку Машу.</li>
                        </ul>
                        <p><strong>Смотрите видео со счастливой историей Ирины:</strong></p>

                        <div class="course-review__video">
                            <iframe
                                    width="100%"
                                    height="400"
                                    src="https://www.youtube.com/embed/nX-qYobw4cI?modestbranding=1&rel=0"
                                    frameborder="0"
                                    allowfullscreen
                                    modestbranding='0'
                                    v-if="bgVideosVisability"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <button @click="showAllReviews()"
                    class="es-button black es-button--bordered"
            >
                Смотреть больше видео
            </button>

            <vue-bottom-sheet ref="myBottomSheet" :swipe-able="false"
                              @opened="bottomSheetOpened()"
                              @closed="bottomSheetClosed()"
            >
                <div class="course-review__other-videos">
                    <div class="course-review__other-title">Все видео-отзывы:</div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/nX-qYobw4cI?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/UpIU7Q7US8E?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/JuJuEzNsk5k?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/526JjN_rkPU?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/31crIkI74xc?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/TLhCRFml65Y?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/iIcy3Xxxvok?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/0EN7hCgCAFg?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/D9f6MkgWM7w?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/M5JialrWd9k?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/HOhJVWdBpig?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/EBP9AjyFbcg?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/feRzvzyBcJ0?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/W22EXlKhkq8?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/HwIcELRPtBg?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/fVYZ7iPxm9o?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>
                </div>
            </vue-bottom-sheet>

            <div class="course-review__image-list">
                <div
                        class="course-review__image-item"
                        v-for="index in reviewsCount"
                        :key="index"
                        @click="showSingle(index)"
                >
                    <img :src="getLink(index)">
                </div>
            </div>

            <vue-easy-lightbox
                    escDisabled
                    moveDisabled
                    :visible="visible"
                    :imgs="imgs"
                    :index="index"
                    @hide="handleHide"
            ></vue-easy-lightbox>

            <button class="es-button black es-button--bordered" @click="tootleMore()">
                {{textMoteButton}}
            </button>
        </div>
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';

export default {
  name: 'CourseReviews',
  components: {
    VueEasyLightbox,
    VueBottomSheet,
  },
  data() {
    return {
      reviewsCount: 12,
      imgs: '',
      visible: false,
      bgVideosVisability: true,
    };
  },
  computed: {
    textMoteButton() {
      return this.reviewsCount === 12 ? 'Показать еще' : 'Скрыть';
    },
  },
  methods: {
    getLink(index) {
      return `/images/course-landing/reviews/${index}.jpg`;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showSingle(index) {
      this.imgs = `/images/course-landing/reviews/${index}.jpg`;
      this.show();
    },
    tootleMore() {
      if (this.reviewsCount !== 12) {
        this.reviewsCount = 12;
      } else {
        this.reviewsCount = 23;
      }
    },
    showAllReviews() {
      this.$refs.myBottomSheet.open();
    },
    bottomSheetOpened() {
      this.bgVideosVisability = false;
    },
    bottomSheetClosed() {
      this.bgVideosVisability = true;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-review {
    padding: 20px 23px 40px 23px;
    position: relative;
    background: $white;

    &__other-videos {
        padding: 5px 20px 20px 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__other-video-item {
        width: 47%;
        margin-bottom: 10px;
        background: rgba(0,0,0,0.05);
        border-radius: 10px;
        overflow: hidden;
        line-height: 0;
    }

    &__other-title {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 30px;
        color: #292935;
    }

    &:before {
        background: url("/images/course-landing/reviews-bg.png");
        width: 320px;
        height: 354px;
        content: '';
        position: absolute;
        z-index: 1;
        left: calc(50% - 150px);
        top: -70px;
    }

    &__inc {
        position: relative;
        z-index: 2;
    }

    &__descr {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;

        @media only screen and (min-width: 767px) {
            font-size: 14px;
            line-height: 150%;
            max-width: 530px;
            margin: 0 auto;
        }

        @media only screen and (min-width: 1024px) {
           max-width: 720px;
        }
    }

    &__descr-large {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        margin: 25px 0;

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            max-width: 530px;
            margin: 30px auto;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 720px;
            font-size: 21px;
            margin: 48px auto;
        }

        strong {
            font-weight: 600;
            color: #7D89A1;
        }
    }

    &__hr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -22px 0 30px 0;

        @media only screen and (min-width: 767px) {
            margin: 30px 0 60px 0;
        }

        &-line {
            width: calc(50% - 23px);
            background: #D1D6E7;
            height: 1px;
        }

        &-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #D1D6E7;
        }
    }

    &__list {
        margin: 40px 0;

        @media only screen and (min-width: 767px) {
            display: flex;
            justify-content: space-around;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 620px;
            margin: 60px auto 40px auto;
        }
    }

    &__list-item {
        background: #F3F6FC;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 30px;
        padding: 23px 23px 23px 50px;
        position: relative;
        color: #292935;
        margin-bottom: 30px;

        @media only screen and (min-width: 767px) {
            width: 280px;
            flex-shrink: 0;
            box-sizing: border-box;
            margin-bottom: 0;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            margin: 20px 0;
        }

        strong {
            font-weight: 500;
        }

        &:before {
            background: url("/images/course-landing/quote-icon-blue.svg");
            width: 18px;
            height: 17px;
            content: '';
            position: absolute;
            left: 18px;
            top: 28px;
        }
    }

    &__item-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;

        ul {
            margin-top: 5px;

            li {
                margin-bottom: 2px;
                list-style: none;
                padding-left: 10px;
                position: relative;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 9px;
                    content: '';
                    width: 2px;
                    height: 2px;
                    background: $landingBlue;
                    border-radius: 50%;
                }
            }
        }
    }

    &__item-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
    }

    &__item-insta {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 25px;
    }

    &__video {
        margin-left: -27px;
        transform: translateZ(0);
        position: relative;
    }

    &__image-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;
        margin: 40px 0 20px 0;

        @media only screen and (min-width: 1024px) {
            max-width: 720px;
            margin: 50px auto 20px auto;
        }
    }

    &__image-item {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        width: 87px;
        height: 87px;
        margin: 5px auto;

        @media only screen and (min-width: 767px) {
            width: 192px;
            height: 192px;
        }

        @media only screen and (min-width: 1024px) {
            width: 229px;
            height: 229px;
        }

        img {
            display: block;
            max-width: 100%;
        }

        &:before {
            position: absolute;
            background: rgba(0,0,0,0.4)
            url("/images/course-landing/zoom-icon-white.svg") no-repeat 50% 50%;
            left: 0;
            top: 0;
            content: '';
            z-index: 5;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    .es-button {
        @media only screen and (min-width: 767px) {
            width: 250px;
            margin: 10px auto;
        }
    }
}
</style>
