<template>
  <div class="landing-page">
    <BaseSayThanks></BaseSayThanks>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseSayThanks from '@/components/secondary-pages/BaseThanks.vue';
import Footer from '@/components/general/Footer.vue';

export default {
  name: 'SayThanks',
  components: {
    BaseSayThanks,
    Footer,
  },
};
</script>
