<template>
    <div class="base-guidebook">
        <Header></Header>
        <Shedule></Shedule>
        <PaymentQuestions></PaymentQuestions>
    </div>
</template>

<script>
import Header from '@/components/secondary-pages/guidebook/Header.vue';
import Shedule from '@/components/secondary-pages/guidebook/Shedule.vue';
import PaymentQuestions from '@/components/secondary-pages/guidebook/PaymentQuestions.vue';

export default {
  name: 'BaseGuideBook',
  components: {
    Header,
    Shedule,
    PaymentQuestions,
  },
  data() {
  },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.base-guidebook {
    font-family: 'Montserrat';
    background: #f4f6fc;
}
</style>
