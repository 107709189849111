<template>
  <div class="landing-page">
    <BaseMarathonHelth></BaseMarathonHelth>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseMarathonHelth from '@/components/secondary-pages/BaseMarathonHelth.vue';

export default {
  name: 'MarathonPage',
  components: {
    BaseMarathonHelth,
  },
};
</script>
