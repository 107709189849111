<template>
    <div class="recomendation-card">
        <div class="recomendation-card__image">
            <img :src="meditation.promoImage">
            <div class="recomendation-card__labels">
                <div class="recomendation-card__label-item">{{getTypeTitle(meditation.type)}}</div>
                <div class="recomendation-card__label-item">{{meditation.discrTime}}</div>
                <div class="recomendation-card__label-item isNew"
                     v-if="meditation.isNew"
                ></div>
            </div>
        </div>
        <div class="recomendation-card__descr">
            <div class="recomendation-card__title">
                {{meditation.name}}
            </div>
            <div class="recomendation-card__item-reviews"

            >
                <span @click="showReviewsDialog(
                                meditation.groupId,
                                meditation.url,
                                meditation.id,
                                meditation.discrPrice
                                )"
                      v-if="meditation.reviews.length"
                >
                   Отзывы ({{meditation.reviews.length}})
                </span>
            </div>
            <div class="recomendation-card__price-cover">
                <div class="recomendation-card__price">
                    <div class="recomendation-card__price-future">
                        {{priceWithSale(meditation.discrPrice)}} $
                    </div>
                    <div class="recomendation-card__price-base">
                        {{Math.round(meditation.discrPrice)}} $
                    </div>
                </div>
                <div class="recomendation-card__actions">
                    <button
                            class="es-button es-button--rounded play es-button--bordered white"
                            @click.stop="showPreview(meditation.id)"
                    ></button>
                    <button
                            @click.stop="addToCart(meditation.id, meditation.discrPrice)"
                            class="es-button es-button--rounded cart"
                            :disabled="isInCart(meditation.id)"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RecomendationItem',
  props: {
    meditation: Object,
    dataKey: Number,
  },
  components: {
  },
  data() {
    return {
      isViewContentSended: [],
    };
  },
  computed: {
    isGetCourse() {
      return this.$store.state.general.isGetCourse;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    newMeditationsList() {
      return this.$store.state.meditations.newMeditationsList;
    },
  },
  methods: {
    priceWithSale(price) {
      return price * 0.5;
    },
    showReviewsDialog(groupID, medID, id, price) {
      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('setCurrentTemplate', 'review');
      this.$store.commit('showDialog');

      if (!this.checkIsViewContentSended(id)) {
        this.isViewContentSended.push(id);
        const currentUrl = this.$store.getters.getLocalUrl;
        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: price,
            eventName: 'ViewContent',
            email: this.getUserMail,
            id: initiateEventID,
          });
      }
    },
    isInCart(id) {
      return this.cartList.includes(id);
    },
    getTypeTitle(type) {
      if (type === 'mediatation') {
        return 'Медитация';
      }

      return 'Вебинар';
    },
    getMainColor(groupID) {
      let mainColor = '';

      this.meditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      this.newMeditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      return mainColor;
    },
    checkIsViewContentSended(id) {
      let isInArray = false;

      this.isViewContentSended.forEach((itemId) => {
        if (id === itemId) {
          isInArray = true;
        }
      });

      return isInArray;
    },
    showPreview(id) {
      let groupID = '';
      let medID = '';
      let type = '';

      this.meditationsList.forEach((item) => {
        item.list.forEach((itemList) => {
          if (itemList.id === id) {
            groupID = item.groupName;
            medID = itemList.url;
            type = itemList.type;
            this.$store.commit('setPlayerDescr', itemList.discrText);
          }
        });
      });

      document.documentElement.style.setProperty('--main-color', this.getMainColor(groupID));

      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('showPlayer', type);
    },
    addToCart(id, price) {
      if (this.isGetCourse) {
        this.$store.commit('hideDialog');
        this.$store.commit('showPaymentGetCourse');
        this.$store.commit('addToCart', id);

        return;
      }

      this.$store.commit('addToCart', id);

      this.$store.commit('setCurrentTemplate', 'cart-sale');
      this.$store.commit('showDialog');

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'AddToCart',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style  lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/landing";
 @import "../../assets/scss/common/forms";

.recomendation-card {
    width: 215px;
    height: 300px;
    background: #dfe7ff;
    border-radius: 10px;
    margin-right: 15px;
    flex-shrink: 0;

    &__image {
        position: relative;
        border-radius: 10px 10px 0 0;
        overflow: hidden;

        img {
            display: block;
            max-width: 100%;
            height: 140px;
            width: 100%;
            object-fit: cover;
        }
    }

    &__labels {
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        align-items: center;
    }

    &__label-item {
        background: rgba(#445185, 0.60);
        color: $white;
        border-radius: 10px;
        line-height: 21px;
        padding: 0 8px;
        font-weight: 500;
        font-size: 12px;
        margin-right: 5px;
        white-space: nowrap;

        &.isNew {
            background: url("/images/new-icon.svg") no-repeat;
            width: 32px;
            height: 32px;
            background-size: contain;
            box-sizing: border-box;
            margin-left: 5px;
        }
    }

    &__descr {
        padding: 13px;
    }

    &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        height: 54px;
    }

    &__price-cover {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    &__price-future {
        color: $main-red;
        margin-top: 4px;
    }

    &__price-base {
        text-decoration: line-through;
        margin-top: 2px;
    }

    &__price {
        font-size: 16px;
        font-weight: 600;
        color: $main-blue;
    }

    &__actions {
        display: flex;

        & > button {
            margin-left: 10px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__item-reviews {
        font-size: 12px;
        line-height: 18px;
        color: $main-blue;
        text-decoration: underline;
        margin-top: 10px;
        height: 18px;
    }
}
</style>
