<template>
    <div class="subscribe-steps">
        <div class="landing-title-group">
            <div id="stepsSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">КОНСУЛЬТАЦИЯ ПСИХОЛОГА</div>
            <div class="landing-title-group__mid">Как записаться?</div>

            <div class="subscribe-steps__steps">
                <div class="subscribe-steps__step-item">
                    <div class="subscribe-steps__step-item-circle">1</div>
                    <strong>Перейдите в наш Инстаграм и напишите в директ</strong>
                    к кому из психологов желаете записаться на консультацию
                </div>
                <div class="subscribe-steps__step-item">
                    <div class="subscribe-steps__step-item-circle">2</div>
                    <strong>Менеджер согласует</strong> с вами удобное
                    <strong>время консультации</strong>
                    и вышлет <strong>ссылку на оплату</strong>
                </div>
                <div class="subscribe-steps__step-item">
                    <div class="subscribe-steps__step-item-circle">3</div>
                    После оплаты за вами закрепится <strong>дата и время</strong>
                </div>
            </div>

            <a href="https://www.instagram.com/elena.siaglo/"
               target="_blank"
               class="es-button"
            >
                Перейти и записаться
            </a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ConsultantLandingSubscribeSteps',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/landing";

.subscribe-steps {
    padding: 10px 24px 40px 24px;
    background: $white;

    .landing-title-group__mid {
        line-height: 26px;
        color: $main-blue;
    }

    .es-button {
        font-size: 17px;
    }

    &__steps {
        border-left: $main-blue 2px dashed;
        margin: 40px 0 40px 30px;

        strong {
            font-weight: 600;
        }
    }

    &__step-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding-left: 50px;
        margin-bottom: 20px;
        position: relative;

        &:last-child {
            margin-bottom: 0;

            .subscribe-steps__step-item-circle {
                top: 4px;
            }
        }

    }

    &__step-item-circle {
        width: 37px;
        height: 37px;
        background: $main-blue;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        position: absolute;
        left: -19px;
        border-radius: 50%;
    }
}
</style>
