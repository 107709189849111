<template>
    <div class="course-review">
        <div class="course-review__inc">
            <div class="course-landing-title">
                Все отзывы
            </div>

            <div class="course-review__image-list">
                <div class="course-review__image-item" @click="showSingle(1)">
                    <img src="/images/course-landing/reviews/reviews-24/1.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(2)">
                    <img src="/images/course-landing/reviews/reviews-24/2.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(3)">
                    <img src="/images/course-landing/reviews/reviews-24/3.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(4)">
                    <img src="/images/course-landing/reviews/reviews-24/4.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(5)">
                    <img src="/images/course-landing/reviews/reviews-24/5.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(6)">
                    <img src="/images/course-landing/reviews/reviews-24/6.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(7)">
                    <img src="/images/course-landing/reviews/reviews-24/7.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(8)">
                    <img src="/images/course-landing/reviews/reviews-24/8.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(9)">
                    <img src="/images/course-landing/reviews/reviews-24/9.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(10)">
                    <img src="/images/course-landing/reviews/reviews-24/10.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(11)">
                    <img src="/images/course-landing/reviews/reviews-24/11.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(12)">
                    <img src="/images/course-landing/reviews/reviews-24/12.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(13)">
                    <img src="/images/course-landing/reviews/reviews-24/13.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(14)">
                    <img src="/images/course-landing/reviews/reviews-24/14.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(15)">
                    <img src="/images/course-landing/reviews/reviews-24/15.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(16)">
                    <img src="/images/course-landing/reviews/reviews-24/16.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(17)">
                    <img src="/images/course-landing/reviews/reviews-24/17.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(18)">
                    <img src="/images/course-landing/reviews/reviews-24/18.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(19)">
                    <img src="/images/course-landing/reviews/reviews-24/19.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(20)">
                    <img src="/images/course-landing/reviews/reviews-24/20.jpg">
                </div>
                <div class="course-review__image-item" @click="showSingle(21)">
                    <img src="/images/course-landing/reviews/reviews-24/21.jpg">
                </div>
            </div>

            <vue-bottom-sheet ref="myBottomSheet" :swipe-able="false"
                              @opened="bottomSheetOpened()"
                              @closed="bottomSheetClosed()"
            >
                <div class="course-review__image-item-full">
                    <img :src="currentIdUrl">
                </div>
            </vue-bottom-sheet>

            <vue-easy-lightbox
                    escDisabled
                    moveDisabled
                    :visible="visible"
                    :imgs="imgs"
                    :index="initIndex"
                    @hide="handleHide"
            ></vue-easy-lightbox>
        </div>
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';

export default {
  name: 'CourseReviews',
  components: {
    VueEasyLightbox,
    VueBottomSheet,
  },
  data() {
    return {
      reviewsCount: 12,
      imgs: [
        '/images/course-landing/reviews/reviews-24/1.jpg',
        '/images/course-landing/reviews/reviews-24/2.jpg',
        '/images/course-landing/reviews/reviews-24/3.jpg',
        '/images/course-landing/reviews/reviews-24/4.jpg',
        '/images/course-landing/reviews/reviews-24/5.jpg',
        '/images/course-landing/reviews/reviews-24/6.jpg',
        '/images/course-landing/reviews/reviews-24/7.jpg',
        '/images/course-landing/reviews/reviews-24/8.jpg',
        '/images/course-landing/reviews/reviews-24/9.jpg',
        '/images/course-landing/reviews/reviews-24/10.jpg',
        '/images/course-landing/reviews/reviews-24/11.jpg',
        '/images/course-landing/reviews/reviews-24/12.jpg',
        '/images/course-landing/reviews/reviews-24/13.jpg',
        '/images/course-landing/reviews/reviews-24/14.jpg',
        '/images/course-landing/reviews/reviews-24/15.jpg',
        '/images/course-landing/reviews/reviews-24/16.jpg',
        '/images/course-landing/reviews/reviews-24/17.jpg',
        '/images/course-landing/reviews/reviews-24/18.jpg',
        '/images/course-landing/reviews/reviews-24/19.jpg',
        '/images/course-landing/reviews/reviews-24/20.jpg',
        '/images/course-landing/reviews/reviews-24/21.jpg',
      ],
      visible: false,
      bgVideosVisability: true,
      currentIdUrl: 1,
      initIndex: 0,
    };
  },
  computed: {
    textMoteButton() {
      return this.reviewsCount === 12 ? 'Показать еще' : 'Скрыть';
    },
  },
  methods: {
    getLink(index) {
      return `/images/course-landing/reviews/${index}.jpg`;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showSingle(index) {
      this.initIndex = index;
      this.show();
    },
    tootleMore() {
      if (this.reviewsCount !== 12) {
        this.reviewsCount = 12;
      } else {
        this.reviewsCount = 23;
      }
    },
    showFullReview(id) {
      this.currentIdUrl = `/images/course-landing/reviews/reviews-24/${id}.jpg`;
      console.log(this.currentIdUrl);
      this.$refs.myBottomSheet.open();
    },
    bottomSheetOpened() {
      this.bgVideosVisability = false;
    },
    bottomSheetClosed() {
      this.bgVideosVisability = true;
      this.currentIdUrl = '';
    },
  },
};
</script>

<style>
    .bottom-sheet__backdrop {
        &:before {
            position: absolute;
            z-index: 10;
            width: 13px;
            height: 13px;
            background: url("/images/close-icon-black.svg") no-repeat;
            background-size: 100%;
            content: '';
            right: 15px;
            top: 15px;
            cursor: pointer ;
         }
    }
</style>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-review {
    padding: 60px 23px 40px 23px;
    position: relative;

    &__other-videos {
        padding: 5px 20px 20px 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__other-video-item {
        width: 47%;
        margin-bottom: 10px;
        background: rgba(0,0,0,0.05);
        border-radius: 10px;
        overflow: hidden;
        line-height: 0;
    }

    &__other-title {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 30px;
        color: #292935;
    }

    &:before {
        background: url("/images/course-landing/reviews-bg.png");
        width: 320px;
        height: 354px;
        content: '';
        position: absolute;
        z-index: 1;
        left: calc(50% - 150px);
        top: -70px;
    }

    &__inc {
        position: relative;
        z-index: 2;
    }

    &__descr {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;

        @media only screen and (min-width: 767px) {
            font-size: 14px;
            line-height: 150%;
            max-width: 530px;
            margin: 0 auto;
        }

        @media only screen and (min-width: 1024px) {
           max-width: 720px;
        }
    }

    &__descr-large {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        margin: 25px 0;

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            max-width: 530px;
            margin: 30px auto;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 720px;
            font-size: 21px;
            margin: 48px auto;
        }

        strong {
            font-weight: 600;
            color: #7D89A1;
        }
    }

    &__hr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -22px 0 30px 0;

        @media only screen and (min-width: 767px) {
            margin: 30px 0 60px 0;
        }

        &-line {
            width: calc(50% - 23px);
            background: #D1D6E7;
            height: 1px;
        }

        &-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #D1D6E7;
        }
    }

    &__list {
        margin: 40px 0;

        @media only screen and (min-width: 767px) {
            display: flex;
            justify-content: space-around;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 620px;
            margin: 60px auto 40px auto;
        }
    }

    &__list-item {
        background: #F3F6FC;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 30px;
        padding: 23px 23px 23px 50px;
        position: relative;
        color: #292935;
        margin-bottom: 30px;

        @media only screen and (min-width: 767px) {
            width: 280px;
            flex-shrink: 0;
            box-sizing: border-box;
            margin-bottom: 0;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            margin: 20px 0;
        }

        strong {
            font-weight: 500;
        }

        &:before {
            background: url("/images/course-landing/quote-icon-blue.svg");
            width: 18px;
            height: 17px;
            content: '';
            position: absolute;
            left: 18px;
            top: 28px;
        }
    }

    &__item-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 60px);
        box-sizing: border-box;

        .large {
            font-size: 14px;
        }

        ul {
            margin-top: 5px;

            li {
                margin-bottom: 2px;
                list-style: none;
                padding-left: 10px;
                position: relative;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 9px;
                    content: '';
                    width: 2px;
                    height: 2px;
                    background: $landingBlue;
                    border-radius: 50%;
                }
            }
        }
    }

    &__item-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
    }

    &__item-insta {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 25px;

        a {
            color: $main-blue;
        }
    }

    &__video {
        margin-left: -27px;
        transform: translateZ(0);
        position: relative;
    }

    &__image-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;
        margin: 40px 0 20px 0;

        @media only screen and (min-width: 1024px) {
            max-width: 720px;
            margin: 50px auto 20px auto;
        }
    }

    &__image-item-full {
        height: 450px;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    &__image-item {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        width: 120px;
        height: 120px;

        @media only screen and (min-width: 767px) {
            width: 192px;
            height: 192px;
            margin: 5px auto;
        }

        @media only screen and (min-width: 1024px) {
            width: 229px;
            height: 229px;
        }

        img {
            display: block;
            max-width: 100%;
        }

        &:before {
            position: absolute;
            background: rgba(46,59,95,0.4)
            url("/images/course-landing/zoom-icon-white.svg") no-repeat 50% 50%;
            left: 0;
            top: 0;
            content: '';
            z-index: 5;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    .es-button {
        @media only screen and (min-width: 767px) {
            width: 250px;
            margin: 10px auto;
        }
    }
}
</style>
