<template>
  <div class="landing-page">
    <ConsultantList></ConsultantList>
  </div>
</template>

<script>
// @ is an alias to /src
import ConsultantList from '@/components/secondary-pages/BaseConsultantList.vue';

export default {
  name: 'ConsultatantListPage',
  components: {
    ConsultantList,
  },
};
</script>
