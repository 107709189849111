<template>
    <div class="poll-result">
        <div class="course-landing-title">Как создавался курс?</div>

        <div class="poll-result__descr">
            <p>
                Мы провели опрос у себя в Инстаграм,
                в котором приняло участие
                <strong>более 800 женщин.</strong>
            </p>
            <p>
                Ниже вы можете увидеть самые
                популярные проблемы и диагнозы женщин,
                а также их желания и мечты. Если у вас
                похожая ситуация, тогда эта программа
                исцеления точно для вас!
            </p>
        </div>

        <div class="poll-result__item-list">
            <div class="poll-result__item">
                <div class="poll-result__item-title">Какой у вас диагноз</div>
                <div class="poll-result__image illnessImage">
                    <div class="poll-result__main-image">
                        <img src="/images/course-landing/animation-illness/1.png">
                    </div>
                    <div class="poll-result__animated-image"
                         :class="{'isHidden': isAnimatedHidden}"
                    >
                        <img :src="nextUrlIllness">
                    </div>
                </div>
            </div>
            <div class="poll-result__item">
                <div class="poll-result__item-title">
                    С какой проблемой вам самой не справиться
                </div>
                <div class="poll-result__image fearImage">
                    <div class="poll-result__main-image">
                        <img src="/images/course-landing/animation-fears/1.png">
                    </div>
                    <div class="poll-result__animated-image"
                         :class="{'isHidden': isAnimatedHidden}"
                    >
                        <img :src="nextUrlFears">
                    </div>
                </div>
            </div>
            <div class="poll-result__item">
                <div class="poll-result__item-title blue">
                    Какой результат вы хотите видеть после курса
                </div>
                <div class="poll-result__slider">
                    <div class="poll-result__image ">
                        <img src="/images/course-landing/poll-result-3.svg">
                    </div>
                    <div class="poll-result__animation-conteinier">
                        <div class="poll-result__animation-conteinier-inc"
                            :style="transform"
                            :class="{'stopAnimation': stopAnimation}"
                        >
                            <div class="poll-result__slider-item"
                                 v-for="index in reviewsCount"
                                 :key="index"
                            >
                                {{bubblesList[index]}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="poll-result__descr bottom">
            <p>
                <span class="gray">Каждый модуль, каждый урок курса</span>
                разработан с учетом ответов на вопросы из анкеты.
            </p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PoolResutl',
  data() {
    return {
      reviewsCount: 18,
      topPosition: 0,
      stopAnimation: false,
      isAnimatedHidden: false,
      currentImage: 2,
      bubblesList: [
        'Беременность без страхов, раслабленное состояние',
        'Внутреннее спокойствие и уверенность в себе',
        'Избавления от страхов, негатива из прошлого',
        'Удачная попытка эко, здоровый малыш',
        'Стать спокойнее, понять куда двигаться ',
        'Здоровье физическое и моральное',
        'Уверенность в себе, что я смогу увидеть 2 полоски',
        'Почувствовать внутреннюю опору, доверять процессам ',
        'Самостоятельные знания как работать со страхами',
        'Ощущение безопасности и уверенности в себе',
        'Чувство свободы и решения своих проблем',
        'Две полоски на тесте, здоровая беременность',
        'Вернуть себя себе, раскрыть свой потенциал',
        'Снять зацикленность и понять в чем ещё счастье',
        'Расслабиться и не париться на счёт проблем',
        'Раскрыться, видеть жизнь ярче, стать уверенней',
        'Выявление реальной причины диагноза',
        'Спокойная мать, здоровый счастливый ребёнок',
        'Научиться спокойствию, стать женственной',
      ],
    };
  },
  computed: {
    transform() {
      return { transform: `translateY(${this.topPosition}px)` };
    },
    nextUrlIllness() {
      return `/images/course-landing/animation-illness/${this.currentImage}.svg`;
    },
    nextUrlFears() {
      return `/images/course-landing/animation-fears/${this.currentImage}.svg`;
    },
  },
  mounted() {
    this.bubblesAnimation();
    this.svgAnimations();
  },
  methods: {
    bubblesAnimation() {
      setInterval(() => {
        let maxMovementHeight = -1053;

        if (window.innerWidth > 1024) {
          maxMovementHeight = -324;
        }

        if (this.topPosition === maxMovementHeight) {
          this.stopAnimation = true;
          this.topPosition = 0;

          setTimeout(() => {
            this.stopAnimation = false;
          }, 50);

          return;
        }

        this.topPosition -= 81;
      }, 2000);
    },
    svgAnimations() {
      setInterval(() => {
        let nextCount = this.currentImage + 1;

        if (this.currentImage === 11) {
          nextCount = 2;
        }

        this.isAnimatedHidden = true;

        setTimeout(() => {
          this.currentImage = nextCount;
        }, 350);

        setTimeout(() => {
          this.isAnimatedHidden = false;
        }, 400);
      }, 1200);
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.poll-result {
    padding: 20px 23px 40px 23px;
    background: $landingLightestGray;
    font-family: $titleFont;

    &__item-list {
        margin-bottom: 30px;

        @media only screen and (min-width: 767px) {
            max-width: 620px;
            margin: 30px auto;
        }

        @media only screen and (min-width: 1024px) {
            display: flex;
            max-width: 930px;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__animation-conteinier {
        width: 222px;
        height: 400px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 15px;
        top: 80px;

        @media only screen and (min-width: 767px) {
            right: 45px;
            width: 250px;
        }

        @media only screen and (min-width: 1024px) {
            width: 580px;
            right: 26px;
        }
    }

    &__animation-conteinier-inc {
        transition: all 200ms;

        @media only screen and (min-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &.stopAnimation {
            transition: all 0ms;
        }
    }

    &__slider-item {
        background: #447DF3;
        border-radius: 20px;
        padding: 9px 23px;
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        font-family: $titleFont;
        color: $white;
        margin-bottom: 15px;
        height: 66px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        box-sizing: border-box;

        @media only screen and (min-width: 1024px) {
            width: 260px;
        }

        &:nth-child(3n) {
            background: #75A2FF;
            width: 90%;
            margin-left: 10%;
            position: relative;

            @media only screen and (min-width: 1024px) {
                width: 290px;
                margin-left: 0;
            }

            &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 8px 8px 0;
                border-color: transparent #75a3ff transparent transparent;
                left: auto;
                right: 20px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 0;
            border-color: #447ef3 transparent transparent transparent;
            left: 20px;
            bottom: -7px;
        }
    }

    &__descr {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: $landingBlue;
        text-align: center;
        margin-bottom: 40px;

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            max-width: 600px;
            margin: 0 auto 40px auto;
        }

        &.bottom {
            font-weight: 500;
            font-size: 18px;
            line-height: 160%;
            background: url("/images/course-landing/buble-bg.png") 50% 50% no-repeat;
            background-size: 320px;
            margin: -20px auto -60px auto;
            padding: 90px 0 80px 0;

            @media only screen and (min-width: 767px) {
                font-size: 22px;
                line-height: 160%;
                margin: -70px auto;
                padding: 160px 0;
                text-align: center;
                background-size: 500px;
            }
        }

        p {
            margin: 15px 0;

            @media only screen and (min-width: 767px) {
                margin: 20px 0;
            }
        }

        strong {
            color: #7D89A1;
            font-weight: 600;
        }

        .gray {
            color: #7D89A1;
        }
    }

    &__item {
        background: $white;
        border: 1px solid $landingGray;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 10px;
        padding: 20px 5px 10px 20px;
        margin: 15px 0;
        position: relative;

        @media only screen and (min-width: 767px) {
            padding: 35px 5px 63px 76px;
        }

        @media only screen and (min-width: 1024px) {
            width: 450px;
            box-sizing: border-box;
            padding: 35px 5px 50px 20px;

            &:nth-child(3) {
                width: 100%;
                height: 530px;
                position: relative;

                .poll-result__image {
                    position: absolute;
                    top: 20px;
                    left: 20px;

                    img {
                        width: 180px;
                    }
                }
            }
        }
    }

    &__item-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        padding-left: 38px;
        position: relative;
        margin-bottom: 25px;

        &.blue {
            @media only screen and (min-width: 1024px) {
                margin-left: 303px;
                margin-top: -5px;
            }

            &:before {
                background: url("/images/course-landing/question-icon-blue.svg");
            }
        }

        &:before {
            position: absolute;
            content: '';
            background: url("/images/course-landing/question-icon-pink.svg");
            width: 29px;
            height: 29px;
            left: 0;
            top: 50%;
            margin-top: -15px;
        }
    }

    &__slider {
        margin: 0 -20px;
        overflow: hidden;

        .poll-result__image {
            margin: 0 0 0 -15px;

            @media only screen and (min-width: 767px) {
                margin: 0 0 0 35px;
            }
        }
    }

    &__image {
        position: relative;

        @media only screen and (min-width: 1024px) {
           margin-left: -10px;
        }

        &.illnessImage {
            margin-bottom: 20px;

            img {
                max-width: 230px;

                @media only screen and (min-width: 767px) {
                    max-width: 380px;
                    width: 380px;
                }
            }

            &:after {
                background: url('/images/course-landing/illnes-after.svg') no-repeat;
                background-size: contain;
                width: 150px;
                height: 100px;
                content: '';
                position: absolute;
                left: calc(50% + 20px);
                bottom: -25px;
                z-index: 10;

                @media only screen and (min-width: 767px) {
                    width: 270px;
                    height: 200px;
                    bottom: -80px;
                    left: calc(50% - 50px);
                }

                @media only screen and (min-width: 1024px) {
                   left: calc(50% - 60px);
                }
            }
        }

        &.fearImage {
            margin-bottom: -15px;
            margin-top: -30px;

            @media only screen and (min-width: 767px) {
                margin-bottom: -60px;
            }

            img {
                max-width: 265px;

                @media only screen and (min-width: 767px) {
                    width: 410px;
                    max-width: 410px;
                }
            }

            .poll-result__main-image {
                height: 286px;
                display: flex;
                align-items: center;

                @media only screen and (min-width: 767px) {
                    height: 406px;
                }
            }

            &:after {
                background: url('/images/course-landing/fear-after.svg') no-repeat;
                background-size: contain;
                width: 135px;
                height: 113px;
                content: '';
                position: absolute;
                left: calc(50% + 20px);
                bottom: 10px;
                z-index: 10;

                @media only screen and (min-width: 767px) {
                    width: 235px;
                    height: 203px;
                    left: calc(50% - 50px);
                }

                @media only screen and (min-width: 1024px) {
                    left: calc(50% - 28px);
                }
            }
        }

        img {
            max-width: 270px;
        }
    }

    &__animated-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 300ms;
        opacity: 1;
        will-change: opacity;
        z-index: 2;

        &.isHidden {
            opacity: 0;
            transition: all 300ms;
        }
    }
}
</style>
