<template>
  <div class="landing-page">
    <Header isNotFixed="true"></Header>
    <Insta></Insta>
  </div>
</template>

<script>
// @ is an alias to /src
import Insta from '@/components/secondary-pages/Insta.vue';
import Header from '@/components/header/BaseHeader.vue';

export default {
  name: 'InstaPage',
  components: {
    Insta, Header,
  },
};
</script>
