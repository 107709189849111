<template>
    <div class="base-player"
         :class="{'opened': isVisible}"
    >
        <div class="base-player__inc">
            <div class="base-player__cover"></div>
            <div class="base-player__close-btn" v-if="!isPromoLanding" @click="hidePlayer"></div>

            <div class="base-player__list-info">

                <template v-if="!isVideoPlayer">
                    Медитация
                    <br>
                    <sapn>{{currentIndex + 1}} / {{playerMeditations.count}}</sapn>
                </template>
                <template v-else>Вебинар</template>

            </div>

            <div class="base-player__slider glide-audio"
                 :class="{'isVideo': isVideoPlayer}"
            >
                <div data-glide-el="track" class="glide__track">
                    <div class="glide__slides">

                        <div
                                class="base-player__slide glide__slide"
                                v-for="(item, index) in playerMeditations.list"
                                :key="index"
                        >
                            <template  v-if="!isVideoPlayer">
                                <img :src="item.promoImage">
                            </template>
                            <template v-else>
                                <div class="base-player__youTube">
                                    <iframe
                                            width="100%"
                                            :height="yuoTubePlayerHeight()"
                                            :src="getYouTubeUrl(index)"
                                            frameborder="0"
                                            allowfullscreen
                                    ></iframe>
                                </div>
                                <div class="base-player__webinar-subTitle">
                                    <template v-if="item.isPayed">
                                        Вебинар
                                    </template>
                                    <template v-else>
                                        Фрагмент вебинара
                                    </template>
                                </div>
                                <div class="base-player__webinar-title">
                                    {{item.name}}
                                </div>
                            </template>

                            <div class="base-player__add-to-cart-btn"
                                 v-if="isAddToCardButtonVisible(item.id)"
                                 :class="{'disabled': isInCart(item.id)}"
                                 @click="addToCart(item.id, getPriceWithSale(item.discrPrice))"
                            >
                                <div>
                                    <template v-if="isInCart(item.id)">
                                        Медитация в корзине. <br>
                                        <strong class="isRed">Оформить заказ?</strong>
                                    </template>
                                    <template v-else>
                                        Купить полную <br>
                                        версию за
                                        <strong v-if="isGetCourse">
                                            {{getPriceWithSale(item.getCoursePrice, 0)}} ₽
                                        </strong>
                                        <strong v-else>
                                            {{getPriceWithSale(item.discrPrice)}} $
                                        </strong>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-glide-el="controls" class="glide-controls">
                    <button class="glide-prev" data-glide-dir="<">prev</button>
                    <button class="glide-next" data-glide-dir=">">next</button>
                </div>
            </div>

            <div class="audioPlayer"
                 v-if="!isVideoPlayer"
                 :class="{'isPayed': !isAddToCardButtonVisible(currentIDByindex)}"
            >
                <audio id="player" controls>
                    <source :src="getUrl" type="audio/mp3" />
                </audio>
            </div>

            <div
                    class="base-player__nav-btn prev"
                    @click="prevSlideButton()"
            ></div>

            <div class="base-player__nav-btn-counter"
                 v-if="isVideoPlayer"
            >
                <span class="active">{{currentIndex + 1}}</span>
                / {{playerMeditations.count}}
            </div>

            <div
                    class="base-player__nav-btn next"
                    @click="nextSlideButton()"
            ></div>

            <div class="base-player__info-handler"
                 v-if="playerDescr !== ''"
                 @click="toogleDescr()"
            >
                <div class="base-player__descr"
                     v-if="isDescrVisible"
                >
                    <div
                            class="base-player__close-descr-handler"
                            @click.stop="toogleDescr()"
                    ></div>
                    <div v-html="playerDescr" v-if="!isPromoLanding"></div>
                    <div v-else-if="isPromoVideoEnabled">
                        Что происходит с теми, кто слушал медитации?
                        Посмотрите видео и переходите дальше,
                        чтобы послушать другие медитации.

                        <router-link to="/meditations" class="es-button">Дальше</router-link>
                    </div>
                    <div v-else>
                        Послушайте медитацию и переходите дальше
                        <button class="es-button" @click="enableVideo()">Дальше</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr';
import NoSleep from 'nosleep.js';
import axios from 'axios';
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'BasePlayer',
  components: {},
  props: {
    descr: String,
  },
  data() {
    return {
      player: '',
      isVisible: false,
      noSleep: '',
      isYouTube: false,
      isPromoVideoEnabled: false,
      isDescrVisible: false,
      currentIndex: 3,
      isViewContentSended: [],
    };
  },
  computed: {
    isGetCourse() {
      return this.$store.state.general.isGetCourse;
    },
    peek() {
      return this.isVideoPlayer ? 10 : 40;
    },
    playerType() {
      return this.$store.state.player.playerType;
    },
    isVideoPlayer() {
      return this.playerType === 'video';
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    mediationInfo() {
      return this.$store.state.meditations.currentMeditation;
    },
    currentMeditationGroup() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    playerMeditations() {
      if (this.isVideoPlayer) {
        return this.$store.getters.getAllWebinars;
      }

      return this.isCabinet
        ? this.$store.getters.getAllMeditations
        : this.$store.getters.getNotPayedMeditations;
    },
    getPlayerBg() {
      if (this.mediationInfo.playerImage) {
        return this.mediationInfo.playerImage;
      }

      return this.currentMeditationGroup.playerImage;
    },
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
    isLandingPage() {
      return this.$store.state.user.landingUserType !== '';
    },
    isPromoLanding() {
      return this.$route.name === 'promo1';
    },
    playerPrice() {
      return this.playerMeditations.list[this.currentIndex].discrPrice;
    },
    playerDescr() {
      return this.playerMeditations.list[this.currentIndex].discrText;
    },
    currentIDByindex() {
      return this.playerMeditations.list[this.currentIndex].id;
    },
    getUrl() {
      const data = this.playerMeditations.list[this.currentIndex];
      let returnLink = data.isPayed ? data.audio : data.audioPreview;

      if (this.isLandingPage) {
        returnLink = data.audioPreview;
      }

      return returnLink;
    },
    currentID() {
      return this.$store.state.meditations.currentMeditation.id;
    },
    isPayed() {
      return this.$store.state.meditations.currentMeditation.isPayed;
    },
    saleCoof() {
      return this.$store.state.general.saleCoof;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
  },
  methods: {
    yuoTubePlayerHeight() {
      let height = window.innerHeight - 450;

      if (height < 280) {
        height = 280;
      }

      return height;
    },
    isAddToCardButtonVisible(id) {
      let isPayed = false;

      this.playerMeditations.list.forEach((item) => {
        if (item.id === id) {
          isPayed = item.isPayed;
        }
      });

      return !isPayed;
    },
    isInCart(id) {
      return this.cartList.includes(id);
    },
    getPriceWithSale(price) {
      return price * this.saleCoof;
    },
    toogleDescr() {
      this.isDescrVisible = !this.isDescrVisible;
    },
    showPaymentForm() {
      this.$store.commit('showPayment');

      this.cartList.forEach((id) => {
        this.$store.commit('markMeditationForPayByID', { id });
      });

      this.$store.commit('setCartMeditationPagePayment', true);
      this.$store.commit('setLandingPaymentFullPrice', this.infoForMeditationsInCart.price);
      this.$store.commit('setLandingPaymentPriceToPay', this.infoForMeditationsInCart.priceWithSale);
    },
    addToCart(id, price) {
      if (this.isGetCourse) {
        this.$store.commit('hideDialog');
        this.$store.commit('showPaymentGetCourse');
        this.$store.commit('addToCart', id);

        return;
      }

      if (this.isInCart(id)) {
        this.showPaymentForm();
        return;
      }

      this.$store.commit('addToCart', id);

      this.$store.commit('setCurrentTemplate', 'cart-sale');
      this.$store.commit('showDialog');

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'AddToCart',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
    checkIsViewContentSended(id) {
      let isInArray = false;

      this.isViewContentSended.forEach((itemId) => {
        if (id === itemId) {
          isInArray = true;
        }
      });

      return isInArray;
    },
    enableVideo() {
      this.isPromoVideoEnabled = true;
    },
    hidePlayer() {
      this.$store.commit('hidePlayer');
    },
    getVideoUrl() {
      const data = this.$store.state.meditations.currentMeditation;

      return data.isPayed ? data.audio : data.audioPreview;
    },
    getYouTubeUrl(index) {
      const data = this.playerMeditations.list[index];
      const url = data.isPayed ? data.audio : data.audioPreview;

      return `https://www.youtube.com/embed/${url}`;
    },
    prevSlideButton() {
      window.document.querySelector('.glide-prev').click();
    },
    nextSlideButton() {
      window.document.querySelector('.glide-next').click();
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--windowHeight', `${window.innerHeight}px`);

    this.playerMeditations.list.forEach((item, index) => {
      if (item.id === this.currentMeditation.id) {
        this.currentIndex = index;
      }
    });

    const isYouTube = window.innerWidth <= 768;

    if (isYouTube && this.currentMeditation.type === 'webinar') {
      this.isYouTube = true;
    } else {
      const playerId = 'player';
      this.player = new Plyr(`#${playerId}`, {
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: false,
          container: null,
        },
      });

      this.player.on('exitfullscreen', () => {
        document.querySelector('.plyr__video-embed.plyr__video-wrapper')
          .style.removeProperty('padding-bottom');
      });

      this.player.on('play', () => {
        const id = this.currentIDByindex;
        const price = this.getPriceWithSale(this.playerPrice);

        if (!this.checkIsViewContentSended(id)) {
          this.isViewContentSended.push(id);
          const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
          const currentUrl = this.$store.getters.getLocalUrl;

          axios
            .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
              amount: price,
              eventName: 'ViewContent',
              email: this.getUserMail,
              id: initiateEventID,
            });
        }
      });
    }

    this.noSleep = new NoSleep();
    this.noSleep.enable();

    if (this.currentMeditation.type === 'webinar') {
      this.isVisible = true;
    } else {
      setTimeout(() => {
        const element = document.querySelector('.plyr__progress__container');

        element.setAttribute('data-text', this.mediationInfo.name);
        this.isVisible = true;
      }, 100);
    }

    setTimeout(() => {
      this.glide = new Glide('.glide-audio', {
        perView: 1,
        gap: 20,
        peek: { before: this.peek, after: this.peek },
        animationDuration: 300,
        rewind: false,
        swipeThreshold: 2,
        dragThreshold: 2,
        startAt: this.currentIndex,
      }).mount({ Controls, Swipe, Breakpoints });

      this.glide.on(['mount.before', 'run'], () => {
        const { index } = this.glide;
        const currentMedData = this.playerMeditations.list[index];

        this.currentIndex = index;

        if (!this.isVideoPlayer) {
          this.player.source = {
            type: 'audio',
            title: 'Example title',
            sources: [
              {
                src: currentMedData.isPayed ? currentMedData.audio : currentMedData.audioPreview,
                type: 'audio/mp3',
              },
            ],
          };

          const element = document.querySelector('.plyr__progress__container');
          element.setAttribute('data-text', currentMedData.name);
        }
      });
    }, 300);
  },
  beforeDestroy() {
    this.noSleep.disable();
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/player";
 @import "../../assets/scss/common/forms";
 @import "../../assets/scss/common/glide";

.base-player {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('/images/player-gradient-bg.png') #2B2B39 100% 0 no-repeat;
    z-index: 40;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms;
    max-width: 767px;
    display: block;

    @media only screen and (max-height: 665px) {
        overflow-y: auto;
    }

    &__inc {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        min-height: 665px;
        z-index: 2;
    }

    &:before {
        position: absolute;
        z-index: 1;
        right: -60px;
        top: -20px;
        width: 450px;
        height: 450px;
        border-radius: 50%;
        content: "";
        background: #92ABFF;
        filter: blur(160px);
    }

    /* ipad */
    @media only screen and (min-width: 768px) {
        left: calc(50% - 384px);
    }

    &.opened {
        opacity: 1;
        visibility: visible;
    }

    .videoPLayer {
        position: absolute;
        bottom: 350px;
        z-index: 10;
        left: 50px;
    }

    .audioPlayer {
        position: relative;
        z-index: 5;

        &.isPayed {
            .plyr__controls__item.plyr__progress__container:before {
                display: none;
            }
        }
    }

    .isVideo {
        bottom: 140px;

        .base-player__slide {
            height: calc(100vh - 330px);
            max-height: none;
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding-bottom: 100px;

            @media only screen and (max-height: 665px) {
                height: 350px;
            }
        }

        .base-player__add-to-cart-btn {}
    }

    &__descr {
        position: absolute;
        bottom: 60px;
        left: -10px;
        width: calc(100vw - 20px);
        max-width: 767px;
        background: #2A6CE4;
        padding: 50px 20px;
        border-radius: 20px;
        box-sizing: border-box;
        color: $white;
        font-size: 13px;
        line-height: 140%;
        overflow: visible;
        max-height: none;

        &:after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 10px 0 10px;
            border-color: #2A6CE4 transparent transparent transparent;
            position: absolute;
            left: 20px;
            bottom: -8px;
            content: '';
            display: block;
            transform: rotate(15deg);
        }
    }

    &__youTube {
        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &.promoPage {
            z-index: 3;
            position: relative;

            &:before {
                top: -80px;
            }

            iframe {
                margin-top: -80px;
            }
        }
    }

    &__cover {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        z-index: 2;
    }

    &__close-btn {
        width: 31px;
        height: 31px;
        background: $white;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 18px;
        z-index: 50;

        &:before {
            width: 100%;
            height: 100%;
            display: flex;
            background:
                    url("/images/player-close-button.svg") no-repeat 50% 50%;
            content: '';
            background-size: cover;
        }
    }

    &__add-to-cart-btn {
        background: linear-gradient(180deg, #F3444E 0%, #F3444E 100%);
        box-shadow: 0 4.60345px 4.60345px rgba(0, 0, 0, 0.25),
        inset 0 4.60345px 5.75431px rgba(255, 255, 255, 0.25);
        border-radius: 15px;

        position: absolute;
        left: 10px;
        bottom: 10px;
        width: calc(100% - 20px);
        height: 66px;
        color: $white;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        padding: 4px 10px;
        box-sizing: border-box;
        z-index: 7;
        top: auto;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            max-width: 325px;
            left: 50%;
            margin-left: -162px;
        }

        strong {
            font-weight: 600;
            margin-left: 5px;

            &.isRed {
                color: #F3444E;
            }
        }

        &:before {
            background: url("/images/cart-icon-white.svg") no-repeat 50% 50%;
            background-size: cover;
            content: '';
            width: 25px;
            height: 25px;
            position: relative;
            left: 0;
            top: 0;
            margin-right: 20px;
            margin-left: -15px;
        }

        &.disabled {
            background: linear-gradient(154.64deg, #43445B 28.15%, #2C2D3D 74.72%);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
            inset 0 4px 5px rgba(255, 255, 255, 0.25);
            opacity: 1;
            padding-left: 10px;
            text-align: center;
            color: #A5CBFF;

            &:before {
                display: none;
            }
        }
    }

    &__webinar-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: $white;
        padding: 0 30px;
    }

    &__webinar-subTitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #74748E;
        text-align: center;
        margin: 20px 0 10px 0;
    }

    &__list-info {
        position: absolute;
        width: 250px;
        left: 50%;
        margin-left: -125px;
        top: 40px;
        z-index: 10;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $white;
        text-align: center;

        span {
            font-size: 12px;
            font-weight: 400;
        }
    }

    &__nav-btn {
        background: url("/images/player-next-btn.svg");
        width: 20px;
        height: 21px;
        position: absolute;
        left: 50%;
        margin: 0 0 0 60px;
        bottom: 64px;
        z-index: 10;
        cursor: pointer;

        &.prev {
            margin: 0 0 0 -78px;
            transform: rotate(180deg);
        }
    }

    &__nav-btn-counter {
        position: absolute;
        bottom: 58px;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        color: #74748E;
        left: 50%;
        width: 60px;
        text-align: center;
        margin-left: -30px;

        .active {
            color: $white;
        }
    }

    &__slider {
        position: absolute;
        left: 0;
        bottom: 320px;
        width: 100%;
        z-index: 10;

        .glide-controls {
            display: none;
        }
    }

    &__slide {
        height: calc(var(--windowHeight) - 450px);
        overflow: hidden;
        position: relative;
        max-height: 405px;
        min-height: 240px;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
        }
    }

    &__info-handler {
        position: absolute;
        left: 20px;
        bottom: 54px;
        background: url("/images/player-info-icon.svg");
        width: 32px;
        height: 32px;
        background-size: cover;
        z-index: 12;
    }

    &__close-descr-handler {
        background: url("/images/close-icon-white.svg");
        position: absolute;
        right: 22px;
        top: 20px;
        width: 12px;
        height: 12px;
        background-size: cover;
    }

    .audioPlayer .plyr__controls {
        height: 250px;
    }

    .audioPlayer .plyr__controls__item.plyr__progress__container:after {
        font-size: 17px;
    }

    .audioPlayer  .plyr__controls__item.plyr__control {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -39px;
        margin-bottom: 36px;
        width: 78px;
        height: 78px;
        box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.25);
        background: linear-gradient(167.56deg, #2F80ED 50.74%, rgba(47, 128, 237, 0.3) 94.42%);
    }

    .audioPlayer .plyr__control[data-plyr=play] svg {
        width: 30%;
        height: 100%;
        fill: #ffffff;
    }

    .audioPlayer .plyr__controls__item.plyr__progress__container {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;

        &:before {
            position: absolute;
            width: 100%;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            content: 'Фрагмент медитации ';
            color: #9B9BB8;
            text-align: center;
            top: -30px;
            left: 0;

        }
    }

    .audioPlayer .plyr__controls__item.plyr__time {
        font-weight: 400;
        font-size: 25px;
        line-height: 140%;
        color: #9B9BB8;
        text-align: center;
        width: 100%;
    }
}

</style>
