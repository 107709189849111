<template>
    <div class="promo-stats">
      <table>
        <tr>
          <th colspan="2"><b>Всего пользователей с промокодом:</b></th>
          <td>{{usersWithPromo}}</td>
        </tr>
        <tr>
          <th colspan="2"><b>Все кто увидел промокод:</b></th>
          <td>{{usersWithActivatedPromo}}</td>
        </tr>
        <tr>
          <th colspan="2">Все кто купил по своему промо 1 курс:</th>
          <td>{{usersWhoPayedOwnCourses_1}}</td>
        </tr>
        <tr>
          <th colspan="2">Все кто купил по своему промо 2 курса:</th>
          <td>{{usersWhoPayedOwnCourses_2}}</td>
        </tr>
        <tr>
          <th colspan="2">Все кто купил по своему промо 3 курса:</th>
          <td>{{usersWhoPayedOwnCourses_3}}</td>
        </tr>

        <tr>
          <th colspan="2">Уникальных, кто купил по чужому промо:</th>
          <td>{{payedWithForeignPromo}}</td>
        </tr>

        <tr>
          <th colspan="3"><b>Топ по использованию промокода:</b></th>
        </tr>

        <tr v-for="(item, index) in payed" :key="index">
          <th>{{index}}</th>
          <td>{{item.email}} ({{item.value}})</td>
          <td>
              Оплат: {{item.payed}},<br>
              Попыток ввода промо: {{item.used}},<br>
              Новых пользователей: {{item.payedByNewUser}},<br>
              Своих курсов: {{item.payedWithOwn}}
          </td>
        </tr>
      </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PromoStats',
  components: {},
  data() {
    return {
      usersWithPromo: 0,
      usersWithActivatedPromo: 0,
      usersWhoPayedOwnCourses_1: 0,
      usersWhoPayedOwnCourses_2: 0,
      usersWhoPayedOwnCourses_3: 0,
      usersWithMaxPayed: [],
      payed: [],
    };
  },
  mounted() {
    this.getPromoStats();
  },
  computed: {
    payedCount() {
      let payedCount = 0;

      this.payed.forEach((item) => {
        payedCount += parseInt(item.payed, 10);
      });

      return payedCount;
    },
    payedWithForeignPromo() {
      const ownPayed = parseInt(this.usersWhoPayedOwnCourses_1, 10)
            + parseInt(this.usersWhoPayedOwnCourses_2, 10)
            + parseInt(this.usersWhoPayedOwnCourses_3, 10);

      return this.payedCount - ownPayed;
    },
    adminToken() {
      return this.$store.state.user.adminToken;
    },
  },
  methods: {
    getPromoStats() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/PromoUserStat.php`, {
          adminToken: this.adminToken,
        })
        .then((response) => {
          if (response.data.success) {
            this.usersWithPromo = response.data.result.usersWithPromo;
            this.usersWithActivatedPromo = response.data.result.usersWithActivatedPromo;
            this.usersWhoPayedOwnCourses_1 = response.data.result.usersWhoPayedOwnCourses_1;
            this.usersWhoPayedOwnCourses_2 = response.data.result.usersWhoPayedOwnCourses_2;
            this.usersWhoPayedOwnCourses_3 = response.data.result.usersWhoPayedOwnCourses_3;
            this.usersWithMaxPayed = response.data.result.usersWithMaxPayed;
            this.payed = response.data.result.payed;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.promo-stats {
  margin: 40px 0 0;
  padding: 0 20px;

  table {
    border: solid 1px $black;
    margin-bottom: 40px;

    td, th {
      border: solid 1px $black;
      padding: 8px;
      text-align: left;
      line-height: 22px;
      font-size: 14px;

      b {
        font-weight: 600;
      }
    }
  }
}
</style>
