<template>
    <div class="wlm-author">
        <div class="landing-title-group">
            <div id="authorSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small" v-if="isWebinarRegistration">
                РЕГИСТРАЦИЯ НА ВЕБИНАР
            </div>
            <div class="landing-title-group__small" v-else>
                МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ
            </div>
            <div class="landing-title-group__mid">Автор и ведущая курса</div>
        </div>

        <div class="wlm-author__content">

            <div class="wlm-author__text">
                <div
                        class="wlm-author__text-content"
                        :class="{'isOpened': isOpened}"
                >
                    <div class="wlm-author__image"></div>
                    <div class="wlm-author__title">Елена Сягло</div>
                    <div class="wlm-author__subtitle">
                        Психолог, специалист по психосоматике, энерготерапевт.
                    </div>
                    <div class="wlm-author__subtitle dot-only">.....</div>
                    <p>После замужества я тоже столкнулась с проблемой бесплодия.
                        Около 3х лет я искала ответы, сдавала всевозможные анализы,
                        проводила медицинские исследования. Но все эти действия только
                        усугубляли мое состояние, создавали дополнительное напряжение,
                        ежедневно увеличивали бесконечное количество страхов и
                        переживаний за свое будущее.
                    </p>
                    <p>Я нашла для себя решение и на тесте увидела свои заветные две полоски! </p>
                    <p class="wlm-author__images-list">
                        <img src="/images/landing/about-descr-1.png">
                        <img src="/images/landing/about-descr-2.png">
                    </p>
                    <p>Всю беременность я еще больше углублялась в психологию,
                        изучала информацию о естественных родах, о гипнородах,
                        о том, как функционирует женское тело в родовом процессе,
                        посещала множество различных тренингов на тему естественного
                        материнства и грудного вскармливания.
                        В результате моя беременность и роды прошли чудесно.
                    </p>
                    <p>
                        Благодаря своему телу я смогла
                        прочувствовать всю природную мощь.
                        Ценные знания, полученные на пути к
                        беременности и подготовки к родам, я решила
                        использовать в своей профессиональной деятельности.
                        Я закончила Школу Психосоматики, поступила в
                        Университет на клинического психолога, стала
                        вести консультации. Результаты моих клиентов
                        впечатляли и я следовала за этими знаками, дальше
                        “прокачивала” свои навыки, получала новые
                        инструменты в тренингах, семинарах и в личных терапиях.
                    </p>
                    <p>В течение первого года моей деятельности:</p>

                    <table class="wlm-author__descr-table">
                        <tr>
                            <th>
                                более
                                <span class="large-char">500</span>
                            </th>
                            <td>консультаций было проведено мной </td>
                        </tr>
                        <tr>
                            <th>
                                более
                                <span class="large-char">40</span>
                            </th>
                            <td>личных терапий я прошла у различных специалистов</td>
                        </tr>
                    </table>

                    <p class="wlm-author__descr-title">
                        Моя жизнь полностью поменялась!
                        Я осознала, что нашла свой истинный путь,
                        ведь открывшись ему, я получила всё, о чем мечтала.
                    </p>
                    <div class="wlm-author__image bottom"></div>
                    <p v-if="!isWebinarRegistration">
                        Мой курс “Медитация в новую жизнь”
                        для тех женщин, кто готов меняться как я.
                        Этот курс не просто про страхи, принятие и беременность,
                        он про новое ощущение жизни, про иную реальность.
                        После прохождения курса вы будете другой,
                        к вам придёт здоровье, энергия, благосостояние,
                        внутренняя и внешняя свобода.
                        К такой гармоничной мамочке обязательно
                        придёт долгожданный малыш!
                    </p>
                    <div class="wlm-author__image bottom isMobile"></div>
                </div>
                <div
                        class="wlm-author__read-more"
                        :class="{'isOpened': isOpened}"
                        @click="toggleText()"
                >
                    <template v-if="isOpened">Свернуть</template>
                    <template v-else>Читать больше</template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'WelcomeLandingAuthor',
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    isWebinarRegistration() {
      return this.$route.name === 'mnj-webinar';
    },
  },
  methods: {
    toggleText() {
      this.isOpened = !this.isOpened;

      if (!this.isOpened) {
        const readMore = document.querySelector('.wlm-author');

        setTimeout(() => {
          readMore.scrollIntoView({ block: 'end' });
        }, 340);
      }
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";

.wlm-author {
    padding: 30px 24px 20px 24px;
    background: $white;

    @media only screen and (max-width: 767px) {
        padding-top: 12px;
    }

    &__descr-title {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 40px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    &__descr-table {
        max-width: 500px;
        margin: 40px auto;

        @media only screen and (max-width: 767px) {
           margin: 25px 0;
        }

        td, th {
            border-bottom: solid $lightGray 1px;
            padding: 15px 35px;

            @media only screen and (max-width: 767px) {
                padding: 15px 35px 15px 0;
            }
        }

        th {
            font-weight: 600;
            font-size: 20px;
            text-align: left;

            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }

            .large-char {
                font-size: 30px;

                @media only screen and (max-width: 767px) {
                    font-size: 26px;
                }
            }
        }
    }

    &__images-list {
        display: flex;
        justify-content: center;
        margin: 50px 0!important;

        @media only screen and (max-width: 767px) {
            margin: 20px 0!important;
        }

        img {
            max-height: 280px;
            border-radius: 10px;
            flex-shrink: 0;
            flex-grow: 0;
            margin: 0 20px;
            display: block;

            @media only screen and (max-width: 767px) {
                max-height: 160px;
                margin: 0 10px;
            }

            &:first-child {
                border-radius: 50%;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin-top: 30px;
        }
    }

    &__image {
        width: 200px;
        height: 290px;
        background: url("/images/landing/welcome-author.jpg") 50% 50%;
        background-size: contain;
        border-radius: 120px;
        flex-shrink: 0;
        margin-right: 90px;
        margin-left: 20px;
        position: relative;
        float: left;
        margin-bottom: 50px;
        margin-top: 50px;

        @media only screen and (max-width: 767px) {
            margin: 0 auto 25px auto;
            float: none;
        }

        &.bottom {
            float: right;
            margin-right: 30px;
            margin-left: 100px;
            margin-top: 20px;
            background: url("/images/landing/welcome-about-bottom.png") 50% 50%;
            background-size: cover;

            @media only screen and (max-width: 767px) {
                display: none;
                margin: 40px auto;
                float: none;
            }

            &.isMobile {
                display: none;

                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }
        }

        &:after,
        &:before {
            position: absolute;
            left: -20px;
            top: 50%;
            width: 7px;
            height: 7px;
            background: #D0D6E7;
            content: '';
            border-radius: 50%;
            margin-top: -3px;
        }

        &:before {
            left: auto;
            right: -20px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        @media only screen and (max-width: 767px) {
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 10px;
        }
    }

    &__subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;

        @media only screen and (max-width: 767px) {
            text-align: center;
            font-size: 14px;
            line-height: 20px;
        }

        &.dot-only {
            letter-spacing: 3px;
            font-size: 30px;
            margin-bottom: 25px;
            display: block;
        }
    }

    &__read-more {
        font-weight: 400;
        font-size: 18px;
        color: $main-blue;
        cursor: pointer;
        display: block;
        text-align: center;
        width: 200px;
        margin: 5px auto;

        @media only screen and (max-width: 767px) {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
        }

        &.isOpened {
            &:after {
                transform: rotate(180deg);
            }
        }

        &:after {
            content: '';
            background: url("/images/landing/arrow-blue.svg");
            display: block;
            width: 12px;
            height: 7px;
            margin: 10px auto 0 auto;

        }
    }

    &__text-content {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        max-height: 430px;
        overflow: hidden;
        position: relative;
        transition: all 300ms;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            max-height: 700px;
        }

        &.isOpened {
            max-height: 10000px;

            &:after {
                opacity: 0;
            }
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background: linear-gradient(360deg, #FFFFFF 33.33%, rgba(255, 255, 255, 0) 88.54%);;
            display: block;
            content: '';
        }
    }

    &__text {
        p {
            margin-bottom: 20px;
        }
    }
}
</style>
