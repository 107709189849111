<template>
    <div class="welcome-page">
        <Header isNotFixed="true" isNoBurger="true"></Header>
        <LandingHeaderTelegram></LandingHeaderTelegram>
        <LandingWebinarDescrTelegram></LandingWebinarDescrTelegram>
        <!--<LandingHowToParticipate></LandingHowToParticipate>-->
        <WebinarPaymentTelegram></WebinarPaymentTelegram>
        <WelcomeLandingFAQ isNoStartButton="false"></WelcomeLandingFAQ>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import LandingHeaderTelegram from '@/components/secondary-pages/webinar-landing/LandingHeaderTelegram.vue';
import LandingWebinarDescrTelegram from '@/components/secondary-pages/webinar-landing/LandingWebinarDescrTelegram.vue';
// import LandingHowToParticipate from
// '@/components/secondary-pages/webinar-landing/LandingHowToParticipate.vue';
import WebinarPaymentTelegram from '@/components/secondary-pages/webinar-landing/WebinarPaymentTelegram.vue';
import WelcomeLandingFAQ from '@/components/secondary-pages/welcome-landing/WelcomeLandingFAQ.vue';

export default {
  name: 'BaseWelcomePage',
  components: {
    Header,
    LandingHeaderTelegram,
    LandingWebinarDescrTelegram,
    // LandingHowToParticipate,
    WebinarPaymentTelegram,
    WelcomeLandingFAQ,
    Footer,
  },
  data() {
    return {
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  mounted() {

  },
  methods: {
    isElementInViewport(element) {
      let el = element;
      let top = el.offsetTop;
      let left = el.offsetLeft;
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < (window.pageYOffset + window.innerHeight)
        && left < (window.pageXOffset + window.innerWidth)
        && (top + height) > window.pageYOffset
        && (left + width) > window.pageXOffset
      );
    },
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

body {
    background: red;
}

.welcome-page {
    .base-header {
        background: $white;
    }

    &__back {
        line-height: 70px;
        text-align: center;

        a {
            font-size: 14px;
            color: $main-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
