<template>
    <div class="advantages-list">
        <template v-if="isTelegramPracticePage">
            <div class="advantages-list__item" v-if="hasEmail">
                <span class="advantages-list-text">Напишите ваш номер телефона и телеграм</span>
            </div>

            <div class="advantages-list__item">
                <span class="advantages-list-text">
                    После оплаты мы добавим вас в закрытую группу в телеграме.
                </span>
            </div>
        </template>
        <template v-else>
            <div class="advantages-list__item" v-if="hasEmail">
                <span class="advantages-list-text">Напишите ваше имя и email</span>
            </div>

            <div class="advantages-list__item">
                <span class="advantages-list-text">
                    После оплаты ссылка на эфир придет на email
                </span>
            </div>
        </template>
    </div>
</template>

<script>

export default {
  name: 'AdvantagesListFlowWebinar',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  props: {
    hasEmail: String,
  },
  computed: {
    meditationInfoSingle() {
      return this.$store.getters.getMeditationsInfoForSingle;
    },
    singleWebinarsCount() {
      return this.meditationInfoSingle.pendingWebinars.length;
    },
    singleMeditationsCount() {
      return this.meditationInfoSingle.pendingMeditations.length;
    },
    isTelegramPracticePage() {
      return this.$route.name === 'TelegramMeeting';
    },
  },
  methods: {
    showDialog(name) {
      this.$store.commit('setCurrentTemplate', name);
      this.$store.commit('showDialog');
    },
    wordForm(num, word) {
      const cases = [2, 0, 1, 1, 1, 2];
      return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.advantages-list {
    margin: 0 0 24px 0;

    &__item {
        background: url("/images/check-iocn-black.svg") no-repeat 0 50%;
        padding-left: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }

    &__icon {
        background: url("/images/info-icon-black.svg") no-repeat 50% 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
</style>
