<template>
    <div class="course-descr">
        <div class="course-descr__list">
            <div class="course-descr__item">
                <div class="course-descr__text">
                    Очень быстро снимите
                    все <strong>замкИ</strong>, которые сковали
                    вас в одиночестве, страхе и боли.
                </div>
                <div class="course-descr__image isLock">
                    <div class="course-descr__main-image">
                        <img src="/images/course-landing/animations-lock/1.png">
                    </div>
                    <div class="course-descr__animated-image"
                         :class="{'isHidden': isAnimatedHidden}"
                    >
                        <img :src="nextUrl">
                    </div>
                </div>
            </div>
            <div class="course-descr__item">
                <div class="course-descr__text">
                    Научитесь жить с любовью и радостью,
                    чтобы встретить материнство
                    в своем прекрасном <strong>зАмке.</strong>
                </div>
                <div class="course-descr__image isCastle">
                    <div class="course-descr__main-image">
                        <img src="/images/course-landing/animation-castle/1.png">
                    </div>
                    <div class="course-descr__animated-image"
                         :class="{'isHidden': isAnimatedHidden}"
                    >
                        <img :src="nextUrlCastle">
                    </div>
                </div>
            </div>
        </div>

        <div class="course-descr__action">
            <button class="es-button black"
                    @click="scrollToFormat()"
            >
              Хочу на курс
            </button>

            <div class="course-descr__action-descr">
              Выберите тариф и оплатите место на курсе по лучшей цене.
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseDescription',
  data() {
    return {
      isAnimatedHidden: false,
      currentImage: 2,
    };
  },
  mounted() {
    setInterval(() => {
      let nextCount = this.currentImage + 1;

      if (this.currentImage === 11) {
        nextCount = 2;
      }

      this.isAnimatedHidden = true;

      setTimeout(() => {
        this.currentImage = nextCount;
      }, 350);

      setTimeout(() => {
        this.isAnimatedHidden = false;
      }, 400);
    }, 1200);
  },
  computed: {
    nextUrl() {
      return `/images/course-landing/animations-lock/${this.currentImage}.svg`;
    },
    nextUrlCastle() {
      return `/images/course-landing/animation-castle/${this.currentImage}.svg`;
    },
  },
  methods: {
    scrollToFormat() {
      const hiddenElement = document.querySelector('#chooseFormatTitle');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-descr {
    padding: 30px 23px;
    text-align: center;
    background: $white;
    font-family: $titleFont;

    @media only screen and (min-width: 1024px) {
        max-width: 930px;
        margin: auto;
    }

    &__list {
        @media only screen and (min-width: 1024px) {
            .course-descr__item {
                display: flex;

                &:nth-child(2) {
                    .course-descr__text {
                        order: 2;
                    }
                }
            }
        }
    }

    &__item {
        &:nth-child(2) {
            .course-descr__text {
                @media only screen and (min-width: 1024px) {
                    margin-top: -30px;
                    margin-left: 40px;
                }
            }
        }
    }

    @media only screen and (min-width: 767px) {
        padding-top: 85px;
        padding-bottom: 50px;
    }

    @media only screen and (min-width: 1024px) {
        padding-top: 0;
    }

    &__animated-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 300ms;
        opacity: 1;
        will-change: opacity;

        &.isHidden {
            opacity: 0;
            transition: all 300ms;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #292935;

        strong {
            font-weight: 900;
        }

        @media only screen and (max-width: 450px) {
            max-width: 350px;
            margin: auto;
        }

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            line-height: 150%;
            max-width: 615px;
            margin: auto;
        }

        @media only screen and (min-width: 1024px) {
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
            text-align: left;
            padding: 0 30px 0 55px;
            margin-top: 120px;
        }
    }

    &__action {
        .es-button {
            font-weight: 600;
            font-size: 12px;
            font-family: $titleFont;
            letter-spacing: 0.8px;
            margin-bottom: 30px;
            border-radius: 40px;

            @media only screen and (min-width: 767px) {
                max-width: 280px;
                margin: 30px auto 30px auto;
            }
        }
    }

    &__action-descr {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #7D89A1;

        @media only screen and (min-width: 767px) {
            max-width: 500px;
            margin: auto;
        }
    }

    &__image {
        margin: 30px 0;
        position: relative;

        @media only screen and (min-width: 767px) {
            margin-bottom: 80px;
        }

        &.isLock {
            @media only screen and (min-width: 1024px) {
                margin-right: 67px;
            }

            img {
                max-width: 270px;

                @media only screen and (min-width: 767px) {
                    max-width: 512px;
                    width: 512px;
                }

                @media only screen and (min-width: 1024px) {
                    max-width: 372px;
                }
            }

            &:after {
                position: absolute;
                content: '';
                background: url("/images/course-landing/arrow-bottom-2.svg");
                width: 12px;
                height: 53px;
                left: 0;
                top: -54px;

                @media only screen and (min-width: 767px) {
                    left: 60px;
                    height: 70px;
                    width: 16px;
                    background-size: 16px;
                    background-repeat: no-repeat;
                }

                @media only screen and (min-width: 1024px) {
                    background: url("/images/course-landing/arrow-bottom-1.svg");
                    width: 20px;
                    height: 51px;
                    top: 170px;
                    left: -20px;
                    transform: rotate(-52deg);
                }
            }

            &:before {
                background: url("/images/course-landing/lock-ligtning.svg");
                width: 22px;
                height: 58px;
                position: absolute;
                left: calc(50% + 100px);
                top: 0;
                content: '';

                @media only screen and (min-width: 767px) {
                    left: calc(50% + 155px);
                    width: 40px;
                    height: 107px;
                    background-size: cover;
                }

                @media only screen and (min-width: 1024px) {
                    width: 40px;
                    height: 107px;
                    position: absolute;
                    left: calc(50% + 130px);
                }
            }
        }

        &.isCastle {
            @media only screen and (min-width: 1024px) {
                margin-bottom: 50px;
            }

            img {
                max-width: 235px;

                @media only screen and (min-width: 767px) {
                    max-width: 512px;
                    width: 512px;
                }

                @media only screen and (min-width: 1024px) {
                    width: 372px;
                    margin-top: -150px;
                    margin-left: 62px;
                }
            }

            &:after {
                position: absolute;
                content: '';
                background: url("/images/course-landing/arrow-bottom-1.svg");
                width: 20px;
                height: 51px;
                right: 28px;
                top: -41px;

                @media only screen and (min-width: 767px) {
                    right: 128px;
                }

                @media only screen and (min-width: 1024px) {
                    background: url("/images/course-landing/arrow-bottom-2.svg");
                    width: 12px;
                    height: 53px;
                    right: -44px;
                    top: 10px;
                    transform: rotate(63deg);
                }
            }

            &:before {
                background: url("/images/course-landing/castle-stars.svg");
                width: 55px;
                height: 49px;
                position: absolute;
                left: calc(50% - 100px);
                top: 0;
                content: '';

                @media only screen and (min-width: 767px) {
                    left: calc(50% - 220px);
                    top: 30px;
                    width: 100px;
                    height: 80px;
                    background-size: cover;
                }
            }
        }
    }
}
</style>
