<template>
    <div class="program-FAQ">
        <div class="program-FAQ__faq-item"
             v-for="(item, index) in questions"
             :class="{'isActive': item.isActive, 'isGray': item.isGray}"
             :key="index"
             @click="toggleActiveState(index)"
        >
            <div class="program-FAQ__faq-title"
                 v-html="item.question"
            ></div>
            <div class="program-FAQ__faq-content" v-html="item.answer"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseModuleFAQ',
  props: {
    questions: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    toggleActiveState(index) {
      this.questions.forEach((item, indexItem) => {
        const currentItem = this.questions[indexItem];

        if (index === indexItem) {
          currentItem.isActive = !currentItem.isActive;
        } else {
          currentItem.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.program-FAQ {
    padding: 10px 0 10px 0;
    counter-reset: num;

    @media only screen and (min-width: 767px) {
        max-width: 620px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 1024px) {
        margin-top: -40px;
        margin-left: 170px;
    }

    &__faq-item {
        margin-bottom: 6px;
        border-bottom: solid $landingGray 1px;
        position: relative;

        &:first-child {
            border-top: solid $landingGray 1px;

            @media only screen and (min-width: 767px) {
                border-top: 0;
            }
        }

        &.isActive {
            .program-FAQ__faq-content {
                max-height: 1200px;
                padding: 5px 0 15px 0;

                @media only screen and (min-width: 767px) {
                    padding: 0 0 15px 30px;
                }
            }

            .program-FAQ__faq-title {
                &:before {
                    background-color: #D1D6E7;
                    background-image: url("/images/course-landing/minus-icon-blue.svg");
                }
            }
        }

        &.isGray {
            &:before {
                opacity: 0;
            }

            .program-FAQ__faq-title {
                color: #7D89A1;
            }
        }

        &:before {
            content: counter(num)".";
            counter-increment: num;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 15px;
            width: 30px;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
        }
    }

    &__faq-title {
        padding: 15px 30px 20px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        position: relative;
        padding-left: 30px;

        strong {
            font-weight: 900;
            color: #7D89A1;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;
        }
    }

    &__faq-content {
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            list-style-type: none;
            margin: 0 0 0 25px;
            padding: 15px 0 5px 0;

            li {
                position: relative;
                padding: 0 0 20px 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;

                &:last-child {
                    padding-bottom: 0;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    left: -25px;
                    width: 15px;
                    height: 15px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 150%;
                    background: url("/images/course-landing/li-check-dot-gray.svg");
                }
            }
        }
    }
}
</style>
