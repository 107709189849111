<template>
    <div
       class="bf-banner"
       :class="{'isAuthed': isAuthed}"
       v-if="isBannerEnabled"
       target="_blank"
    >
        <img src="/images/black-friday-mobile-4.png" class="bf-banner__mobile">
        <img src="/images/black-friday-desktop-4.png" class="bf-banner__desktop">
    </div>
</template>

<script>

export default {
  name: 'BlackFridayBanner',
  components: {},
  data() {
    return {
      isBannerEnabled: this.$store.state.general.isBannerActive,
    };
  },
  computed: {
    isAuthed() {
      return this.$store.getters.getUserMail !== '';
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.bf-banner {
    background: $white;
    margin-top: 60px;
    text-align: center;
    margin-bottom: -60px;
    display: block;

    &.isAuthed {
        margin-top: 78px;

        @media only screen and (min-width: 767px) {
            margin-top: 97px;
        }
    }

    &__mobile {
        display: block;

        @media only screen and (min-width: 767px) {
            display: none;
        }
    }

    &__desktop {
        display: block;

        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    img {
        width: 100%;
    }
}

</style>
