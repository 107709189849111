<template>
    <div class="course-cases">
        <div class="course-cases__inc">
            <div class="course-landing-title">
                <div class="hiddenLabel" id="reviewsSection"></div>
                Кейсы после курса
            </div>

            <div class="course-cases__list glide-slider">
                <div class="course-cases__arrow" @click="prevSlideButton"></div>
                <div class="course-cases__arrow right" @click="nextSlideButton"></div>
                <div class="glide__track" data-glide-el="track">
                    <div class="glide__slides">
                        <div class="course-cases__item glide__slide"
                             v-for="(item, index) in list"
                             :key="index"
                        >
                            <div class="course-cases__item-image">
                                <img :src="item.image">
                            </div>
                            <div class="course-cases__item-content">
                                <div class="course-cases__title-label">ДО</div>
                                <div class="course-cases__text-block-before">
                                    <div class="course-cases__text-block-before-content">
                                        <ul v-html="item.before"></ul>
                                    </div>
                                    <div class="course-cases__read-more"
                                         @click="showReadMore(item.before)"
                                         v-if="item.isReadMoreBefore"
                                    >
                                        Читать далее
                                    </div>
                                </div>
                                <div class="course-cases__title-label blue">
                                    После
                                </div>
                                <div class="course-cases__text-block-after">
                                    <div class="course-cases__text-block-after-content">
                                        <ul v-html="item.after"></ul>
                                    </div>

                                    <div class="course-cases__read-more"
                                         @click="showReadMore(item.after)"
                                         v-if="item.isReadMoreAfter"
                                    >
                                        Читать далее
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="glide__arrows" data-glide-el="controls">
                    <button class="glide__arrow glide__arrow--left glide-prev"
                            data-glide-dir="<"
                    >
                        prev
                    </button>
                    <button
                            class="glide__arrow glide__arrow--right glide-next"
                            data-glide-dir=">"
                    >
                        next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'CourseCases',
  components: {
  },
  data() {
    return {
      list: [
        {
          image: '/images/course-landing/case-16.jpg',
          before: '<li>рано вышла замуж, развелась, потом долго отходила </li> <li>работала всю жизнь с детьми беспризорными и с кризисными семьями</li><li><b>ставили непроходимость труб</b></li><li>внутри было много разочарования, тяжести, усталости и горечи, что не родила до 40-ка</li><li>из за того, что долго не получается забеременеть был срыв, после чего начала ходить по врачам</li>',
          after: '<li>через короткое время после курса узнала, что беременна</li><li>были очень лёгкая беременность и роды</li><li><b>стала мамой впервые и самостоятельно в 42 года</b></li><li>поменялось внутреннее состояние</li><li>стала верить и доверять Богу</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-17.jpg',
          before: '<li>всю жизнь мечтала о близнецах и с самого детства</li> <li>замужем 7 лет, есть ребенок 13 лет от первого брака</li><li>первые роды были тяжелые, лежали в реанимации с ребенком </li><li><b>потом появился сильный страх рождения больного ребенка</b></li><li>как следствие, после удачного ЭКО, малыш замер на сроке 12-13 недель</li><li>тогда совсем не чувствовала своё тело, поэтому узнали про ЗБ только на 16 неделе</li><li>потом было еще 2 беременности, но обе замершие</li><li>сменила 8 врачей</li><li>делала много монипуляций со своим телом, о чем потом сильно сожалела</li>',
          after: '<li>отпустила “идею фикс” забеременеть любой ценой</li><li>переключилась на работу, много путешествовала с мужем</li><li><b>после 9 лет многочисленных попыток, забеременела самостоятельно без ЭКО</b></li><li>узи показало, что ждет <b>два малыша</b></li><li>перестала быть «головастиком» , который переваривает свои незажитые травмы снова и снова у себя в голове</li><li>открыла своё сердце для любви и доверилась Богу</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-18.jpg',
          before: '<li>в 14 лет врачи поставили диагноз СКПЯ (поликистоз яичников)</li> <li><b>в 22 года поставили диагноз бесплодие, детская матка и отсутствие овуляции</b></li><li>5 лет до бесплодия сидела на контрацептивах, а затем еще 5 лет на дюфастоне</li><li>последние 12 лет мечтала стать мамой</li><li>затем развелась, забросила все препараты и врачей, начала заниматься собой</li><li>была в отношениях с женатым мужчиной, затем вышла из них и встретила нынешнего мужа</li>',
          after: '<li>в конце курса обнаружила, что появилась овуляция, до этого 12 лет овуляции не было и цикл был не постоянный</li><li>улучшились отношения с мужем</li><li>начала задумываться о смене работы и оформила самозанятость</li><li><b>в 35 лет после курса первый раз в жизни увидела 2 полоски на тесте, благополучно родила</b></li><li>во время беременности не было токсикоза и других неприятных моментов</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-19.jpg',
          before: '<li>6 лет планирования беременности</li> <li>неудачные беременности</li><li><b>безрезультатные ЭКО</b></li>',
          after: '<li>сильно поменялось окружение, перестала общаться с теми, с кем нет общих интересов</li><li>внутри появилось больше уверенности, открытости, появилась энергия на новые достижения</li><li><b>после курса наступила долгожданная беременность</b></li><li><b>благополучно родила здорового ребенка</b></li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: false,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-1.jpg',
          before: '<li>13 лет работала учителем в школе</li> <li>5 лет пыталась забеременеть, но не получалось</li><li><b>никогда не видела на тесте двух полосок</b></li><li>обижалась на мужа за отсутствие близости и ненаступление беременности</li><li>расстраивалась критическим дням и не делала тесты на беременность</li><li>мама и свекровь "приставали" расспросами “а когда же будут внуки”, вплоть до обид</li><li>мало прислушивалась к своему телу и своим желаниям</li><li>жила в постоянной моральной усталости от всего</li><li>отстаивала свои права и права коллег на работе, иногда приходилось “воевать” с начальством</li>',
          after: '<li>уже на курса поняла, что старая работа в бюджетной организации “душит”</li><li>на следующий день после окончания курса уволилась с работы</li><li>общее состояние поменялось на  спокойное, расслабилась</li><li><b>наступила долгожданная беременность!</b></li><li><b>благополучно родила доченьку!</b></li><li>близость с мужем стала намного чувственнее и волшебнее</li><li>отношения с мамой значительно улучшились, отправилась с мамой в совместное путешествие и много общалась по душам, прошли "детские обиды"</li><li>отношения со свекровью стали лучше и откровеннее</li><li>на курсе появилось много подруг-единомышленниц с которыми приятно общаться до сих пор</li><li>стала позволять себе быть разной и не ругать себя за это</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-3.jpg',
          before: '<li><b>была зациклена на зачатии</b> ребенка и вся жизнь крутилась вокруг этого\n</li> <li>душевное состояние было на нуле\n</li><li>жила для других себе в ущерб\n</li><li>не было сепарации с родителями\n</li><li>чувствовала неприятие себя, одиночество и недостаток любви\n</li>',
          after: '<li>сменила цель на то, чтобы быть счастливой, чувствовать и дарить любовь\n</li><li><b>отношения с супругом улучшились</b>, появились уважение и любовь\n</li><li>ушла со старой нелюбимой работы, радуется жизни и кайфует\n</li><li>сепарировалась от родителей, после этого ушли зажимы на лице\n</li><li>пошла учиться дальше, прошла курс по психосоматике \n</li><li>стала более женственной, окружающие это замечают и делают комплименты</li><li>на курсе обрела подруг - круг родственных душ\n</li><li>повзрослела, вышла из состояния ребёнка, стала красивой, умной, великолепной, сексуальной женщиной\n</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-6.jpg',
          before: '<li><b>жила в состоянии жертвы</b>, думала что все вокруг должны</li> <li>был страх жизни, боялась, что не поймут, хотелось спрятаться и не высовываться</li><li>боялась выражать свои эмоции</li><li>была ребенком не умеющим брать ответственность за свою жизнь</li><li>было неустойчивое эмоциональное состояние</li>',
          after: '<li><b>проработала страх никогда не стать мамой</b>, после этого фокус внимания сместился на саморазвитие, на себя</li><li>стало интересно жить, нашла увлечение по душе, появилось много энергии и желание заниматься чем-то кроме работы</li><li>наладились отношения с мужем, научилась разговаривать с ним и спокойно реагировать на вещи, на которые раньше бы обижалась </li><li>сепарировалась от родителей, перестала их учить и на душе стало легко</li><li>ушла постоянная спешка, стала спокойнее, пришло понимание что значит жить здесь и сейчас</li><li>начал уходить лишний вес</li><li>научилась слушать свое тело и исцелять его</li><li>проработала цистит за один день без врачей и таблеток</li><li>повзрослела, научилась сама принимать решения и брать ответственность на себя</li><li>познакомилась с собой настоящей, приняла свои темные стороны</li><li>научилась выражать свои эмоции и не бояться что подумают окружающие</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-14.jpg',
          before: '<li>работала сутками без отдыха</li> <li>находилась всегда в тревоге, не было дня, чтобы тревога не накрывала </li><li>в феврале 2022 года <b>поставили диагноз рак молочной железы</b></li><li>были проблемы и недопонимания с родителями, считала что все должна решить за них и взяла под полную опеку </li>',
          after: '<li>все тревоги растворились, стала спокойной</li><li><b>курс помог пройти лечение от рака</b> молочной железы, сейчас наблюдается положительная динамика</li><li>стала более осознанной во всем, принятии ситуации, принятии решений</li><li>познакомилась с новой собой, стала более уверенной в себе</li><li>смогла сепарироваться от мамы, отпустить ее</li><li>появилась энергия на что-то новое, пошла учиться на курс НГМ</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-9.jpg',
          before: '<li>были обиды на родителей\n</li><li><b>была постоянная гонка за беременностью;</b></li><li>проживала свою жизнь на автомате;\n</li><li>мало обращала внимание на тело, на его знаки, слушала только голову;\n</li><li>были постоянные мысли о будущем, не жила в настоящем</li>',
          after: '<li>проработала страх "никогда не стать мамой"\n</li><li>прекратила гонку за беременностью, \n</li><li>проработала ограничение “не достойна больших денег”, сейчас работает в своё удовольствие, а деньги сами приходят\n</li><li><b>снизился ТТГ без лекарств\n</b></li><li>стала жить под девизом “Я под защитой Вселенной, Вселенная всегда заботится обо мне!”\n</li><li>пришло внутреннее спокойствие, лёгкость и уверенность в себе\n</li><li>ушла спешка и гонка, стала жить в любви и радости\n</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-4.jpg',
          before: '<li><b>после замершей беременности было тяжелое состояние,</b> ничего не радовало</li> ',
          after: '<li>стало поправляться состояние, почувствовала радость жизни</li><li><b>ушли фанатичные мысли о ребенке,</b> появилась внутренняя уверенность, что он точно придёт</li><li>появилась легкость, ушла суетливость, торопливость</li><li>начали налаживаться отношения со своим мужчиной</li><li>пошла в духовное развитие, на курс Рейки</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: false,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-2.jpg',
          before: '<li>Выгорание от работы, уход из профессиональной сферы</li> <li>Непонимание себя и куда двигаться дальше</li><li><b>Конфликты с мужем,</b> ссоры, недопонимания</li><li>Стресс после переезда в другую страну</li><li>состояние тревожности, блокировка эмоций, игнорирование собственных чувств, строгость к себе\n</li><li>плаксивость, нестабильное эмоциональное состояние</li>',
          after: '<li>Изменила отношение к себе, стала более бережной, поверила в себя и свой выбор</li><li>Поступила в университет на психолога</li><li>Начала изучать психосоматику, телесную терапию, сексологию</li><li><b>Наладились отношения с мужем</b></li><li><b>Переехала с мужем в Таиланд</b> и сейчаспланирует организовывать там живые встречи с девушками</li><li>Наладила отношение со своим отцом, прилетела к нему в другой город и впервые за 10 лет познакомила его со своим мужем </li><li>внутри появилось ощущение лёгкости, благодарности, интереса к жизни\n</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-13.jpg',
          before: '<li><b>Были недопонимания с мамой</b></li> <li>Не было четких личных границ</li><li>Не слышала себя, не понимала о чем сигналит тело</li><li>Не было состояния потоковости, момента</li>',
          after: '<li>Наладились отношения с мамой, <b>ушли претензии, недопонимания, взаимные упреки и обиды</b></li><li>Научились с мужем слушать друг друга</li><li>Окружающие отмечают большие изменения, чувствуют свет и тепло при общении</li><li>Стала больше принимать все ситуации</li><li>Появилась легкость и гармония внутри</li><li>Стала больше чувствовать, замечать чудеса вокруг</li><li>Идет на второй поток, чтобы убрать еще новые слои, фильтры, ограничения. За возможностью быть в поле прекрасных женщин.</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-5.jpg',
          before: '<li><b>была тревожная, если что-то происходило, сразу начиналось сердцебиение,</b> ладошки потели\n</li><li>была неуверенная в своих знаниях и умениях, обесценивала себя\n</li><li> стеснялась проявляться в соц. сетях, боялась что подумают окружающие \n</li><li> боялась сглаза\n</li>',
          after: '<li><b>научилась по новому реагировать</b> на непонятные ситуации, появилось больше спокойствия\n</li><li>научилась прислушиваться к своему телу\n</li><li>поняла, что внутри есть большой потенциал, чтобы быть полезной людям</li><li>обучилась на консультанта по Матрице судьбы, начала проводить консультации</li><li>стала верить в свои силы, знания и умения</li><li> стала ценить себя и не стесняться быть классной</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-10.jpg',
          before: '<li><b>работала кассиром в минимаркете</b></li><li>медленно утопала, шла ко дну</li><li>мыслями застревала в прошлых неудачах,обидах</li><li>винила и осуждала себя\n</li>',
          after: '<li><b>научилась разговаривать с телом, чувствовать его</b></li><li>начала проходить личные терапии с психологами\n</li><li>пошла в развитие себя и прошла курс баров ACCESS CONSCIOUSNESS\n</li><li>пошла еще дальше и стала фасилитатором ACCESS CONSCIOUSNESS\n</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: false,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-12.jpg',
          before: '<li>Старалась угодить окружающим, игнорируя свои желания</li> <li>Испытывала напряжение в общении с отцом</li><li><b>Страдала от большого количества страхов и комплексов,</b> неуверенности в себе, которые мешали проявлению</li><li>Часто страдала от недостатка жизненной энергии и упадка сил</li><li>Несколько лет не решалась самостоятельно начать водить авто</li><li>Часто смотрела на мир из состояния жертвы</li>',
          after: '<li><b>Научилась брать ответственность за свою жизнь на себя</b></li><li>Избавилась от страха вождения, уверенно чувствует себя за рулем</li><li>Проработала обиду на отца и наладила с ним комфортное общение</li><li>Начала слушать свое тело, свои желания</li><li>стала себя чувствовать сильной и энергичной </li><li>Начала масштабироваться, обрела новых клиентов и запустила онлайн курс по Нейрографике</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-11.jpg',
          before: '<li><b>пребывала в нервном, раздраженным, тревожном состоянии;</b></li><li>видела мир “опасным и жестоким”, а людей “плохими”;\n </li><li>не чувствовала взаимосвязь со своим телом\n </li>',
          after: '<li><b>научилась строить отношения</b> на работе с коллегами, дома с любимым мужчиной и с близкими людьми; \n </li><li>научилась очень быстро находить ответы и решения на многие проблемы со здоровьем, если они возникают\n </li><li>очень счастлива, что решилась на операцию по восстановлению зрения после 25 лет \tмучений с очками </li><li>начала пользоваться своей интуицией, и это помогло улучшить качество жизни </li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: false,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-8.jpg',
          before: '<li>беспокоили боли в спине</li> <li><b>пила много лекарств</b></li><li>было акне.</li>',
          after: '<li>улучшилось здоровье</li><li>отказалась от лекарств\n</li><li><b>избавилась от акне</b></li><li>боли в спине стали проходить</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: false,
          isReadMoreAfter: false,
        },
        {
          image: '/images/course-landing/case-7.jpg',
          before: '<li><b>убегала и пряталась от проблем</b></li><li>не могла найти себя настоящую </li><li>не знала что такое познавать себя\n</li><li>не могла найти интересы кроме работы</li>',
          after: '<li>смогла проработать смерть мамы и отпустить эту ситуацию\n</li><li><b>восстановился женский цикл</b></li><li>начала проявляться на работе\n</li><li>пошла на курс по работе с метафорическими картами\n</li><li>начала рисовать, ходя до курса вообще не рисовала\n</li><li>пошла учиться плавать при том, что я очень боится воды\n</li>',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
        {
          image: '/images/course-landing/case-15.jpg',
          before: '<li><b>был сильный страх смерти</b></li> <li>обижалась на окружающих, особенно на супруга, перекладывала на него ответственность за продолжение рода</li> <li>жила в позиции жертвы, которую взяла от мамы</li> <li>чувствовала себя в опасности, это прослеживалось даже физически (могла свести судорога, если кто-то неожиданно дотрагивался)</li> <li>критиковала своё тело из-за того, что оно не совершенно</li> <li>контролировала себя и запрещала быть собой</li> <li>шла на работу даже, если плохо себя чувствовала</li> ',
          after: '<li>смогла перепрожить смерть отца и близких, поменяла отношение к смерти</li> <li><b>вышла из позиции жертвы</b> после того, когда взяла ответственность на себя и за себя </li> <li>похудела без диет и дополнительных физических нагрузок на 2 размера</li> <li>разрешила себе стать многогранной</li> <li>разрешила другим быть собой, действовать так, как они считают нужным</li> <li>полюбила свое тело, оно стало другом</li> <li>разрешила себе не иметь детей</li> ',
          isBeforeOpen: false,
          isAfterOpen: false,
          isReadMoreBefore: true,
          isReadMoreAfter: true,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    prevSlideButton() {
      window.document.querySelector('.glide-prev').click();
    },
    nextSlideButton() {
      window.document.querySelector('.glide-next').click();
    },
    showReadMore(data) {
      this.$store.commit('setNewCasesContent', data);
      this.$store.commit('setCurrentTemplate', 'casesInfo');
      this.$store.commit('showDialog');
    },
  },
  mounted() {
    this.glide = new Glide('.glide-slider', {
      perView: 2,
      gap: 40,
      animationDuration: 300,
      rewind: false,
      swipeThreshold: 2,
      dragThreshold: 2,
      startAt: 0,
    }).mount({ Controls, Swipe, Breakpoints });
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/glide";

 .course-cases {
    padding: 80px 23px 40px 23px;
    position: relative;
     margin-bottom: 40px;

    &__list {
        position: relative;
        width: 600px;
        margin: auto;
    }

     &__arrow {
         position: absolute;
         left: -70px;
         top: 50%;
         background: url("/images/course-landing/glide-arrow.svg");
         width: 29px;
         height: 54px;
         margin-top: -27px;
         cursor: pointer;

         &.right  {
             left: auto;
             right: -70px;
             transform: rotate(180deg);
         }
     }

    &__item {
        background: $white;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 30px;
        width: 280px;
        height: 768px;
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    &__item-content {
        padding: 10px;
    }

    &__item-image {
        margin-bottom: 10px;
        border-radius: 28px 28px 0 0;
        overflow: hidden;

        img {
            max-width: 100%;
            display: block;
            border-radius: 0 0 25px 25px;
        }
    }

    &__title-label {
        font-weight: 600;
        font-size: 13px;
        color: #99A9B9;
        background: #DCE0E9;
        border-radius: 5px;
        width: 85px;
        height: 25px;
        line-height: 25px;
        text-transform: uppercase;
        text-align: center;

        &.blue {
            background: #447df3;
            color: $white;
        }
    }

    &__text-block-before,
    &__text-block-after{
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        margin: 15px 0;

        ul {
            padding-left: 10px;
        }

        li {
            list-style: outside;
            margin: 2px 0 0 10px;
            position: relative;

            &:first-letter {
                text-transform: lowercase;
            }
        }

        b {
            font-weight: 600;
        }
    }

    &__read-more {
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        color: #7D89A1;
        text-decoration: underline;
        display: inline-block;
        margin: 10px 0 15px 10px;
        cursor: pointer;
    }

    &__text-block-before-content {
        height: 86px;
        overflow: hidden;
    }

    &__text-block-after-content {
        height: 195px;
        overflow: hidden;
    }

     .glide__arrows {
         visibility: hidden;
     }
}
</style>
