<template>
    <div class="course-progr">
        <div class="course-progr__title">Программа курса:</div>
        <div class="course-progr__blue-title">Модуль 1</div>
        <div class="course-progr__title-h2">
            Выявляем источники проблем
        </div>
        <div class="course-progr__image-p1"></div>
        <div class="course-progr__contentFAQ">
            <div class="course-progr__title-h4">Уроки модуля:</div>
            <CourseModuleFAQ :questions="questionsP1"></CourseModuleFAQ>
        </div>

        <div class="course-progr__result-content">
            <div class="course-progr__result-title">Результат 1 модуля:</div>
            Найдете замкИ
            <span class="course-progr__lock"></span>
            в теле, голове и психике, которые мешают
            прийти к материнству.
        </div>

        <div class="course-progr__blue-title">Модуль 2</div>
        <div class="course-progr__title-h2">
            Избавляемся от ненужного
        </div>
        <div class="course-progr__image-p2"></div>
        <div class="course-progr__contentFAQ">
            <div class="course-progr__title-h4">Уроки модуля:</div>
            <CourseModuleFAQ :questions="questionsP2"></CourseModuleFAQ>
        </div>

        <div class="course-progr__result-content">
            <div class="course-progr__result-title">Результат 2 модуля:</div>
            Найдете ключи к замкАм
            <span class="course-progr__lock"></span>,
            чтобы попрощаться с болезнями,
            страхами и тревожностью.
        </div>

        <div class="course-progr__blue-title">Модуль 3</div>
        <div class="course-progr__title-h2">
            Учимся жить по новому
        </div>
        <div class="course-progr__image-p3"></div>
        <div class="course-progr__contentFAQ">
            <div class="course-progr__title-h4">Уроки модуля:</div>
            <CourseModuleFAQ :questions="questionsP3"></CourseModuleFAQ>
        </div>
        <div class="course-progr__result-content">
            <div class="course-progr__result-title">Результат 3 модуля:</div>
            Откроете замкИ
            <span class="course-progr__lock"></span>,
            и освободитесь от них,
            чтобы начать строить свой прекрасный зАмок
            <span class="course-progr__castle"></span>.
        </div>

        <div class="course-progr__blue-title">Модуль 4</div>
        <div class="course-progr__title-h2">
            Материнство в изобилии и радости
        </div>
        <div class="course-progr__image-p4"></div>
        <div class="course-progr__contentFAQ">
            <div class="course-progr__title-h4">Уроки модуля:</div>
            <CourseModuleFAQ :questions="questionsP4"></CourseModuleFAQ>
        </div>
        <div class="course-progr__result-content">
            <div class="course-progr__result-title">Результат 4 модуля:</div>
            Найдете свое истинное предназначение,
            чтобы наполнить зАмок <span class="course-progr__castle"></span>
            светом и любовью, откроете для
            себя материнство в изобилии и радости.
        </div>
    </div>
</template>

<script>
import CourseModuleFAQ from '@/components/secondary-pages/course-landing/CourseModuleFAQ.vue';

export default {
  name: 'CourseProgram',
  components: {
    CourseModuleFAQ,
  },
  data() {
    return {
      questionsP4: [
        {
          question: 'Вкус изобилия',
          answer: 'Откроете для себя жизнь с бесконечно великими возможностями, ресурсами, деньгами. Узнаете, как быть собой и своим состоянием менять окружающих. Получите инструменты, которые помогут воплотить ваши истинные желания в жизнь и менять свою реальность с лёгкостью и удовольствием.',
          isActive: true,
          isGray: false,
        },
        {
          question: 'Миссия выполнима',
          answer: 'Найдете свое предназначение, раскроете в себе природные способности, поймете свою уникальность и индивидуальность. Посчитаете матрицу своей судьбы и получите целостное представление о всех гранях вашей личности.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Сила интуиции',
          answer: 'Разрушите рамки ограничивающих убеждений. Научитесь настраиваться на ощущения и доверять своему сердцу. Начнете слышать и слушать себя, принимать правильные решения, жить своей жизнью. Разовьете навык общения со своим внутренним проводником, высшим Я.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Выпускной в новую жизнь',
          answer: 'Обсудим ваши невероятные результаты и поделимся инсайтами. Определим победителей (самых старательных и активных учениц) и наградим их ценными подарками. Проведем мощную групповую медитацию на исцеление, чтобы получить дополнительную энергию и ресурс для «новой жизни».',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Бонусы',
          answer: '<ul><li>PDF файл с расшифровкой 22 энергий в матрице судьбы</li><li>Конспекты уроков в текстовом формате</li><li>Презентации уроков</li></ul>',
          isActive: false,
          isGray: true,
        },
      ],
      questionsP3: [
        {
          question: 'Расставляем всё по полочкам',
          answer: 'Проведете диагностику своей родовой системы и сделаете правильную расстановку всех членов семьи. Сделаете практики на активацию силы своего рода, на отпускание своих не рожденных деток и/или детей своего рода. Увидите позитивные изменения в личной жизни, обретете гармонию с самой собой.',
          isActive: true,
          isGray: false,
        },
        {
          question: 'Скованные одной цепью',
          answer: 'Научитесь принимать и сепарироваться по-настоящему. Мама, папа, дети - станут вашими главными учителями. Решите проблемы в отношениях с партнером и окружающими людьми. Станете более уверенны в собственных силах и перестанете быть зависимы от чужого мнения. Сможете самостоятельно принимать важные решения, самореализовываться и строить карьеру.\n',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Внутриутробный рай',
          answer: 'Сможете исцелить и перепрожить в благости и спокойствии важный момент собственного рождения. Вернете чувство безопасности, перепишите свои базовые стратегии и глубинные установки, чтобы передать их детям. «Заложите на будущее» здоровье своего ребенка на каждом из этапов беременности. Сможете проработать и избежать в будущем ошибки проживания беременности и родов.\n',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Новые грани сексуальности',
          answer: 'Разблокируете и почувствуете свою сексуальную энергию и женственность. Разрушите все стереотипы проявления своей сексуальности. Стыд, страх, самообесценивание себя как женщины больше не будут вашими спутниками. Уйдет напряжение в теле и разблокируются телесные зажимы. Отношения с партнёром выйдут на новый уровень, жизнь станет более яркой и насыщенной.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Бонусы',
          answer: '<ul><li>Медитация на перепроживание и исцеление процесса собственного рождения</li><li>Медитация на раскрытие женственности и сексуальности</li><li>Конспекты уроков в текстовом формате</li><li>Презентации уроков</li></ul>',
          isActive: false,
          isGray: true,
        },
      ],
      questionsP2: [
        {
          question: 'Просьба не беспокоить',
          answer: 'Проработаете в парах корень своих беспокойств и навязчивых идей. Забудете про негативные убеждения и страхи «не смогу быть хорошей мамой», «никогда не рожу», «рожу больного ребенка», «не хватит здоровья», «не успею», «что скажут другие». Высвободите энергию на дальнейшие шаги к материнству.',
          isActive: true,
          isGray: false,
        },
        {
          question: 'Страхи на пустом месте',
          answer: 'Узнаете 5 биологических законов природы, по которым проходит беременность. Поймете, почему бывают замершие беременности, выкидыши, неудачные ЭКО и как их избежать. Забудете про токсикоз, набор веса, молочницу, тонус матки и многое другое.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Прости как Бог прощает',
          answer: 'Сделаете в парах практику на прощение по алгоритму. Вернете себе «потерянные части» прошлого. Поймете, почему человек, которого вы не можете простить, поступил с вами именно так, что он «подсветил» и для чего вам нужна была эта ситуация. Ваши обиды трансформируются в осознания, начнете больше понимать о себе.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Зажимы, блоки, сомнения',
          answer: 'Узнаете про энергетические центры (чакры) и научитесь их активировать. Проработаете свои телесные блоки и зажимы. Сможете полностью почувствовать вкус жизни, улучшать свое эмоциональное состояние, возвращать спокойствие и уверенность в себе.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Я в домике',
          answer: 'Детская фраза «я в домике» обретет для вас новый смысл. Вы узнаете 7 эффективных техник для быстрой помощи себе. Научитесь самостоятельно прорабатывать острые переживания, накатывание страхов, всплеск эмоций. Узнаете как не впускать в себя негативную информацию, держать свой сердечный центр.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Бонусы',
          answer: '<ul><li>PDF файл с авторской методикой для работы с навязчивыми мыслями и негативными убеждениями</li><li>PDF файл с авторской методикой для работы с прощением</li><li>PDF файл с молитвой о нерожденных детях рода</li><li>Конспекты уроков в текстовом формате</li><li>Презентации уроков</li></ul>',
          isActive: false,
          isGray: true,
        },
      ],
      questionsP1: [
        {
          question: 'Разговор со своим телом',
          answer: 'Поможете телу почувствовать себя в безопасности и поймете как страхи влияют на вашу жизнь. Освободитесь от страха смерти, получите свободу для проживания своей яркой жизни. Наладите связь со своим с телом и почувствуете, что оно хочет вам сказать. Научитесь самостоятельно запускать программу самоисцеления.',
          isActive: true,
          isGray: false,
        },
        {
          question: 'Погружение в свои страхи',
          answer: 'Сделаете регрессивную практику в парах по алгоритму. Погрузитесь в прошлое и найдете ситуацию в которой зародился ваш самый сильный страх (никогда не стать мамой, страх одиночества, страх потерять ребенка, страх неполноценности, страх смерти и болезней, страх денег, возраста, войны и др.).',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Характер и болезни',
          answer: 'Узнаете взаимосвязь вашего характера и ваших болезней с точки зрения науки, кинезиологии, психологии и биологии. Научитесь выявлять эмоциональные блоки, убеждения и программы, которыми вы пользуетесь автоматически, неосознанно. Сделаете мышечный тест и найдете ответы на свои вопросы при помощи тела в обход ума.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Деньги в твоей голове',
          answer: 'Увидите свои замки, которые мешают росту ваших финансов. Разберетесь со страхами бедности, банкротства, несостоятельности и беспомощности. Сделаете групповую медитацию на изобилие, трансформируете страхи в лёгкость, наполненность и уверенность в себе. В новом состоянии будете готовы привлекать больше денег в свою жизнь.',
          isActive: false,
          isGray: false,
        },
        {
          question: 'Бонусы',
          answer: '<ul><li>PDF файл с авторской методикой для работы со страхами</li><li>PDF файл с авторской методикой для налаживания контакта между сознанием и телом</li><li>PDF файл с авторской методикой для принятия реальности</li><li>Конспекты уроков в текстовом формате</li><li>Презентации уроков</li></ul>',
          isActive: false,
          isGray: true,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-progr {
    padding: 20px 23px;
    background: $landingLightestGray;
    font-family: $titleFont;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;

        @media only screen and (min-width: 767px) {
            font-size: 34px;
            line-height: 150%;
        }
    }

    &__contentFAQ {
        @media only screen and (min-width: 1024px) {
            display: flex;
            padding-left: 27px;
        }
    }

    &__blue-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        background: $landingBlue;
        border-radius: 15px;
        width: 120px;
        height: 28px;
        text-align: center;
        margin: 30px auto;
        color: $white;
    }

    &__title-h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        margin: 20px 0;
        text-align: center;

        @media only screen and (min-width: 767px) {
            font-size: 22px;
            line-height: 140%;
        }
    }

    &__title-h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        margin: 20px 0;
        text-align: center;
    }

    &__title-h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        margin: -20px 0 20px 0;

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            line-height: 150%;
        }
    }

    &__content {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;

        p {
            margin: 20px 0;
        }
    }

    &__result-content {
        background: $white;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 20px;
        padding: 23px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin: 30px 0 40px 0;

        @media only screen and (min-width: 767px) {
            max-width: 620px;
            margin: 30px auto 70px auto;
            font-size: 18px;
        }

        @media only screen and (min-width: 1024px) {
            padding: 50px 90px;
            max-width: 720px;
            box-sizing: border-box;
        }

        .pink {
            color: #db38a4;
        }
    }

    &__result-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 20px;

        @media only screen and (min-width: 767px) {
            font-size: 16px;
            line-height: 120%;
        }
    }

    &__image-p1 {
        margin: 0 -23px;
        padding: 20px 23px;
        background: url("/images/course-landing/program-1-bg.png") no-repeat 50% 0;
        background-size: 390px;
        text-align: center;
        min-height: 204px;

        @media only screen and (min-width: 767px) {
            background-size: 660px;
            min-height: 345px;
        }
    }

    &__image-p2 {
        margin: 0 -23px;
        padding: 20px 23px;
        background: url("/images/course-landing/program-2-bg.png") no-repeat 50% 0;
        background-size: 424px;
        text-align: center;
        min-height: 204px;

        @media only screen and (min-width: 767px) {
            background-size: 680px;
            min-height: 335px;
        }
    }

    &__image-p3 {
        margin: 0 -23px;
        padding: 20px 23px;
        background: url("/images/course-landing/program-3-bg.png") no-repeat 50% 0;
        background-size: 424px;
        text-align: center;
        min-height: 204px;

        @media only screen and (min-width: 767px) {
            background-size: 654px;
            min-height: 380px;
            margin-bottom: -40px;
        }
    }

    &__image-p4 {
        margin: 0 -23px;
        padding: 20px 23px;
        background: url("/images/course-landing/program-4-bg.png") no-repeat 50% 0;
        background-size: 424px;
        text-align: center;
        min-height: 204px;

        @media only screen and (min-width: 767px) {
            background-size: 680px;
            min-height: 405px;
            margin-bottom: -70px;
        }
    }

    &__lock {
        background: url("/images/course-landing/pink-lock.svg");
        width: 12px;
        height: 15px;
        display: inline-block;
        margin: 0 5px;
    }

    &__castle {
        background: url("/images/course-landing/blue-castle.svg");
        width: 19px;
        height: 19px;
        display: inline-block;
        margin: 0 5px;
    }
}
</style>
