<template>
    <div class="course-faq-btm">
        <div class="course-landing-title">Ответы на вопросы</div>

        <div class="course-faq-btm__faq">
            <div class="course-faq-btm__faq-item"
                v-for="(item, index) in questions"
                 :class="{'isActive': item.isActive}"
                 :key="index"
            >
                <div class="course-faq-btm__faq-title"
                     v-html="item.question"
                     @click="toggleActiveState(index)"
                ></div>
                <div class="course-faq-btm__faq-content" v-html="item.answer"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseTarget',
  data() {
    return {
      questions: [
        {
          question: 'Как часто будут уроки?',
          answer: '<p>Живые онлайн лекции и практики (уроки) будут проходить 3 раза в неделю. </p><p>Конкретные даты и время вы сможете посмотреть на платформе в расписании и заранее запланировать время для них.\n</p>',
          isActive: false,
        },
        {
          question: 'Сколько длятся уроки?',
          answer: '<p>Уроки будут проходить вживую онлайн, длиться будут в среднем — два часа. К большинству уроков есть бонусы, чек-листы и презентации для удобного обучения.</p>',
          isActive: false,
        },
        {
          question: 'Будут ли записи уроков?',
          answer: '<p>Да, конечно, вы сможете посмотреть уроки в удобное для вас время. </p>',
          isActive: false,
        },
        {
          question: 'Сколько времени понадобится на домашние задания?',
          answer: '<p>От 20 минут.</p>',
          isActive: false,
        },
        {
          question: 'Можно ли повысить тариф позже, во время курса?\n',
          answer: '<p>Нет</p>',
          isActive: false,
        },
        {
          question: 'Смогу ли я вернуть деньги, если курс не понравится?',
          answer: '<p>В течение трех дней после старта курса.\n</p>',
          isActive: false,
        },
        {
          question: 'Что делать после оплаты?',
          answer: '<p>Поздравляем, место на курсе ваше! На почте будет письмо с инструкцией, что делать дальше. Отправитель hello@elena.shop.</p><p>P.S. Если письма нет на почте, проверьте папку «Спам». Если и там не нашли, напишите «Нет письма» в службу заботы: <a href="https://t.me/helpElenaSiaglo_bot ">https://t.me/helpElenaSiaglo_bot </a></p>',
          isActive: false,
        },
        {
          question: 'Оставили заявку, что дальше?',
          answer: '<p>Заявка без предоплаты не бронирует место на курс и не фиксирует стоимость. Заявка позволяет оплатить курс, если останутся места. Чтобы забронировать место и цену, необходимо оплатить полностью или оставить предоплату 3000 рублей. На вашей почте есть письмо со ссылкой на доплату.</p><p>Старт потока — 1 июля.'
          + '<br>Оплатить можно до 30 июня включительно.  Но лучше поторопиться, 29 июня пройдет практика с Еленой «Распаковка своих замков». На практике смогут участвовать те, кто оставил предоплату или оплатил полностью.</p> <p>P.S. Если письма нет на почте, проверьте папку «Спам». Если и там не нашли или остались вопросы, нужна консультация, напишите в службу заботы <a href="https://t.me/helpElenaSiaglo_bot">https://t.me/helpElenaSiaglo_bot</a> </p>',
          isActive: false,
        },
        {
          question: 'Оставили предоплату, что дальше?',
          answer: '<p>Предоплата 3000 рублей бронирует место на курс и фиксирует стоимость. Что дальше: на вашей почте есть письмо со ссылкой на доплату.</p><p>Старт потока — 1 июля.<br>Оплатить можно до 30 июня включительно. </p><p>P.S. Если письма нет на почте, проверьте папку «Спам». Если и там не нашли или остались вопросы, нужна консультация, напишите в службу заботы <a href="https://t.me/helpElenaSiaglo_bot">https://t.me/helpElenaSiaglo_bot</a> </p>',
          isActive: false,
        },
        {
          question: 'Рассрочка',
          answer: '<p>Рассрочка банка доступна гражданам РФ до 3 месяцев. Чтобы подать заявку, перейдите на страницу оплаты, нажмите на кнопку «Заплатить по частям».</p><p>Если банк отказал в рассрочке или вы гражданин другой страны, вам необходимо оплатить всю сумму.</p>',
          isActive: false,
        },
        {
          question: 'Не получается оплатить\n',
          answer: '<p>Так бывает из-за большой нагрузки на платежные системы. Попробуйте через пару минут снова. Если не получается, обратитесь в службу заботы <a href="https://t.me/helpElenaSiaglo_bot">https://t.me/helpElenaSiaglo_bot</a>, вам помогут.</p>',
          isActive: false,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    toggleActiveState(index) {
      this.questions.forEach((item, indexItem) => {
        const currentItem = this.questions[indexItem];

        if (index === indexItem) {
          currentItem.isActive = !currentItem.isActive;
        } else {
          currentItem.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-faq-btm {
    padding: 40px 23px 40px 23px;
    background: $landingLightestGray
    url("/images/course-landing/target-section-bg.png") 50% 0 no-repeat;

    &__faq-item {
        background: $white;
        border-radius: 15px;
        margin-bottom: 6px;

        @media only screen and (min-width: 767px) {
            max-width: 520px;
            margin: 0 auto 6px auto;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 620px;
        }

        &.isActive {
            .course-faq-btm__faq-content {
                max-height: 1200px;
                padding: 15px;
            }

            .course-faq-btm__faq-title {
                strong {
                    color: #DB38A4;
                }

                &:after {
                    background:
                            url("/images/course-landing/minus-icon-blue.svg")
                            $landingGray no-repeat 50% 50%;
                }
            }
        }
    }

    &__faq-title {
        padding: 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        position: relative;
        padding-right: 50px;

        strong {
            font-weight: 900;
            color: #7D89A1;
        }

        &:after {
            content: '';
            position: absolute;
            right: 12px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;
        }
    }

    &__faq-content {
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
