<template>
    <div class="question-form">
        <div class="question-form__block">
            <div class="question-form__title">
                Остались вопросы?
                Нужна помощь?
            </div>
            <div class="question-form__content">
                По любым возникшим вопросам вы можете
                связаться с нашей службой заботы.
            </div>
            <a href="https://t.me/elenasiaglo_marathonbot" class="es-button white">Задать вопрос</a>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseQuestionForm',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.question-form {
    background: $landingLightestGray;
    padding: 20px 23px 20px 23px;
    font-family: $titleFont;

    @media only screen and (min-width: 1024px) {
        max-width: 720px;
        margin: auto;
    }

    &__block {
        background: linear-gradient(153.47deg, #292935 32.3%, #414148 96.49%);
        border-radius: 20px;
        padding: 34px;
        color: $white;
        text-align: center;

        @media only screen and (min-width: 767px) {
            padding: 40px 140px 50px 140px;
        }

        .es-button {
            font-weight: 500;
            font-size: 12px;
            color: #292935;
            font-family: $titleFont;
            margin-bottom: 0;

            @media only screen and (min-width: 767px) {
                width: 200px;
                margin: 40px auto 0 auto;
            }
        }
    }

    &__title {
        font-weight: 500;
        font-size: 19px;
        line-height: 120%;
        margin-bottom: 20px;

        @media only screen and (min-width: 767px) {
            font-size: 22px;
            line-height: 120%;
        }
    }

    &__content {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        margin-bottom: 20px;

        @media only screen and (min-width: 767px) {
            font-size: 14px;
            line-height: 150%;
        }
    }
}
</style>
