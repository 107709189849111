<template>
    <div class="cart-list opened">

        <div class="cart-list__content">
            <div class="cart-list__item"
                 v-for="item in infoForMeditationsInCart.list"
                 :key="item.id"
            >
                <div class="cart-list__item-name">
                    {{item.name}} (доступ на 6 месяцев)
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CartProductsList',
  components: {},
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    isGetCourse() {
      return this.$store.state.general.isGetCourse;
    },
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
    promoDiscount() {
      return this.$store.state.payment.promoDiscount;
    },
    promoDiscountType() {
      return this.$store.state.payment.promoDiscountType;
    },
    activatedPromoCode() {
      return this.$store.state.payment.activatedPromoCode;
    },
  },
  methods: {
    toggleList() {
      this.isOpened = !this.isOpened;
    },
    wordForm(num, word) {
      const cases = [2, 0, 1, 1, 1, 2];
      return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.cart-list {
    border-top: solid 1px #E0E4F0;
    border-bottom: solid 1px #E0E4F0;
    margin-bottom: 20px;

    &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding: 10px 2px;
        position: relative;
        margin-bottom: 0;
        transition: all 200ms;

        &:after {
            position: absolute;
            right: 8px;
            top: 9px;
            cursor: pointer;
            content: '';
            display: block;
            background: url("/images/arrow-right-small.svg") no-repeat 50% 50%;
            background-size: 8px;
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
            transition: all 200ms;
        }
    }

    &__content {
        padding-bottom: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 200ms;
    }

    &.opened {
        .cart-list__content {
            max-height: 1000px;
        }

        .cart-list__title {
            margin-bottom: 10px;

            &:after {
                transform: rotate(-90deg);
            }
        }
    }

    &__item {
        font-size: 12px;
        color: $lightGray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 8px 8px 0;
    }

    &__actions {

    }
}
</style>
