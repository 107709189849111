<template>
  <div class="landing-page">
    <BaseTelegramPaymentSuccess></BaseTelegramPaymentSuccess>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseTelegramPaymentSuccess from '@/components/secondary-pages/BaseTelegramPaymentSuccess.vue';

export default {
  name: 'TelegramPaymentSuccess',
  components: {
    BaseTelegramPaymentSuccess,
  },
};
</script>
