<template>
  <div class="landing-page">
    <BaseInsta></BaseInsta>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseInsta from '@/components/secondary-pages/BaseInsta.vue';
import Footer from '@/components/general/Footer.vue';

export default {
  name: 'InstaPageStart',
  components: {
    BaseInsta,
    Footer,
  },
};
</script>
