<template>
    <div class="welcome-page">
        <Header isNotFixed="true"></Header>
        <template v-if="!isPaymentPage">
            <ConsultatantLandingHeader></ConsultatantLandingHeader>
            <ConsultantLandingOurTeam></ConsultantLandingOurTeam>
            <ConsultantLandingRequestExample></ConsultantLandingRequestExample>
            <ConsultantLandingFlow></ConsultantLandingFlow>
            <ConsultantLandingList></ConsultantLandingList>
            <ConsultantLandingTgPromo></ConsultantLandingTgPromo>
            <ConsultantLandingSubscribeSteps></ConsultantLandingSubscribeSteps>
        </template>
        <ConsultantPayment v-if="isPaymentPage"></ConsultantPayment>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import ConsultatantLandingHeader from '@/components/secondary-pages/consultant-list/ConsultantLandingHeader.vue';
import ConsultantLandingOurTeam from '@/components/secondary-pages/consultant-list/ConsultantLandingOurTeam.vue';
import ConsultantLandingRequestExample from '@/components/secondary-pages/consultant-list/ConsultantLandingRequestExample.vue';
import ConsultantLandingFlow from '@/components/secondary-pages/consultant-list/ConsultantLandingFlow.vue';
import ConsultantLandingList from '@/components/secondary-pages/consultant-list/ConsultantLandingList.vue';
import ConsultantLandingTgPromo from '@/components/secondary-pages/consultant-list/ConsultantLandingTgPromo.vue';
import ConsultantLandingSubscribeSteps from '@/components/secondary-pages/consultant-list/ConsultantLandingSubscribeSteps.vue';
import ConsultantPayment from '@/components/secondary-pages/consultant-list/ConsultantPayment.vue';

export default {
  name: 'BaseConsultatantList',
  components: {
    Header,
    ConsultatantLandingHeader,
    ConsultantLandingOurTeam,
    ConsultantLandingRequestExample,
    ConsultantLandingFlow,
    ConsultantLandingList,
    ConsultantLandingTgPromo,
    ConsultantLandingSubscribeSteps,
    ConsultantPayment,
    Footer,
  },
  data() {
    return {
    };
  },
  computed: {
    isPaymentPage() {
      return this.$route.name === 'ConsultatantPayment';
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

body {
    background: red;
}

.welcome-page {
    .base-header {
        background: $white;
    }

    .invite-banner {
        margin: 24px;
    }

    &__back {
        line-height: 70px;
        text-align: center;

        a {
            font-size: 14px;
            color: $main-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
