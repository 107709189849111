<template>
    <div class="wlm-works">
        <div class="landing-title-group">
            <div id="howItWorksSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                РЕГИСТРАЦИЯ НА ВЕБИНАР
            </div>
            <div class="landing-title-group__mid">Отзывы участников  о вебинаре</div>
        </div>

        <div class="wlm-works__video-slider glide-video">
            <div data-glide-el="track" class="glide__track">
                <div class="glide__slides">
                    <div class="wlm-works__video glide__slide">
                        <img src="/images/registration-webinar/registration-review-1.png">
                    </div>
                    <div class="wlm-works__video glide__slide">
                        <img src="/images/registration-webinar/registration-review-2.png">
                    </div>
                    <div class="wlm-works__video glide__slide">
                        <img src="/images/registration-webinar/registration-review-3.png">
                    </div>
                </div>
            </div>
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>

                <div class="wlm-works__dots glide__bullets" data-glide-el="controls[nav]">
                    <button class="glide__bullet" data-glide-dir="=0"></button>
                    <button class="glide__bullet" data-glide-dir="=1"></button>
                    <button class="glide__bullet" data-glide-dir="=2"></button>
                </div>

                <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'RegistrationReviews',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    setTimeout(() => {
      if (window.innerWidth < 768) {
        this.glide = new Glide('.glide-video', {
          perView: 1,
          gap: 20,
          peek: { before: 0, after: 80 },
          animationDuration: 300,
          rewind: false,
        }).mount({ Controls, Swipe, Breakpoints });
      }
    }, 300);
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";

.wlm-works {
    padding: 50px 24px;
    background: #edf1ff;

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 20px 24px;
    }

    &__video-slider {
        img {
            max-width: 100%;
            border-radius: 20px;
            display: block;
        }

        @media only screen and (max-width: 767px) {
            margin: 0 -24px 0 0;
        }
    }

    .glide__arrows {
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        display: none;

        @media only screen and (max-width: 767px) {
            display: flex;
        }

        .glide__arrow {
            background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 10px;
            border: 0;
            position: relative;
            top: 1px;
            flex-shrink: 0;
            cursor: pointer;

            &--right {
                transform: rotate(180deg);
            }
        }
    }

    &__dots {
        text-align: center;
        margin: 0 20px;

        .glide__bullet {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 2px;
            background: rgba($main-blue, 0.1);
            border: 5px solid #edf1ff;
            padding: 0;
            box-sizing: unset;
            cursor: pointer;
        }

        .glide__bullet--active {
            background: $main-blue;
        }
    }

    &__video {
        width: 220px;
        margin: 0 10px;
        position: relative;

        border: dashed $main-blue-bright 1px;
        padding: 6px;
        border-radius: 20px;
        box-sizing: border-box;

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: auto;
            transform: scale(0.85);
            transition: transform 200ms;

            &.glide__slide--active {
                transform: scale(1);
            }
        }
    }
}
</style>
