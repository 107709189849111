<template>
    <div class="wlm-advantages">
        <div class="landing-title-group">
            <div id="advantagesSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ
            </div>
            <div class="landing-title-group__mid">Что даст вам курс</div>
        </div>

        <div class="landing-title-medium">
            На курсе вы получите пошаговую инструкцию,
            как подготовить себя к беременности.
        </div>

        <div class="wlm-advantages__list">
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_1.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы научитесь</div>
                    <div class="wlm-advantages__content">
                        ощущать свой женский центр, свою матку.
                        Во время специальной медитации вы погрузитесь
                        в ощущения в теле и почувствуете пульсацию.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_2.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы настроитесь</div>
                    <div class="wlm-advantages__content">
                        на гармоничную и здоровую беременность,
                        наладите связь со своим будущим малышом.
                        Это даст поддержку и чувство безопасности.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_3.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы сделаете</div>
                    <div class="wlm-advantages__content">
                        очень важную практику сепарации от родителей.
                        Это даст чувство личной силы, уверенности в себе,
                        избавит от внутренних конфликтов.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_4.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы сделаете</div>
                    <div class="wlm-advantages__content">
                        диагностику вашей родовой системы,
                        проведете коррекцию, освободитесь от родовых страхов.
                        Это вернет ощущение огромной силы и поддержки своего рода.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_5.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы узнаете</div>
                    <div class="wlm-advantages__content">
                        много нового о своем теле в моем вебинаре про 5
                        биологических законов природы.
                        По каким законам оно живет, научитесь его слышать и понимать.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_6.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы ощутите</div>
                    <div class="wlm-advantages__content">
                        невероятную любовь и благодарность к
                        своему телу в медитации на принятие себя.
                        Это вернет вам гармонию и целостность.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_7.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы разовьете</div>
                    <div class="wlm-advantages__content">
                        навык общения со своим высшим Я, прокачаете интуицию.
                        Это поможет вам принимать правильные
                        решения в жизни и найти своё предназначение.
                    </div>
                </div>
            </div>
            <div class="wlm-advantages__item">
                <div class="wlm-advantages__item-image">
                    <img src="/images/landing/icn_8.svg">
                </div>
                <div class="wlm-advantages__text">
                    <div class="wlm-advantages__title">Вы узнаете</div>
                    <div class="wlm-advantages__content">
                        четкий пошаговый алгоритм загадывания желаний.
                        Это приблизит вас к исполнению вашей заветной мечты.
                    </div>
                </div>
            </div>
        </div>

        <WelcomeStartCounter secondary="true" v-if="isUserFromWebinar"></WelcomeStartCounter>
        <div class="wlm-advantages__start-block" v-else-if="isUserAuthed">
            <div class="wlm-advantages__buy-more">
                <p>Чтобы получить максимальный эффект мы
                    предлагаем докупить остальные товары до
                    полного курса по <strong>специальной цене.</strong></p>
                <button class="es-button white " @click="scrollToPayment()">
                    Начать
                </button>
            </div>
        </div>
        <div class="wlm-advantages__action" v-else>
            <button class="es-button blue" @click="scrollToPayment()">
                Начать
            </button>
        </div>
    </div>
</template>

<script>
import WelcomeStartCounter from '@/components/secondary-pages/welcome-landing/WelcomeStartCounter.vue';

export default {
  name: 'WelcomeLandingAdvantages',
  components: {
    WelcomeStartCounter,
  },
  data() {
    return {
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
    };
  },
  computed: {
  },
  methods: {
    scrollToPayment() {
      this.$store.dispatch('scrollToLandingPayment');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.wlm-advantages {
    padding: 50px 24px;
    background: $white;

    @media only screen and (max-width: 767px) {
        padding: 30px 24px 20px 24px;
    }

    &__start-block {
        text-align: center;
        background: $main-blue;
        margin: auto;
        padding: 20px 15px 15px 15px;
        border-radius: 10px;
        color: $white;

        button {
            display: inline-block;
            width: auto;
            padding: 0 25px;
            cursor: pointer;
            margin: 25px 0 0 0;

            @media only screen and (max-width: 450px) {
                width: 100%;
            }
        }
    }

    &__buy-more {
        font-size: 17px;
        line-height: 22px;
        max-width: 600px;
        margin: auto;

        @media only screen and (max-width: 767px) {
            font-size: 19px;
            line-height: 24px;
        }

        strong {
            font-weight: 600;
            color: #eee61e;
        }
    }

    &__item {
        display: flex;
        padding-top: 30px;
        padding-bottom: 40px;
        align-items: flex-start;
        overflow: hidden;

        @media only screen and (max-width: 767px) {
            padding-bottom: 20px;
            padding-top: 0;
        }

        &:last-child {
            .wlm-advantages__item-image:after {
                display: none;
            }
        }
    }

    &__item-image {
        margin-right: 40px;
        margin-top: -30px;
        position: relative;

        @media only screen and (max-width: 767px) {
            margin-top: 0;
            margin-right: 28px;
        }

        img {
            display: block;

            @media only screen and (max-width: 767px) {
                width: 40px;
            }
        }

        &:after {
            position: absolute;
            content: '';
            border: dashed 1px #CDD3E2;
            height: 200px;
            top: 100%;
            left: 50%;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;

        @media only screen and (max-width: 767px) {
            font-size: 16px;
            padding-top: 11px;
            margin-bottom: 5px;
        }
    }

    &__content {
        font-size: 17px;
        line-height: 20px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__action {
        text-align: center;

        @media only screen and (max-width: 400px) {
            margin-top: 30px;
        }

        button {
            display: inline-block;
            width: 130px;
            cursor: pointer;

            @media only screen and (max-width: 400px) {
                width: 100%;
            }
        }
    }
}
</style>
