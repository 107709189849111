<template>
    <div class="payment-FAQ" >
        <div class="payment-FAQ__faq-item"
             v-for="(item, index) in questions"
             :class="{'isActive': item.isActive}"
             :key="index"
             @click="toggleActiveState(index)"
        >
            <div class="payment-FAQ__faq-title"
                 v-html="item.question"
            ></div>
            <div class="payment-FAQ__faq-content" v-html="item.answer"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CoursePaymentFAQ',
  data() {
    return {
      questions: [
        {
          question: 'Условия оформления рассрочки?',
          answer: '<ol><li>Без процентов на 3 месяца.</li><li>Первый взнос только через месяц, сейчас ничего не платите.</li><li>Возможно только для граждан России.</li></ol>',
          isActive: false,
        },
        {
          question: 'Как оформить рассрочку?',
          answer: '<ol class="envelope-bg"><li>Нажимайте кнопку «Оформить рассрочку», на странице оплаты прокрутите вниз и нажмите на кнопку «Заплатить по частям». Далее выбирайте условия и заполняйте анкету до конца.</li><li>Ждите звонка от менеджера банка, он согласует дату и время встречи для подписания договора.</li><li>Подпишите договор. После этого банк отправит нам сообщение о подписании, и вы получите на почту письмо с доступом к курсу.</li></ol>',
          isActive: false,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    toggleActiveState(index) {
      this.questions.forEach((item, indexItem) => {
        const currentItem = this.questions[indexItem];

        if (index === indexItem) {
          currentItem.isActive = !currentItem.isActive;
        } else {
          currentItem.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.payment-FAQ {
    background: $landingLightestGray;
    padding: 5px 23px 20px 23px;

    &__faq-item {
        margin-bottom: 6px;
        border-bottom: solid $landingGray 1px;

        @media only screen and (min-width: 767px) {
            text-align: center;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }

        @media only screen and (min-width: 1024px) {
           &:last-child {
               border-bottom: 0;
           }
        }

        &.isActive {
            .payment-FAQ__faq-content {
                max-height: 1200px;
                padding: 5px 0 15px 0;
            }

            .payment-FAQ__faq-title {
                strong {
                    color: #DB38A4;
                }

                &:after {
                    background:
                            url("/images/course-landing/minus-icon-blue.svg")
                            $landingGray no-repeat 50% 50%;
                }
            }
        }
    }

    &__faq-title {
        padding: 15px 50px 15px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        position: relative;

        @media only screen and (min-width: 767px) {
            font-size: 22px;
            line-height: 140%;
            display: inline-block;
        }

        strong {
            font-weight: 900;
            color: #7D89A1;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;
        }
    }

    &__faq-content {
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        ol {
            list-style-type: none;
            counter-reset: num;
            margin: 0 0 0 25px;
            padding: 15px 0 5px 0;

            @media only screen and (min-width: 767px) {
                text-align: left;
                max-width: 520px;
                margin: 0 auto;
            }

            &.envelope-bg {
                background: url("/images/course-landing/mail-bg.svg") no-repeat 50% 97%;
                padding-bottom: 80px;
            }

            li {
                position: relative;
                padding: 0 0 20px 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;

                &:last-child {
                    padding-bottom: 0;
                }

                &:before {
                    content: counter(num)".";
                    counter-increment: num;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: -25px;
                    width: 30px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 150%;
                }
            }
        }
    }
}
</style>
