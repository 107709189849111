<template>
    <div class="welcome-about">
        <div class="welcome-about__header">
            <div class="welcome-about__descr">
                <div class="welcome-about__descr-text isSuccess" v-if="isSuccessPage">
                    Спасибо за покупку. В ближайшее время
                    менеджер добавит вас в закрытую группу в телеграме.
                </div>
                <div class="welcome-about__descr-text" v-else>
                    Я проведу для вас медитацию в прямом эфире
                    в закрытом телеграм канале. Будем с вами в
                    одном поле,
                    <strong class="blue">получите мою поддержку,</strong>
                    зарядитесь
                    моим состоянием.<br><br> Начало во вторник
                    17 мая в 19.00 по Москве.
                    Стоимость символическая 190 рос. рублей (около $3),
                    чтобы у всех была возможность прийти.
                    Доступ в канал получите после оплаты.
                    Для тех, кто оплатит, выложу запись медитации в канал,
                    сможете потом повторять.
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'LandingWebinarDescr',
  data() {
    return {
    };
  },
  props: {
    isSecond: String,
    isThird: String,
    isFourth: String,
    isFifth: String,
    isSix: String,
    isSeventh: String,
  },
  computed: {
    isSuccessPage() {
      return this.$route.name === 'TelegramFlowSuccess';
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";
 @import "../../../assets/scss/common/forms";

 .isDesktop {
     @media only screen and (max-width: 767px) {
         display: none;
     }
 }

 .isMobile {
     display: none;

     @media only screen and (max-width: 767px) {
         display: block;
     }
 }

 .welcome-about {
    padding: 50px 24px;
     background: $white;

     &.largeTopPadding {
         padding-top: 80px;
     }

     &__body {
         position: relative;
     }

     &__video {
         width: 100%;
         text-align: center;
         position: relative;

         &:before {
             display: block;
             content: '';
             width: 100%;
             height: 60px;
             position: absolute;
             left: 0;
             top: 0;
         }
     }

     &__dotes {
         letter-spacing: 3px;
         font-size: 30px;
         margin-bottom: 20px;
         display: block;
     }

    @media only screen and (max-width: 767px) {
        padding: 30px 24px 20px 24px;
    }

    &__our-audience {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 20px 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    &__descr-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        .es-button {
            max-width: 320px;
            margin: auto;
        }

        @media only screen and (max-width: 700px) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 2px;

            .es-button {
                max-width: none;
            }
        }

        .blue {
            color: $main-blue;
        }
    }

    &__descr-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;

        @media only screen and (max-width: 700px) {
            font-size: 14px;
            line-height: 21px;
        }

        .blue {
            color: $main-blue;
            font-weight: 600;
        }

        &.isSuccess {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            padding:  80px 0;
            line-height: 30px;

        }
    }

     &__cabinet-link {
         margin: 20px 0 0 0;

         @media only screen and (max-width: 700px) {
             margin: 40px 0 0 0;
         }

         a {
             color: $main-blue;
         }
     }

}
</style>
