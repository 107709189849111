<template>
    <div class="mh-will-know">
        <div class="mh-content">
            <div class="mh-title">
                <span class="purple">Какие вопросы</span> вы<br>
                решите на марафоне:
            </div>

            <div class="mh-subtitle">Любая ситуация решаема! Даже если у вас…</div>

            <div class="mh-will-know_content">
                <div class="mh-will-know_points-list right">
                    <div class="mh-will-know_points-line right"></div>
                    <div class="mh-will-know_point-item purple">
                        <p>Страх, что болезни и симптомы никогда не пройдут</p>
                    </div>
                    <div class="mh-will-know_point-item purple">
                        <p>Сложности в отношениях и партнер мало зарабатывает</p>
                    </div>
                    <div class="mh-will-know_point-item purple">
                        <p>Тревога за свое будущее и нету сил что-то менять</p>
                    </div>
                </div>
                <div class="mh-will-know_text-side">
                    <div class="mh-will-know_text-image">
                        <img src="/images/marathon/mh-female-memoji-2.png">
                    </div>

                    <div class="mh-will-know_text-title">
                        Пора это изменить!<br>
                        Приходите на бесплатный<br>
                        марафон, чтобы открыть свой<br>
                        путь к здоровью, счастью и успеху.
                    </div>

                    <button
                            class="mh-button mh-button--purple mh-button--shadow"
                            @click="scrollToForm()"
                    >
                        Записаться
                    </button>
                </div>
            </div>

            <div class="mh-highlight">
                Вас ждут мощные практики от успешного психолога и
                ведущей <strong class="purple">
                женского клуба медитаций и трансформаций</strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Header',
  data() {
  },
  computed: {
  },
  methods: {
    scrollToForm() {
      const hiddenElement = document.querySelector('#mh-form');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-will-know {
    background: rgb(253,252,255);
    background: linear-gradient(131deg, rgba(253,252,255,1) 0%, rgba(251,236,230,1) 100%);
    padding: 70px 0;

    @media only screen and (max-width: 768px) {
        padding: 40px 0;
    }

    .desktopOnly {
        @media only screen and (max-width: 1200px) {
            display: none;
        }
    }

    .mobileOnly {
        display: none;

        @media only screen and (max-width: 1200px) {
            display: block;
        }
    }

    .mh-content {
        max-width: 1200px;
        margin: auto;
        min-height: 650px;

        @media only screen and (max-width: 1200px) {
            max-width: 730px;
        }

        @media only screen and (max-width: 768px) {
           max-width: 360px;
            padding: 0 10px;
        }
    }

    .mh-highlight {
        background: linear-gradient(180deg, #742DDB 36.15%, #AE66FB 105.09%);
        border-radius: 50px;
        margin: 80px 0;
        padding:  80px;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 130%;
        color: $white;

        @media only screen and (max-width: 1200px) {
            padding: 57px 56px;
            font-size: 38px;
        }

        .purple {
            color: #F79CFF;
        }

        strong {
            font-weight: 600;
        }

        @media only screen and (max-width: 768px) {
            padding: 30px;
            font-size: 20px;
            margin: 30px 0;

            .mh-button {
                padding: 0 10px;
                width: 260px;
            }
        }
    }

    &_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 80px;

        @media only screen and (max-width: 1200px) {
            flex-direction: column;
            margin-top: 50px;
        }

        @media only screen and (max-width: 768px) {
            margin-top: 30px;
        }
    }

    &_text-side {
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        width: 540px;
        margin-top: -50px;

        @media only screen and (max-width: 1200px) {
            text-align: center;
            margin-top: 0;
        }

        @media only screen and (max-width: 768px) {
            font-size: 18px;
            width: auto;
        }
    }

    &_text-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 120%;
        margin-bottom: 30px;

        @media only screen and (max-width: 768px) {
            font-size: 20px;
        }
    }

    &_text-content {
        margin-bottom: 50px;
    }

    &_text-image {
        margin-bottom: 20px;

        img {
            max-width: 121px;
        }
    }

    &_points-list {
        position: relative;
        width: 500px;

        .mh-button {
            margin: 40px auto 0 auto;
        }

        @media only screen and (max-width: 1200px) {
            width: 100%;
            margin-bottom: 50px;
        }

        @media only screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    &_points-line {
        width: 26px;
        border: solid $main-purple 2px;
        border-right: 0;
        position: absolute;
        height: 80%;
        left: -70px;
        top: 10%;

        @media only screen and (max-width: 1200px) {
            display: none;
        }

        &.right {
            left: auto;
            right: -70px;
            border-right: solid $main-purple 2px;
            border-left: 0;

            &:after {
                left: auto;
                right: -26px;
            }
        }

        &:after {
            position: absolute;
            content: '';
            height: 2px;
            width: 26px;
            background: $main-purple;
            top: 50%;
            left: -26px;
        }
    }

    &_point-item {
        border: 1px solid #E1CDFF;
        box-shadow: 0 40px 60px rgba(135, 66, 235, 0.1);
        border-radius: 20px;
        background: $white;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        padding: 20px 30px;
        margin: 20px 0;
        text-align: left;

        &.purple {
            background: url('/images/marathon/mh-lightning.svg') no-repeat 30px 50% $main-purple;
            padding-left: 80px;
            color: $white;
        }

        @media only screen and (max-width: 768px) {
            font-size: 16px;
            margin: 5px 0;
        }
    }

    &_point-item-title {
        font-weight: 600;
        font-size: 18px;
        color: #F79CFF;
        margin-bottom: 15px;
    }

}
</style>
