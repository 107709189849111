<template>
    <div class="welcome-about" :class="{'largeTopPadding': isCourseInfoPage}">
        <div class="welcome-about__header" v-if="!isCourseInfoPage">
            <div class="welcome-about__descr">
                <div class="welcome-about__descr-title">
                    Мой курс <span class="blue">о доверии</span> к своему телу,
                    <span class="blue">об освобождении</span> от обид,
                    страхов, вины, убеждений, ограничивающих вас. <br>
                    <span class="welcome-about__dotes">.....</span>
                </div>
                <div class="welcome-about__descr-text">
                    Я создала этот курс для тех, кто точно готов к переменам и
                    трансформациям, для тех, кто хочет открыть свет для новой жизни.
                </div>
                <div class="welcome-about__cabinet-link isDesktop" v-if="isCabinet">
                    <router-link to="/courseInfo">Подробнее о курсе</router-link>
                </div>
            </div>
            <div class="welcome-about__main-image"></div>

            <div class="welcome-about__cabinet-link isMobile" v-if="isCabinet">
                <router-link to="/courseInfo">Подробнее о курсе</router-link>
            </div>
        </div>

        <div class="welcome-about__conspect" v-if="isWelcomeWebinarPage">
            <span class="hiddenLabel" id="conspectBanner"></span>
            <div class="welcome-about__conspect-title">
                Мы сделали для вас  КОНСПЕКТ вебинара.
            </div>
            <div class="welcome-about__conspect-text">
                В нем очень кратко и тезисно написаны главные выводы вебинара.
            </div>
            <a href="https://elena.shop/summary.pdf" target="_blank" class="es-button white bordered">Скачать PDF</a>
        </div>

        <div class="welcome-about__body" v-if="!isCabinet">
            <div class="landing-title-group">
                <div id="aboutSectionScrollMarker"
                     class="landing-title-group__hidden-marker"
                ></div>
                <div class="landing-title-group__small">МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ</div>
                <div class="landing-title-group__mid">Сколько нас</div>
            </div>

            <div class="glide-slider">
                <div class="glide__track" data-glide-el="track">
                    <div class="welcome-about__our-audience glide__slides">
                        <div class="welcome-about__our-audience-item baby">
                            <div class="welcome-about__our-audience-small-text">более</div>
                            <div class="welcome-about__our-audience-count">5 000</div>
                            <div class="welcome-about__our-audience-text">
                                написали о своей беременности или о благополучных родах
                            </div>
                        </div>
                        <div class="welcome-about__our-audience-item education">
                            <div class="welcome-about__our-audience-small-text">более</div>
                            <div class="welcome-about__our-audience-count">30 000</div>
                            <div class="welcome-about__our-audience-text">
                                проходят курс и  количество участниц каждый день растет
                            </div>
                        </div>
                        <div class="welcome-about__our-audience-item insta">
                            <div class="welcome-about__our-audience-small-text">более</div>
                            <div class="welcome-about__our-audience-count">150 000</div>
                            <div class="welcome-about__our-audience-text">
                                читают посты, смотрят сториз, пишут отзывы в Инстаграм
                            </div>
                        </div>
                    </div>
                </div>

                <div class="glide__arrows" data-glide-el="controls">
                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>

                    <div class="welcome-about__dots glide__bullets" data-glide-el="controls[nav]">
                        <button class="glide__bullet" data-glide-dir="=0"></button>
                        <button class="glide__bullet" data-glide-dir="=1"></button>
                        <button class="glide__bullet" data-glide-dir="=2"></button>
                    </div>

                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'WelcomeLandingAbout',
  data() {
    return {
      glide: '',

    };
  },
  computed: {
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isCourseInfoPage() {
      return this.$route.name === 'CourseInfo';
    },
    isWelcomeWebinarPage() {
      return this.$route.name === 'WelcomeWebinar';
    },
  },
  mounted() {
    setTimeout(() => {
      if (window.innerWidth < 550) {
        this.glide = new Glide('.glide-slider', {
          perView: 1,
          gap: 10,
          peek: { before: 0, after: 130 },
          animationDuration: 300,
          rewind: false,
          startAt: 0,
        }).mount({ Controls, Swipe, Breakpoints });
      }
    }, 300);
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";

 .isDesktop {
     @media only screen and (max-width: 767px) {
         display: none;
     }
 }

 .isMobile {
     display: none;

     @media only screen and (max-width: 767px) {
         display: block;
     }
 }

 .welcome-about {
    padding: 50px 24px;
    background: $white;

     &__conspect {
         background:
           $main-blue url("/images/registration-webinar/book-conspect.png") no-repeat 90% 90%;
         background-size: 220px;
         border-radius: 10px;
         padding: 20px 80px 100px 20px;
         color: $white;
         max-width: 360px;
         margin: 15px auto;
         position: relative;

         .hiddenLabel {
             position: absolute;
             top: -60px;
             width: 100%;
             display: block;
         }

         .es-button {
             width: 120px;
             background: transparent;
             color: $white;
             border: solid 1px $white;
         }
     }

     &__conspect-title {
         font-size: 16px;
         font-weight: 600;
         line-height: 26px;
         margin-bottom: 15px;
     }

     &__conspect-text {
         font-size: 14px;
         font-weight: 400;
         line-height: 20px;
         margin-bottom: 25px;
     }

     &.largeTopPadding {
         padding-top: 80px;
     }

     &__body {
         position: relative;
     }

     &__dotes {
         letter-spacing: 3px;
         font-size: 30px;
         margin-bottom: 20px;
         display: block;
     }

    @media only screen and (max-width: 767px) {
        padding: 30px 24px 20px 24px;
    }

    &__our-audience {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 20px 0;
    }

     .glide-slider {
         position: relative;

         @media only screen and (max-width: 550px) {
             max-width: 340px;
             margin: auto;
         }

         &:after {
             @media only screen and (max-width: 550px) {
                 width: 40px;
                 height: 100%;
                 position: absolute;
                 right: 0;
                 top: 0;
                 content: '';
                 background:
                         linear-gradient(268.78deg, #FFFFFF 0.94%, rgba(255, 255, 255, 0) 138.27%);
             }
         }

         @media only screen and (max-width: 767px) {
            margin-top: -40px!important;
         }
     }

     .glide__arrows {
         display: flex;
         justify-content: center;
         align-items: center;
         margin-top: 10px;

         @media only screen and (min-width: 550px) {
             display: none;
         }

         .glide__arrow {
             background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
             width: 24px;
             height: 24px;
             border-radius: 50%;
             margin: 0 10px;
             border: 0;
             position: relative;
             top: 1px;
             flex-shrink: 0;
             cursor: pointer;
             display: none;

             &--right {
                 transform: rotate(180deg);
             }
         }
     }

     &__dots {
         text-align: center;
         margin: 0 20px;

         .glide__bullet {
             width: 10px;
             height: 10px;
             border-radius: 50%;
             margin: 0 2px;
             background: rgba($main-blue, 0.1);
             border: 5px solid $white;
             padding: 0;
             box-sizing: unset;
             cursor: pointer;
         }

         .glide__bullet--active {
             background: $main-blue;
         }
     }

    &__our-audience-item {
        max-width: 280px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        padding-top: 20px;
        margin: 0 8px;

        @media only screen and (max-width: 550px) {
            width: 170px!important;
        }

        &.baby {
            background: url(/images/landing/baby-icon.svg) no-repeat 50px 0;
        }

        &.education {
            background: url(/images/landing/had-icon.svg) no-repeat 35px 5px;
        }

        &.insta {
            background: url(/images/landing/insta-icon.svg) no-repeat 45px 4px;
        }
    }

    &__our-audience-small-text {
        color: $main-blue;
        font-size: 18px;
        margin-bottom: 5px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__our-audience-count {
        color: $main-blue;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 8px;

        @media only screen and (max-width: 767px) {
            font-size: 25px;
        }
    }

    &__our-audience-text {
        font-family: $textFont;
        line-height: 24px;
        white-space: normal;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
            margin-bottom: 37px;
        }
    }

    &__main-image {
        width: 200px;
        height: 290px;
        background: url("/images/landing/welcome-about.png") 50% 50%;
        background-size: cover;
        border-radius: 120px;
        flex-shrink: 0;
        margin-left: 90px;
        margin-right: 20px;
        position: relative;

        @media only screen and (max-width: 700px) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
        }

        &:after,
        &:before {
            position: absolute;
            left: -20px;
            top: 50%;
            width: 7px;
            height: 7px;
            background: #D0D6E7;
            content: '';
            border-radius: 50%;
            margin-top: -3px;
        }

        &:before {
            left: auto;
            right: -20px;
        }
    }

    &__descr-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        @media only screen and (max-width: 700px) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 2px;
        }

        .blue {
            color: $main-blue;
        }
    }

    &__descr-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;

        @media only screen and (max-width: 700px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

     &__cabinet-link {
         margin: 20px 0 0 0;

         @media only screen and (max-width: 700px) {
             margin: 40px 0 0 0;
         }

         a {
             color: $main-blue;
         }
     }

}
</style>
