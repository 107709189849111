<template>
    <div class="start-counter"
         :class="{'isSecondary': secondary}"
    >
        <div class="start-counter__title large" v-if="secondary || payment">
            Успейте приобрести курс со скидкой!
        </div>

        <div class="start-counter__title" v-else>
            Скидка действует:
        </div>

        <div
                class="start-counter__timer"
                :class="{'isBordered': secondary || payment}"

        >
            <div class="start-counter__title" v-if="secondary || payment">
                Скидка действует:
            </div>

            <div class="start-counter__timer-cover">
                <div class="start-counter__timer-item days" v-if="timerDays > 0">
                    <strong>{{timerDays}}</strong>
                    {{wordForm(timerDays, ['день', 'дня', 'дней'])}}
                </div>
                <div class="start-counter__timer-item">
                    <strong>{{timerHours}}</strong>
                    {{wordForm(timerHours, ['час', 'часа', 'часов'])}}
                </div>
                <div class="start-counter__timer-item">
                    <strong>{{timerMinutes}}</strong>
                    {{wordForm(timerHours, ['минута', 'минуты', 'минут'])}}
                </div>
                <div class="start-counter__timer-item">
                    <strong>{{timerSeconds}}</strong>
                    {{wordForm(timerHours, ['секунда', 'секунды', 'секунд'])}}
                </div>
            </div>
        </div>

        <div class="start-counter__action"
             :class="{'small': secondary, 'hidden': payment}"
             v-if="!timeIsOver && !is3DaysModulePayment"
        >
            <button class="es-button white"
                    @click="scrollToPayment()"
            >
                Начать
            </button>
        </div>
        <div class="start-counter__time-over" v-if="timeIsOver">
            Время вышло
        </div>
    </div>
</template>

<script>

export default {
  name: 'WelcomeStartCounter',
  props: {
    secondary: String,
    payment: String,
  },
  data() {
    return {
      timerDays: '00',
      timerHours: '00',
      timerMinutes: '00',
      timerSeconds: '00',
      validDate: '',
      timeIsOver: false,
      is3DaysModulePayment: this.$route.name === 'Supermodule',
    };
  },
  mounted() {
    if (this.isSettingsReady) {
      this.checkStorageTime();
      this.setBackCounter();
    }
  },
  watch: {
    timeIsOver() {
      this.$store.commit('setLandingSaleTimeOver', this.timeIsOver);
    },
    isSettingsReady() {
      if (this.isSettingsReady) {
        this.checkStorageTime();
        this.setBackCounter();
      }
    },
  },
  computed: {
    modulesPageCookieDate() {
      return this.$store.state.general.modulesPageCookieDate;
    },
    isSettingsReady() {
      return this.$store.state.general.isSettingsReady;
    },
    webinarSaleTimeCookieDate() {
      return this.$store.state.general.webinarSaleTimeCookieDate;
    },
    webinarSaleTime3DaysCookieDate() {
      return this.$store.state.general.webinarSaleTime3DaysCookieDate;
    },
    singlePageCookieDate() {
      return this.$store.state.general.singlePageCookieDate;
    },
  },
  methods: {
    checkStorageTime() {
      const is3DaysLanding = this.$route.name === 'WelcomeWebinar36';
      const is3DaysModulePayment = this.$route.name === 'Supermodule';
      const is3DaysSinglePayment = this.$route.name === 'Finalprice';
      let endTime = localStorage.getItem('webinarSaleTime');
      let settingsCookieTime = this.webinarSaleTimeCookieDate;
      let cookieName = 'webinarSaleTime';

      if (is3DaysLanding) {
        endTime = localStorage.getItem('webinarSaleTime3Days');
        settingsCookieTime = this.webinarSaleTime3DaysCookieDate;
        cookieName = 'webinarSaleTime3Days';
      }

      if (is3DaysModulePayment) {
        endTime = localStorage.getItem('modulePayment3Days');
        settingsCookieTime = this.modulesPageCookieDate;
        cookieName = 'modulePayment3Days';
      }

      if (is3DaysSinglePayment) {
        endTime = localStorage.getItem('singlePayment3Days');
        settingsCookieTime = this.singlePageCookieDate;
        cookieName = 'singlePayment3Days';
      }

      if (endTime) {
        const currentTime = Date.parse(new Date());
        const endTimeTimeStamp = Date.parse(endTime.replace('-', '/').replace('-', '/'));
        const settingsCookieTimeStamp = Date.parse(settingsCookieTime.replace('-', '/').replace('-', '/'));

        if ((settingsCookieTimeStamp < currentTime) && (currentTime > endTimeTimeStamp)) {
          localStorage.removeItem(cookieName);
          this.checkStorageTime();
          return;
        }

        /* Если время вышло, то перезапустиим */
        if (currentTime > endTimeTimeStamp) {
          this.endTime = 0;
        }
      }

      if (!endTime) {
        const date = new Date();

        if (is3DaysLanding || is3DaysModulePayment || is3DaysSinglePayment) {
          date.setDate(date.getDate() + 3);
        } else {
          date.setMinutes(date.getMinutes() + 20);
        }

        const yyyy = date.getFullYear();
        const mm = date.getMonth() + 1;
        const dd = date.getDate();
        const hh = date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();

        endTime = `${yyyy}-${mm}-${dd} ${hh}:${m}:${s}`;

        if (is3DaysLanding) {
          localStorage.setItem('webinarSaleTime3Days', endTime);
        } else if (is3DaysModulePayment) {
          localStorage.setItem('modulePayment3Days', endTime);
        } else if (is3DaysSinglePayment) {
          localStorage.setItem('singlePayment3Days', endTime);
        } else {
          localStorage.setItem('webinarSaleTime', endTime);
        }

        this.$store.commit('setLandingSaleTimeEnabled', true);
      }

      this.validDate = endTime;
    },
    scrollToPayment() {
      this.$store.dispatch('scrollToLandingPayment');
    },
    setBackCounter() {
      const deadline = Date.parse(this.validDate.replace('-', '/').replace('-', '/'));

      setInterval(() => {
        const utcDate = new Date();
        const currentTime = Date.parse(utcDate);
        const distance = deadline - currentTime;

        /* Если время вышло, то перезапустим */
        if (distance <= 0) {
          this.checkStorageTime();
          this.setBackCounter();
          return false;
        }

        this.timerDays = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.timerHours = Math.floor((distance / (1000 * 60 * 60)) % 24);
        this.timerMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.timerSeconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (this.timerHours < 10) {
          this.timerHours = `0${this.timerHours}`;
        }

        if (this.timerMinutes < 10) {
          this.timerMinutes = `0${this.timerMinutes}`;
        }

        if (this.timerSeconds < 10) {
          this.timerSeconds = `0${this.timerSeconds}`;
        }

        return true;
      }, 1000);
    },
    wordForm(num, word) {
      const cases = [2, 0, 1, 1, 1, 2];
      return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.start-counter {
    background: $main-blue;
    padding: 18px 10px 15px 10px;
    max-width: 470px;
    margin: 20px auto 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    color: $white;
    width: calc(100% - 48px);
    box-sizing: border-box;

    &__time-over {
        margin: 25px 0;
    }

    &.isSecondary {
        padding: 30px 20px;
        max-width: none;
    }

    &__title {
        font-size: 13px;
        font-weight: 600;

        &.large {
            font-size: 18px;
            line-height: 30px;
            text-align: center;

            @media only screen and (max-width: 767px) {
                text-align: center;
            }
        }
    }

    &__action {
        button {
            width: 100%;
            font-size: 12px;
            font-weight: 600;
            font-family: $titleFont;
        }

        width: 270px;

        &.hidden {
            display: none;
        }

        &.small {
            width: 200px;

            @media only screen and (max-width: 450px) {
                width: 100%;
            }
        }

        @media only screen and (max-width: 450px) {
            width: 100%;
        }
    }

    &__timer-cover {
        display: flex;
        margin-top: 12px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__timer {
        display: flex;
        margin: 19px 0 10px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.isBordered {
            border: solid $white 1px;
            border-radius: 8px;
            padding: 20px;
            margin-top: 40px;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                background: url("/images/landing/bant.svg");
                width: 42px;
                height: 22px;
                top: -22px;
                left: 50%;
                margin-left: -21px;
            }
        }
    }

    &__timer-item {
        margin: 0 10px;
        font-size: 12px;
        position: relative;
        text-align: center;

        &:nth-child(2):before,
        &:nth-child(2):after {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: -12px;
            top: 15px;
            background: $white;
        }

        &:nth-child(4):before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: -12px;
            top: 15px;
            background: $white;
        }

        &:nth-child(2):after {
            left: auto;
            right: -12px;
        }

        &.days {
            strong {
                width: 30px;
            }
        }

        strong {
            display: block;
            font-size: 37px;
            font-weight: 600;
            letter-spacing: 3px;
            margin-bottom: 4px;
            width: 55px;
        }
    }
}
</style>
