<template>
    <div class="say-thanks">
        <div class="say-thanks__top-img">
            <img src="/images/say-thanks/say-thanks-main-bg.jpg"/>
        </div>
        <div class="say-thanks__logo">
            <img src="/images/say-thanks/say-thanks-logo.png">
        </div>

        <div class="say-thanks__logo-mobile">
            <img src="/images/say-thanks/say-thanks-logo.svg">
        </div>

        <div class="say-thanks__top-img-mobile">
            <img src="/images/say-thanks/say-thanks-main-mobile.png"/>
        </div>

        <div class="say-thanks__title">
            Сказать елене <br>
            <span>«Спасибо»</span><br>
            можно здесь
        </div>

        <div class="say-thanks__text">
            Любая сумма по сердцу
        </div>

        <div class="say-thanks__button pink" @click="showSayThanksForm()">
            спасибо
        </div>
    </div>
</template>

<script>
export default {
  name: 'SayThanks',
  components: {
  },
  data() {
  },
  methods: {
    showSayThanksForm() {
      this.$store.commit('setCurrentTemplate', 'say-thanks-form');
      this.$store.commit('showDialog');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.say-thanks {
    padding: 40px 0 10px 0;
    background: #F6F8FE;
    min-height: calc(100vh - 124px);

    @media only screen and (min-width: 767px) {
        padding-top: 0;
    }

    &__top-img-mobile {
        text-align: center;

        img {
            max-height: 100%;
            width: 290px;
        }

        @media only screen and (min-width: 767px) {
            display: none;
        }
    }

    &__top-img {
        margin-bottom: -10px;
        position: relative;
        display: none;

        @media only screen and (min-width: 767px) {
            display: block;
        }

        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            height: 140px;
            width: 100%;
            background: linear-gradient(transparent, #F6F8FE);
        }

        img {
            max-width: 100%;
            width: 100%;
            max-height: 350px;
            object-fit: cover;
        }
    }

    &__logo {
        position: relative;
        z-index: 2;
        text-align: center;
        margin-bottom: 50px;
        display: none;

        @media only screen and (min-width: 767px) {
            display: block;
        }

        img {
            width: 150px;
            max-width: 100%;
        }
    }

    &__logo-mobile {
        position: relative;
        z-index: 2;
        text-align: center;
        margin-bottom: 30px;

        img {
            width: 150px;
            max-width: 100%;
        }
        @media only screen and (min-width: 767px) {
            display: none;
        }
    }

    &__title {
        color: #161617;
        text-align: center;
        font-family: Tenor Sans;
        font-size: 30px;
        font-weight: 400;
        line-height: 120%; /* 36px */
        text-transform: uppercase;
        margin:15px 0 10px 0;
        position: relative;
        z-index: 2;

        @media only screen and (min-width: 767px) {
            font-size: 50px;
        }

        span {
            color: #EF38B6;
        }
    }

    &__text {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
        text-align: center;
        margin: 5px 0 30px 0;

        @media only screen and (min-width: 767px) {
            font-size: 20px;
        }
    }

    &__button {
        border-radius: 25px;
        background: radial-gradient(114.71% 31.85% at 49.84% 100.00%,
                rgba(243, 108, 255, 0.40) 0%,
                rgba(246, 143, 255, 0.00) 100%),
        linear-gradient(357deg, #954DEC 0%, #7831E0 100%);
        color: #FFF;
        text-align: center;
        text-shadow: 0 1.6470588445663452px 3.2941176891326904px 0 rgba(0, 0, 0, 0.20);
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
        width: 280px;
        height: 75px;
        margin: 0 auto 15px auto;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        border: solid 3px #bc91f2;
        position: relative;
        text-decoration: none;
        box-sizing: border-box;
        margin-bottom: 40px;
        cursor: pointer;

        @media only screen and (min-width: 767px) {
            margin: 60px auto 80px auto;
        }

        &.pink {
            background: radial-gradient(114.71% 31.85% at 49.84% 100.00%,
                    rgba(254, 239, 255, 0.40) 0%,
                    rgba(246, 143, 255, 0.00) 100%),
            linear-gradient(357deg, #F860D6 0%, #EB29A9 100%);
            border: solid 3px #f9a3e2;
        }
    }
}
</style>
