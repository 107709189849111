<template>
    <div class="shedule">
        <div class="landing-content">
            <div class="shedule__content-block">
                <div class="shedule__content-title">31 марта в 11:00 мск</div>
                <div class="shedule__content-main">
                    <div class="shedule__content-left">
                        <div class="shedule__content-section">
                            <p>
                                Состоится живой вебинар-предобучение с Еленой
                                <b>«Распаковка своих замков»</b>.
                            </p><br>
                            <p class="nomargin">Вебинар будет доступен для тех, кто:</p>
                            <ul>
                                <li>оплатил обучение полностью</li>
                                <li>получил одобрение от банка по рассрочке</li>
                                <li>оставил предоплату</li>
                                <li>внес первый платёж по доверительной внутренней рассрочке</li>
                            </ul>
                        </div>

                        <div class="shedule__content-section">
                            <p>
                                После живого вебинара с Еленой откроется
                                доступ к модулю <b>«Предобучение»</b>
                                на обучающей платформе GetCourse.
                            </p>
                            <p>Этот модуль необходим, чтобы помочь
                                вам лучше подготовиться к предстоящей
                                трансформационной программе «Ключ к материнству».
                            </p>
                            <p class="nomargin">
                                <b>В этом модуле вы получите ссылки на чаты курса:</b>
                            </p>
                            <ul>
                                <li>Дневник курса и Общий чат (тарифы «Золотой ключик»
                                    и «Ключик с бриллиантами»))</li>
                                <li>Чат с кураторами (тариф «Ключик с бриллиантами»)</li>
                            </ul>
                            <p>В тарифе «Серебряный ключик» чаты не предусмотрены.</p>
                        </div>
                    </div>
                    <div class="shedule__content-right">
                        <div class="shedule__content-section">
                            <p>
                                <b>Ссылка на трансляцию придёт за час
                                    до старта вна вашу электронную почту.</b>
                            </p>
                            <p>
                                Все ученицы первого потока отмечали,
                                что не ожидали настолько глубокого и
                                трансформационного предобучения. Этот
                                бонусный урок поможет вам осознать, с
                                какими реальными проблемами вы пришли
                                на курс, и понять, над чем будете работать.
                            </p>
                        </div>

                        <div class="shedule__content-section">
                            <p>
                                Письмо с доступом к платформе будет
                                ждать вас на электронной почте,
                                которую вы указывали при оплате.
                            </p>
                            <p>
                                Письмо от отправителя <b>hello@elena.shop</b>
                            </p>

                            <p>
                                Тема письма: <b>Ура! Вы участник курса
                                «Ключ к материнству 2.0»</b>
                                {Начинаем 3 апреля}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shedule__content-block">
                <div class="shedule__content-title">3 апреля в 11.00 мск</div>
                <div class="shedule__content-main">
                    <div class="shedule__content-left">
                        <p>
                            Старт основного обучения. Откроется первый модуль
                            <b>«Выявляем источники проблем»</b> и первый урок
                            <b>«Разговор со своим телом».</b>
                        </p><br>
                        <p class="nomargin">Доступ к занятиям откроется для тех, кто:</p>
                        <ul>
                            <li>оплатил обучение полностью</li>
                            <li>получил одобрение от банка по рассрочке</li>
                            <li>внёс первый платеж по доверительной внутренней рассрочке</li>
                        </ul>
                    </div>
                    <div class="shedule__content-right">
                        <p>
                            Напоминания об открытии уроков будут публиковаться
                            по утрам на канале курса, а также приходить
                            на электронную почту, которую вы указывали при оплате.
                        </p>
                        <p>
                            <b>Письмо от отправителя hello@elena.shop</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="shedule__content-block">
                <div class="shedule__content-title blue">
                    Как будут проходить вебинары с Еленой?
                </div>
                <div class="shedule__simple-content">
                    <ul>
                        <li>на платформе Zoom по специальной ссылке;</li>
                        <li>2 раза в неделю по понедельникам и четвергам;</li>
                        <li>начало в 11.00 по МСК;</li>
                        <li>длительность: 2-3 часа каждый.</li>
                    </ul>
                </div>
            </div>
            <div class="shedule__content-block">
                <div class="shedule__content-title blue">
                    Как попасть на живой вебинар?
                </div>
                <div class="shedule__simple-content">
                    <ul>
                        <li>ссылка на вебинар будет находится
                            на платформе GetCourse</li>
                        <li>урок на GetCourse будет открываться
                            за 2 часа до встречи в Zoom</li>
                        <li>в уроке будут рекомендации, как лучше
                            подготовиться к вебинару</li>
                        <li>через 2 часа после завершения вебинара
                            к уроку добавится запись вебинара
                            и конспект</li>
                    </ul>
                </div>
            </div>
            <div class="shedule__content-block">
                <div class="shedule__content-title blue">
                    Из чего состоит урок на GetCourse?
                </div>
                <div class="shedule__simple-content">
                    <ul>
                        <li>живой вебинар, а так же запись вебинара
                            с тайм-кодами для удобного просмотра видео</li>
                        <li>конспект в текстовом варианте для
                            удобного изучения урока</li>
                        <li>интересное домашнее задание для
                            закрепления материала</li>
                        <li>дополнительные материалы в виде скриптов
                            для индивидуальных терапий, чек листов,
                            презентаций, медитаций, метафорических карт
                            и др.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Shedule',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.shedule {
    padding: 10px 0;

    &__content-main {
        display: flex;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        & > div {
            width: 50%;
            box-sizing: border-box;
            padding: 70px 30px 30px 30px;

            @media only screen and (max-width: 768px) {
                width: 100%;
                padding-top: 50px;
            }

            @media only screen and (max-width: 500px) {
                padding-top: 30px;
            }
        }
    }

    &__content-left {
        background: $white;
        border-radius: 20px 0 0 20px;

        @media only screen and (max-width: 768px) {
            border-radius: 20px 20px 0 0;
        }
    }

    &__content-right {
        background: #e4e8f5;
        border-radius: 0 20px 20px 0;

        @media only screen and (max-width: 768px) {
            border-radius:0 0 20px 20px;
        }
    }

    &__simple-content {
        width: 550px;
        margin: 0 auto;
        padding: 90px 0 60px 0;
        font-weight: 600;

        @media only screen and (max-width: 768px) {
           font-size: 16px;
           padding: 80px 30px 30px 30px;
            width: auto;
        }

        @media only screen and (max-width: 500px) {
            font-size: 14px;
        }
    }

    &__content-section {
        &:first-child {
            position: relative;
            height: 390px;

            @media only screen and (max-width: 768px) {
                height: auto;
                padding-bottom: 30px;
                margin-bottom: 30px;
            }

            @media only screen and (max-width: 500px) {
                padding-bottom: 20px;
            }

            &:after {
                position: absolute;
                left: -30px;
                width: calc(100% + 60px);
                height: 3px;
                background: #f4f6fc;
                content: '';
                opacity: 0.85;
                bottom: 55px;

                @media only screen and (max-width: 768px) {
                    bottom: 0;
                }
            }
        }
    }

    &__content-block {
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 20px;
        position: relative;
        margin: 50px 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 120px;
        background: $white;

        @media only screen and (max-width: 500px) {
            font-size: 14px;
            margin-bottom: 70px;
        }

        b {
            font-weight: 600;
        }

        p {
            margin: 20px 0;

            &.nomargin {
                margin: 0;
            }
        }

        ul {
            padding-left: 10px;
        }

        li {
            list-style: outside;
            margin: 2px 0 0 10px;
            position: relative;
        }
    }

    &__content-title {
        width: 365px;
        height: 76px;
        background: #292935;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 15px;
        line-height: 76px;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        margin: -40px auto 30px -182px;
        color: $white;
        position: absolute;
        left: 50%;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
            height: auto;
            margin-top: -24px;
            line-height: 24px;
            padding: 15px 15px;
            box-sizing: border-box;
        }

        @media only screen and (max-width: 500px) {
            width: 80%;
            left: 50%;
            margin-left: -40%;
        }

        &.blue {
            background: $main-blue;
            width: 700px;
            margin-left: -350px;

            @media only screen and (max-width: 768px) {
                width: 80%;
                left: 50%;
                margin-left: -40%;
                height: auto;
            }
        }
    }
}
</style>
