<template>
    <div class="invite-banner">
        <div class="invite-banner__image">
            <img src="/images/landing/insta-page-banner-5.jpg">
        </div>

        <div class="invite-banner__content">
            <div class="landing-title-group">
                <div class="landing-title-group__small">
                    Консультация психолога
                </div>
                <div class="landing-title-group__mid">
                    Личная консультация психолога
                </div>
            </div>

            <div class="insta-page__form">
                <router-link to="consultation" class="es-button">
                    Записаться
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ConsultationBanner',
  components: {},
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.invite-banner {
    position: relative;
    margin: 24px 0;
    color: $white;
    overflow: hidden;
    border-radius: 10px;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        bottom: 0;
        left: 0;
        height: 100%;
        background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
        z-index: 1;
    }

    .landing-title-group__mid {
        font-size: 24px;
        line-height: 30px;
        text-align: left;

        @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .landing-title-group__small {
        text-align: left;
        font-size: 14px;
        margin-bottom: 10px;

        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    &__content {
        padding: 1px 24px 14px 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        z-index: 2;

        .es-button {
            max-width: 250px;

            @media only screen and (max-width: 767px) {
                max-width: none;
            }
        }
    }

    &__image {
        margin: 0;
        overflow: hidden;
        width: 100%;
        max-height: 400px;
        flex-shrink: 0;
        background: $white;

        @media only screen and (max-width: 767px) {
            margin: 0;
            max-height: 250px;
            width: auto;
        }

        img {
            object-fit: cover;
            display: block;
            width: 100%;

            &.isMobile {
                display: none;

                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }

            &.isDesktop {
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
}

</style>
