<template>
    <div class="base-marathon">
        <template v-if="!isThanksPage">
            <Header></Header>
            <YouWillKnow></YouWillKnow>
            <Program></Program>
            <Curators></Curators>
            <RegForm></RegForm>
            <Footer></Footer>
        </template>
        <template v-else>
            <ThanksPage></ThanksPage>
        </template>
    </div>
</template>

<script>
import Header from '@/components/secondary-pages/marathon/Header.vue';
import YouWillKnow from '@/components/secondary-pages/marathon/YouWillKnow.vue';
import Program from '@/components/secondary-pages/marathon/Program.vue';
import Curators from '@/components/secondary-pages/marathon/Curators.vue';
import RegForm from '@/components/secondary-pages/marathon/RegForm.vue';
import ThanksPage from '@/components/secondary-pages/marathon/ThanksPage.vue';
import Footer from '@/components/secondary-pages/marathon/Footer.vue';

export default {
  name: 'BaseMarathon',
  components: {
    Header,
    YouWillKnow,
    Program,
    Curators,
    RegForm,
    Footer,
    ThanksPage,
  },
  data() {
    return {
      isThanksPage: this.$route.name === 'MarathonThanks',
      isMarathonHealing: this.$route.name === 'MarathonHealing',
    };
  },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.base-marathon {
    font-family: 'Montserrat';
}
</style>
