<template>
    <div class="our-team">
        <div class="our-team__content">
            <div class="landing-title-group">
                <div id="teamSectionScrollMarker"
                     class="landing-title-group__hidden-marker"
                ></div>
                <div class="landing-title-group__mid">Наша команда</div>
            </div>

            <div class="our-team__list">
                <div class="our-team__item" @click="scrollToBlock('elenaScrollMarker')">
                    <div class="our-team__item-image">
                        <img src="/images/consultant-page/team-elena.jpg"/>
                    </div>
                    <div class="our-team__item-descr">
                        <div class="our-team__item-title">Елена Сягло</div>
                        <div class="our-team__item-text">
                            перинатальный психолог, энерготерапевт,<br>
                            специалист по психосоматике.
                        </div>
                    </div>
                </div>

                <div class="our-team__item" @click="scrollToBlock('innaScrollMarker')">
                    <div class="our-team__item-image">
                        <img src="/images/consultant-page/team-inna.jpg"/>
                    </div>
                    <div class="our-team__item-descr">
                        <div class="our-team__item-title">Инна Дисковец</div>
                        <div class="our-team__item-text">
                            перинатальный психолог
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ConsultantLandingTeam',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    scrollToBlock(id) {
      const hiddenElement = document.querySelector(`#${id}`);
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/landing";

.our-team {
    padding: 20px 24px;
    background: $white;

    .landing-title-group .landing-title-group__mid {
        color: $main-blue;
    }

    &__list {
        margin-top: 45px;

        @media only screen and (min-width: 767px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-image {
        width: 106px;
        height: 106px;
        border: solid #9CCBFF 3px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        margin-right: 20px;

        img {
            object-fit: cover;
            width: 100%;
        }
    }

    &__item-descr {
        padding-top: 15px;
    }

    &__item-title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
    }

    &__item-text {
        font-size: 12px;
        line-height: 15px;
        color: #414141;
    }
}
</style>
