<template>
  <div class="landing-page">
    <BaseGuidebook></BaseGuidebook>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseGuidebook from '@/components/secondary-pages/BaseGuidebook3.vue';

export default {
  name: 'GuidebookPage',
  components: {
    BaseGuidebook,
  },
};
</script>
