<template>
    <div class="wlm-faq" :class="{'bright-blue': isLandingRegistration}">
        <div class="landing-title-group">
            <div id="faqSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                <template v-if="isWebinarBuyingPage">ПОТОК ИСЦЕЛЕНИЯ</template>
                <template v-else-if="isSingleMeditation">МЕДИТАЦИИ ДЛЯ ЖЕНЩИН</template>
                <template v-else-if="isTelegramRegistration"></template>
                <template v-else>МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ</template>
            </div>
            <div class="landing-title-group__mid">Вопросы-ответы</div>
        </div>

        <div class="wlm-faq__list">
            <div class="faq-list">
                <div class="faq-item"
                     v-for="(item, index) in filteredList"
                     :key="index"
                     :class="{'isActive': item.isActive}"
                >
                    <div class="faq-title" @click="toggleFAQ(index)">
                        {{item.title}}
                        <div class="faq-arrow">
                            <svg width="10" height="7"
                                 viewBox="0 0 10 7"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                        d="M5.59996 6.14997C5.48286 6.26722 5.324 6.33316
5.15829 6.33331H4.84163C4.67628 6.33139 4.51806 6.26571 4.39996 6.14997L0.124958
1.86664C0.046078 1.7884 0.00170898 1.68191 0.00170898 1.57081C0.00170898 1.45971
0.046078 1.35321 0.124958 1.27497L0.716625 0.683308C0.793428 0.604921 0.89855
0.560748 1.00829 0.560748C1.11803 0.560748 1.22316 0.604921 1.29996
0.683308L4.99996 4.39164L8.69996 0.683308C8.7782 0.604428 8.88469 0.560059
8.99579 0.560059C9.10689 0.560059 9.21339 0.604428 9.29163 0.683308L9.87496
1.27497C9.95384 1.35321 9.99821 1.45971 9.99821 1.57081C9.99821 1.68191
9.95384 1.7884 9.87496 1.86664L5.59996 6.14997Z"
                                        fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="faq-text" v-html="item.text"></div>
                </div>
            </div>

            <div class="wlm-faq__actions" v-if="!isNoStartButton && !isWebinarRegistration">
                <button class="es-button" @click="scrollToPayment()">Хочу в поток</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'WelcomeLandingFaq',
  data() {
    return {
      list: this.$store.state.general.faqList,
      listRegistration: this.$store.state.general.faqListRegistration,
    };
  },
  props: {
    isNoStartButton: String,
  },
  computed: {
    isWebinarRegistration() {
      return this.$route.name === 'mnj-webinar';
    },
    isTelegramRegistration() {
      return this.$route.name === 'TelegramMeeting';
    },
    filteredList() {
      if (this.isWebinarRegistration) {
        return this.listRegistration;
      }

      return this.list.filter((item) => {
        let returnData = true;
        const hiddenList = item.isPageHidden.split(',');

        hiddenList.forEach((hiddenItem) => {
          if (hiddenItem === this.$route.name) {
            returnData = false;
          }
        });

        if (item.isLandingVisible !== 'always') {
          returnData = false;
        }

        if (item.isPageVisible !== 'all' && item.isPageVisible !== this.$route.name) {
          returnData = false;
        }

        return returnData;
      });
    },
    isWebinarBuyingPage() {
      return this.$route.name === 'WebinarLanding';
    },
    isSingleMeditation() {
      return this.$route.name === 'Finalprice';
    },
    isNewMeditation() {
      return this.$route.name === 'New';
    },
    isLandingRegistration() {
      return this.$route.name === 'mnj-webinar';
    },
  },
  methods: {
    scrollToPayment() {
      this.$store.dispatch('scrollToLandingPayment');
    },
    toggleFAQ(index) {
      this.filteredList.forEach((listItem, listIndex) => {
        if (listIndex === index && !listItem.isActive) {
          this.filteredList[listIndex].isActive = true;
        } else {
          this.filteredList[listIndex].isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/faq";
 @import "../../../assets/scss/common/forms";

.webinar-instr {
    &__button {
        height: 50px;
        width: 270px;
        font-size: 16px;
        margin: 30px auto 35px auto;

        @media only screen and (max-width: 767px) {
            margin: 20px auto 26px -25px;
            width: calc(100vw - 64px);
        }
    }

    &__descr {
        font-size: 14px;
        line-height: 21px;
        margin: 0 auto;

        img {
            max-width: 100%;
            margin: 15px 0;
        }

        .link {
            color: $main-blue;
            cursor: pointer;
            text-decoration: none;
        }

        p {
            font-size: 16px;
            line-height: 20px;

            @media only screen and (max-width: 767px) {
                font-size: 14px;
            }
        }

        ol {
            margin: 30px 0 0 0;

            @media only screen and (max-width: 767px) {
                margin: 40px 0 0 0;
            }

            li {
                font-size: 16px;
                line-height: 26px;
                position: relative;
                padding-left: 35px;

                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 21px;
                    padding-left: 25px;

                    @media only screen and (max-width: 767px) {
                        .blue {
                            font-size: 12px;
                        }
                    }

                }

                .li-count {
                    font-weight: 600;
                    color: $main-blue;
                    position: absolute;
                    left: 2px;
                    top: 0;
                    font-size: 22px;

                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        left: 0;
                        top: 4px;
                    }
                }

            }
        }
    }
}

.wlm-faq {
    padding: 50px 24px;
    background: $light-blue;

    &.bright-blue {
        background: #edf1ff;
    }

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 20px 24px;
    }

    &__actions {
        margin: 40px 0 0 0;

        button {
            width: 200px;
            margin: auto;

            @media only screen and (max-width: 400px) {
                width: 100%;
            }
        }
    }
}
</style>
