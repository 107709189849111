<template>
  <div class="cards-page">
    <BaseCards></BaseCards>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseCards from '@/components/cards/BaseCards.vue';

export default {
  name: 'CardsPage',
  components: {
    BaseCards,
  },
};
</script>
