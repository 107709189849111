<template>
    <div class="users-tg">
      <div class="users-tg__title">Спсиок людей</div>
        <table class="users-tg__list">
            <tr>
                <th>№</th>
                <th>Номер телефона</th>
                <th>Логин TG</th>
            </tr>
            <tr v-for="(item, index) in usersTG"
                :key="index"
            >
                <td>{{index}}</td>
                <td>{{showData(item)[0]}}</td>
                <td>{{showData(item)[1]}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'usersTG',
  components: {
  },
  data() {
    return {
      usersTG: '',
    };
  },
  computed: {
    adminToken() {
      return this.$store.state.user.adminToken;
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    showData(usersData) {
      const data = usersData.replace('{', '').replace('}', '').split(',');
      return data;
    },
    getUsers() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/adminGetPracticeUsers.php`, {
          adminToken: this.adminToken,
        })
        .then((response) => {
          if (response.data.success) {
            this.usersTG = response.data.result.users.split(', ');
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.users-tg {
  margin: 40px 0 0;
  padding: 0 20px;

  &__title {
    margin-bottom: 15px;
    font-weight: 600;
  }

  &__list {
      border: solid 1px $black;
      width: 100%;

      th {
          font-weight: 600;
      }

      td, th {
          border: solid 1px $black;
          padding: 8px;
      }
  }
}
</style>
