<template>
  <div class="free-webinar-transit">
    <div class="free-webinar-transit__content">

      <div class="free-webinar-transit__title">
        Вас автоматически перенаправит
      </div>

      <div class="free-webinar-transit__text">
        Если автоматический переход не произошел, нажмите кнопку перейти
      </div>

      <div class="free-webinar-transit__action">
        <a :href="link" target="_blank" class="es-button">Перейти</a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Sammary',
  data() {
    return {
      morningLink: 'https://start.bizon365.ru/room/93064/kowog6sx20',
      eveningLink: 'https://start.bizon365.ru/room/93064/koabmhuss',
    };
  },
  methods: {
    sentPixelEvent() {
      const currentDate = new Date();
      const hours = currentDate.getUTCHours();
      const minutes = currentDate.getMinutes();
      const morningStart = (hours === 11 && minutes >= 55) || hours >= 12;
      const eveningStart = (hours === 15 && minutes >= 55) || hours >= 16;

      if (morningStart && hours < 15 && this.isMorningPage) {
        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'PageView');
        }
      }

      if (eveningStart && hours < 19 && this.isEveningPage) {
        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'PageView');
        }
      }

      window.location.href = this.link;
    },
  },
  computed: {
    isMorningPage() {
      return this.$route.name === 'free-webinar-transit-page-15';
    },
    isEveningPage() {
      return this.$route.name === 'free-webinar-transit-page-19';
    },
    link() {
      let link = '';

      if (this.isMorningPage) {
        link = this.morningLink;
      }

      if (this.isEveningPage) {
        link = this.eveningLink;
      }

      return link;
    },
  },
  mounted() {
    this.sentPixelEvent();
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/common/forms";
  @import "../assets/scss/common/variables";

  .free-webinar-transit{
    padding: 60px 24px 0 24px;
    background: $white;
    min-height: 100vh;
    box-sizing: border-box;
    text-align: center;

    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 15px;
    }

    &__text {
      font-size: 14px;
      margin-bottom: 40px;
      line-height: 21px;
    }

    &__descr {
      font-size: 18px;
      line-height: 28px;
      margin: 0 auto;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    &__action {
      padding: 20px 0;
      margin: 0 auto;
      max-width: 270px;

      .es-button {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
        font-family: $titleFont;
      }
    }
  }
</style>
