<template>
    <div class="admin-settings">
        <label class="admin-settings__item">
            <span class="text">Баннеры</span>
            <input type="checkbox"
                   required
                   v-model="isBannersActive"
                   @change="saveBannerState()"
            >
        </label>

        <label class="admin-settings__item">
            <span class="text">Активность мессенджеров</span>
            <input type="checkbox"
                   required
                   v-model="isMessengersActive"
                   @change="saveMessengerState()"
            >
        </label>

        <label class="admin-settings__item">
            <span class="text">Куки на сутки для лендинга (welcomeWebinar)</span>
            <input type="date"
                   required
                   v-model="webinarSaleTimeCookieDate"
                   @change="saveWebinarSaleTimeCookieDate()"
            >
        </label>

        <label class="admin-settings__item">
            <span class="text">Куки на 3 дня для лендинга (specialprice)</span>
            <input type="date"
                   required
                   v-model="webinarSaleTime3DaysCookieDate"
                   @change="saveWebinarSaleTime3DaysCookieDate()"
            >
        </label>

        <label class="admin-settings__item">
            <span class="text">Куки страницы модулей (supermodule)</span>
            <input type="date"
                   required
                   v-model="modulesPageCookieDate"
                   @change="saveModuleCookie()"
            >
        </label>

        <label class="admin-settings__item">
            <span class="text">Куки страницы одиночных оплат (finalprice)</span>
            <input type="date"
                   required
                   v-model="singlePageCookieDate"
                   @change="saveSingleCookie()"
            >
        </label>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AdminSettings',
  data() {
    return {
      isBannersActive: this.$store.state.general.isBannersActive,
      isMessengersActive: this.$store.state.general.isMessengersActive,
      modulesPageCookieDate: this.$store.state.general.modulesPageCookieDate,
      webinarSaleTimeCookieDate: this.$store.state.general.webinarSaleTimeCookieDate,
      webinarSaleTime3DaysCookieDate: this.$store.state.general.webinarSaleTime3DaysCookieDate,
      singlePageCookieDate: this.$store.state.general.singlePageCookieDate,
    };
  },
  computed: {
    adminToken() {
      return this.$store.state.user.adminToken;
    },
  },
  methods: {
    saveWebinarSaleTimeCookieDate() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/setWebinarSaleTimeCookieDate.php`, {
          date: this.webinarSaleTimeCookieDate,
        })
        .then((response) => {
          if (response.data.success) {
            const { modulesPageCookieDate } = response.data;
            this.$store.commit('setWebinarSaleTimeCookieDate', modulesPageCookieDate);
            this.$store.commit('showGlobalMessage', 'Данные сохранены');
          }
        });
    },
    saveWebinarSaleTime3DaysCookieDate() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/setWebinarSaleTime3DaysCookieDate.php`, {
          date: this.webinarSaleTime3DaysCookieDate,
        })
        .then((response) => {
          if (response.data.success) {
            const { webinarSaleTime3DaysCookieDate } = response.data;
            this.$store.commit('setWebinarSaleTime3DaysCookieDate', webinarSaleTime3DaysCookieDate);
            this.$store.commit('showGlobalMessage', 'Данные сохранены');
          }
        });
    },
    saveModuleCookie() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/setModulePageCookieDate.php`, {
          date: this.modulesPageCookieDate,
        })
        .then((response) => {
          if (response.data.success) {
            const { modulesPageCookieDate } = response.data;
            this.$store.commit('setModulesPageCookieDate', modulesPageCookieDate);
            this.$store.commit('showGlobalMessage', 'Данные сохранены');
          }
        });
    },
    saveSingleCookie() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/setSingleCookieDate.php`, {
          date: this.singlePageCookieDate,
        })
        .then((response) => {
          if (response.data.success) {
            const { singlePageCookieDate } = response.data;
            this.$store.commit('setSinglePageCookieDate', singlePageCookieDate);
            this.$store.commit('showGlobalMessage', 'Данные сохранены');
          }
        });
    },
    saveMessengerState() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/setMessengersState.php`, {
          state: this.isMessengersActive ? 1 : 0,
        })
        .then((response) => {
          if (response.data.success) {
            const { isMessengersActive } = response.data;
            this.$store.commit('setMessengersState', parseInt(isMessengersActive, 10));
            this.$store.commit('showGlobalMessage', 'Данные сохранены');
          }
        });
    },
    saveBannerState() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/v2/api/setBannerState.php`, {
          state: this.isBannersActive ? 1 : 0,
        })
        .then((response) => {
          if (response.data.success) {
            const { isBannersActive } = response.data;
            this.$store.commit('setBannerActiveState', parseInt(isBannersActive, 10));
            this.$store.commit('showGlobalMessage', 'Данные сохранены');
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.admin-settings {
    margin: 20px 0;
    padding: 0 20px;

    input[type=checkbox] {
        width: 20px;
        height: 20px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        max-width: 400px;
        margin: auto;
        padding: 15px;
        border-bottom: solid 1px $light-blue;
        box-sizing: border-box;

        span.text {
            margin-right: 10px;
        }

        input {
            flex-shrink: 0;
        }

        &:nth-child(odd) {
            background: $light-blue;
        }
    }
}
</style>
