<template>
    <div class="mh-header">
        <div class="mh-content">
            <div class="mh-header_left-side">
                <div class="mh-header_title">
                    Регистрация<br>
                    завершена <br>
                    на 80%
                </div>
                <div class="mh-header_sub-title">
                    Для завершения регистрации<br>
                    перейдите в телеграм бот по кнопке
                </div>
                <a href="https://course.elena.shop/tlgrm" class="mh-button">
                    ПЕРЕЙТИ
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Thanks',
  data() {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-header {
    background: linear-gradient(180deg, #742DDB 0%, #A35BF4 100%);
    padding: 115px 0;
    color: $white;

    @media only screen and (max-width: 1200px) {
        padding: 80px 0 20px 0;
    }

    @media only screen and (max-width: 768px) {
        padding: 50px 0 20px 0;
    }

    &:after {
        background-image:  url("/images/marathon/mh-thanks-line.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 500px;
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        height: 120px;
        content: '';
        z-index: 1;

        @media only screen and (min-width: 768px) {
            background-size: 800px;
            top: 90px;
        }
    }

    .mh-content {
        max-width: 1200px;
        margin: auto;
        position: relative;
        z-index: 2;
        min-height: 100vh;

        @media only screen and (max-width: 1200px) {
            max-width: 670px;
            background-size: 370px;
            background-position: 100% 100%;
            padding-bottom: 60px;
        }

        @media only screen and (max-width: 768px) {
            max-width: 360px;
            background-size: 220px;
            background-position: 100% 350px;
            min-height: 100vh;
            padding-bottom: 10px;
        }
    }

    &_left-side {
        max-width: 720px;

        a {
            display: block;
            line-height: 77px;
            border-radius: 20px;
            background: radial-gradient(
                            76.16% 76.16% at 31.97% 19.67%,
                            rgba(255, 255, 255, 0.70) 0%,
                            rgba(255, 255, 255, 0.00) 69.79%,
                            rgba(255, 255, 255, 0.00) 100%),
            #E057EC;
            box-shadow: 4px 20px 62px 0 rgba(0, 0, 0, 0.30);

            color: #FFF;
            text-align: center;
            text-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.30);
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            text-decoration: none;
            width: 220px;
            height: 77px;
            margin: auto;
        }
    }

    &_sub-title {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 18.2px */
        margin: 50px 0 40px 0;

        @media only screen and (max-width: 768px) {
            text-align: center;
        }
    }

    &_title {
        color: #e3d5f8;
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        line-height: 130%; /* 30.8px */
        text-transform: uppercase;
        opacity: 1;
        margin-top: 150px;
        position: relative;
        width: 320px;
        margin: 150px auto 0 auto;

        &:before {
            background: url("/images/marathon/smile-thanks-top.png");
            width: 129px;
            height: 136px;
            position: absolute;
            left: 0;
            top: -150px;
            content: '';
            background-size: contain;
        }

        &:after {
            background: url("/images/marathon/smile-thanks-bottom.png");
            width: 91px;
            height: 107px;
            position: absolute;
            right: 20px;
            bottom: -50px;
            content: '';
            background-size: contain;
        }

        @media only screen and (max-width: 768px) {
            font-size: 30px;
            text-align: center;
        }
    }
}
</style>
