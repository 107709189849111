var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-card__item",class:{'isPayed': _vm.meditation.isPayed, 'isNotPayedHidden': _vm.hideNotPayed}},[(_vm.isCabinet && _vm.meditation.isPayed)?_c('div',{staticClass:"module-card__item-title link",on:{"click":function($event){$event.stopPropagation();return _vm.showPreview(_vm.meditation.id)}}},[_vm._v(" "+_vm._s(_vm.meditation.name)+" ")]):_c('div',{staticClass:"module-card__item-title"},[_vm._v(" "+_vm._s(_vm.meditation.name)+" ")]),_c('div',{staticClass:"module-card__item-time"},[(_vm.isCabinet)?_c('div',{staticClass:"module-card__item-time"},[_c('div',[_vm._v(_vm._s(_vm.showType(_vm.meditation.type)))]),_c('div',[_vm._v("Длительность: "+_vm._s(_vm.meditation.discrTime))])]):_c('div',{staticClass:"module-card__item-time"},[(_vm.meditation.isPayed)?[_vm._v(" Медитация куплена ")]:[_vm._v(" Длительность: "+_vm._s(_vm.meditation.discrTime)+" ")]],2)]),_c('div',{staticClass:"module-card__item-reviews"},[(_vm.meditation.isPayed && !_vm.isCabinet)?_c('router-link',{attrs:{"to":"/my"}},[_vm._v(" Посмотреть в ЛК ")]):_vm._e(),(!_vm.meditation.isPayed && _vm.meditation.reviews.length)?_c('span',{on:{"click":function($event){return _vm.showReviewsDialog(
                                      _vm.meditation.groupId,
                                      _vm.meditation.url,
                                      _vm.meditation.id,
                                      _vm.meditation.discrPrice
                                  )}}},[_vm._v(" Отзывы ("+_vm._s(_vm.meditation.reviews.length)+") ")]):_vm._e()],1),(!_vm.meditation.isPayed)?_c('div',{staticClass:"module-card__price-cover"},[(_vm.isGetCourse)?_c('div',{staticClass:"module-card__price"},[_c('div',{staticClass:"module-card__price-future"},[_vm._v(" "+_vm._s(_vm.priceWithSale(_vm.meditation.getCoursePrice, 0))+" ₽ ")]),(_vm.meditation.getCoursePrice != _vm.priceWithSale(_vm.meditation.getCoursePrice, 0))?_c('div',{staticClass:"module-card__price-base"},[_vm._v(" "+_vm._s(Math.round(_vm.meditation.getCoursePrice))+" ₽ ")]):_vm._e()]):_c('div',{staticClass:"module-card__price"},[_c('div',{staticClass:"module-card__price-future"},[_vm._v(" "+_vm._s(_vm.priceWithSale(_vm.meditation.discrPrice, 1))+" $ ")]),_c('div',{staticClass:"module-card__price-base"},[_vm._v(" "+_vm._s(Math.round(_vm.meditation.discrPrice))+" $ ")])]),_c('div',{staticClass:"module-card__actions"},[_c('button',{staticClass:"es-button es-button--rounded play es-button--bordered white",on:{"click":function($event){$event.stopPropagation();return _vm.showPreview(_vm.meditation.id)}}}),_c('button',{staticClass:"es-button es-button--rounded cart",attrs:{"disabled":_vm.isInCart(_vm.meditation.id)},on:{"click":function($event){$event.stopPropagation();return _vm.addToCart(
                                              _vm.meditation.id,
                                              _vm.meditation.discrPrice
                                          )}}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }