<template>
    <div class="welcome-about">
        <div class="welcome-about__descr" v-if="isFourth">
            <div class="welcome-about__video">
                <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/BI4MeHaY588?modestbranding=1&rel=0"
                        frameborder="0"
                        allowfullscreen
                        modestbranding='0'
                ></iframe>
            </div>
        </div>
        <div class="welcome-about__header" v-else>
            <div class="welcome-about__descr" v-if="isSecond">
                <div class="welcome-about__descr-title">
                    <span class="blue">Во время потока</span>
                    ваш мир
                    перевернется с ног на
                    голову, пространство
                    откроет для вас новые
                    возможности, о которых вы
                    раньше не подозревали.
                    <br>
                    <span class="welcome-about__dotes">.....</span>
                </div>
                <div class="welcome-about__descr-text">
                    Поток исцеления - это уникальный проект!
                    Это не просто терапевтический эфир,
                    это волшебное пространство,
                    где происходят массовые метаморфозы сознания.
                </div>
            </div>
            <div class="welcome-about__descr" v-else-if="isThird">
                <div class="welcome-about__descr-title">
                    Вы купили участие! Информация для входа выслана вам на почту.
                    <br><br>

                    Переходите в закрытый чат для участников потока.
                    Когда мы переходим на другой уровень,
                    очень важно иметь окружение, которое нас поддержит
                    <br><br>
                    <a
                            class="es-button"
                            href="https://t.me/+nkZt__6YkCsxOTFi"
                            target="_blank"
                    >
                        Перейти
                    </a>
                </div>
            </div>

            <div class="welcome-about__descr" v-else-if="isFifth">
                <div class="welcome-about__descr-title">
                    <p>На занятии вас ждёт 4 групповых
                        медитативных упражнения для исцеления душевных травм.

                    <p>1. Упражнение «Освобождение
                    от ограничений, полученных в детстве»</p>

                    <p>2. Упражнение «Подключение
                    к программам, которые переведут
                    вас на линию жизни с более счастливой судьбой»</p>

                    <p>3. Упражнение «Исцеление
                    скрытых душевных ран полученных в прошлом»</p>

                    <p> 4. Упражнение
                    «Подключение к безграничному источнику любви»</p>

                    <p>Эта действенная
                    практика поможет вам
                    получать ресурс в течение
                    вашей жизни тогда, когда это будет необходимо.</p>

                    <p>Занятие состоится
                    9 апреля в 11.00 по Москве. Длительность: 1,5 часа.</p>

                    <p>Запись будет.</p>
                </div>
            </div>

            <div class="welcome-about__descr" v-else-if="isSix">
                <div class="welcome-about__descr-text">
                    3 реальных запроса, 3 уникальные истории,
                    3 случайные участника на глазах всего
                    нашего потока трансформируют свои страхи,
                    обиды и другие негативные чувства в свою
                    уникальную силу, освободятся от зажимов,
                    расчистят свое сознание от ненужных мыслей,
                    почувствуют свободу.
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'LandingWebinarDescr',
  data() {
    return {
    };
  },
  props: {
    isSecond: String,
    isThird: String,
    isFourth: String,
    isFifth: String,
    isSix: String,
    isSeventh: String,
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";
 @import "../../../assets/scss/common/forms";

 .isDesktop {
     @media only screen and (max-width: 767px) {
         display: none;
     }
 }

 .isMobile {
     display: none;

     @media only screen and (max-width: 767px) {
         display: block;
     }
 }

 .welcome-about {
    padding: 50px 24px;
     background: $white;

     &.largeTopPadding {
         padding-top: 80px;
     }

     &__body {
         position: relative;
     }

     &__video {
         width: 100%;
         text-align: center;
         position: relative;

         &:before {
             display: block;
             content: '';
             width: 100%;
             height: 60px;
             position: absolute;
             left: 0;
             top: 0;
         }
     }

     &__dotes {
         letter-spacing: 3px;
         font-size: 30px;
         margin-bottom: 20px;
         display: block;
     }

    @media only screen and (max-width: 767px) {
        padding: 30px 24px 20px 24px;
    }

    &__our-audience {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 20px 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
            margin-bottom: 37px;
        }
    }

    &__descr-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        p {
            margin: 15px 0;
        }

        .es-button {
            max-width: 320px;
            margin: auto;
        }

        @media only screen and (max-width: 700px) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 2px;

            .es-button {
                max-width: none;
            }
        }

        .blue {
            color: $main-blue;
        }
    }

    &__descr-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;

        @media only screen and (max-width: 700px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

     &__cabinet-link {
         margin: 20px 0 0 0;

         @media only screen and (max-width: 700px) {
             margin: 40px 0 0 0;
         }

         a {
             color: $main-blue;
         }
     }

}
</style>
