<template>
  <div class="landing-page">
    <BaseCourseLanding></BaseCourseLanding>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseCourseLanding from '@/components/secondary-pages/BaseCourseLanding20.vue';

export default {
  name: 'CourseLandingPage',
  components: {
    BaseCourseLanding,
  },
};
</script>
