<template>
    <div class="select-time">
        <div class="landing-title-group">
            <div id="registrationSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">РЕГИСТРАЦИЯ НА ВЕБИНАР</div>
            <div class="landing-title-group__mid">Выберите время<br> просмотра</div>
        </div>

        <div class="select-time__list">
            <div class="select-time__item-cover">
                <div class="select-time__item">
                    <div class="select-time__item-time">
                        <div class="select-time__item-icon">
                            <img src="/images/registration-webinar/time-icon.svg">
                        </div>
                        <div class="select-time__item-time-content">
                            <div class="select-time__item-time-title">15:00</div>
                            <div class="select-time__item-time-descr">Московское время</div>
                        </div>
                    </div>
                    <div class="select-time__item-places-left">Осталось 17 мест</div>
                    <button class="es-button white" @click="showRegForm('1500')">
                        Зарегистрироваться
                    </button>
                </div>
            </div>

            <div class="select-time__item-cover">
                <div class="select-time__item">
                    <div class="select-time__item-time">
                        <div class="select-time__item-icon">
                            <img src="/images/registration-webinar/time-icon.svg">
                        </div>
                        <div class="select-time__item-time-content">
                            <div class="select-time__item-time-title">19:00</div>
                            <div class="select-time__item-time-descr">Московское время</div>
                        </div>
                    </div>
                    <div class="select-time__item-places-left">Осталось 22 места</div>
                    <button class="es-button white" @click="showRegForm('1900')">
                        Зарегистрироваться
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SelectTime',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
    showRegForm(time) {
      this.$store.commit('setWebinarRegType', time);
      this.$store.commit('setCurrentTemplate', 'webinar-registration');
      this.$store.commit('showDialog');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.select-time {
    padding: 25px 24px;
    background: $white;

    &__list {
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__item-cover {
        border: solid 1px $main-blue-bright;
        padding: 6px;
        border-radius: 10px;
        width: 270px;
        margin: 15px 10px;

        @media only screen and (max-width: 767px) {
            width: 100%;
            margin: 15px 0;
        }
    }

    &__item {
        background: $main-blue-bright;
        box-shadow: 0 0 14px #105CFF;
        border-radius: 10px;
        padding: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item-places-left {
        background: #CC4FC7;
        border-radius: 20px;
        margin: 10px 0;
        font-size: 14px;
        font-weight: 600;
        padding: 0 15px;
        line-height: 24px;
        color: $white;
        display: inline-block;
    }

    &__item-icon {
        position: absolute;
        left: -35px;
        top: 15px;
    }

    &__item-time {
        position: relative;
        color: $white;
    }

    &__item-time-title {
        font-size: 48px;
        font-weight: 600;
    }

    &__item-time-descr {
        font-size: 12px;
        font-weight: 500;
    }
}
</style>
