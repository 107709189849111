<template>
    <div class="base-cabinet">
        <Header isNotFixed="true"></Header>
        <BFBanner></BFBanner>
        <CabinetHeader></CabinetHeader>
        <AvailableList></AvailableList>
        <Recomendations v-if="totalAmountForNotPayed"></Recomendations>
        <div class="base-cabinet__banner">
            <ConsultationBanner></ConsultationBanner>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import ConsultationBanner from '@/components/general/ConsultationBanner.vue';
import AvailableList from '@/components/cabinet/AvailableList.vue';
import CabinetHeader from '@/components/cabinet/CabinetHeader.vue';
import Recomendations from '@/components/cabinet/Recomendations.vue';
import BFBanner from '@/components/general/BFBanner.vue';

export default {
  name: 'BaseCabinet',
  components: {
    Header,
    Footer,
    CabinetHeader,
    AvailableList,
    Recomendations,
    BFBanner,
    ConsultationBanner,
  },
  data() {
    return {
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    meditationsInfoForSingle() {
      return this.$store.getters.getMeditationsInfoForSingle;
    },
    totalAmountForNotPayed() {
      return this.meditationsInfoForSingle.priceForNoPayed;
    },
  },
  mounted() {
    this.$store.commit('hideBurger');

    if (this.getUserMail === '') {
      this.$router.push({ name: 'Auth' });
    }
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.base-cabinet {
    background: $white;

    &__title {
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
    }

    &__banner {
        padding: 0 13px;
    }
}
</style>
