<template>
    <div class="course-format">
        <div class="course-landing-title">
            <div class="hiddenLabel" id="chooseFormatTitle"></div>
            Выберите удобный<br>
            формат участия
        </div>

        <CourseFormatItem20></CourseFormatItem20>
    </div>
</template>

<script>
import CourseFormatItem20 from '@/components/secondary-pages/course-landing/CourseFormatItem20.vue';

export default {
  name: 'CourseFormat',
  components: {
    CourseFormatItem20,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-format {
    padding: 5px 23px 20px 23px;
    background: $landingLightestGray;
}

.course-landing-title {
    @media only screen and (min-width: 767px) {
        br {
            display: none;
        }
    }
}

.hiddenLabel {
    position: absolute;
    top: -25px;
    height: 2px;
    left: 0;
    width: 100%;
}
</style>
