<template>
    <div class="wlm-structure">
        <div class="landing-title-group">
            <div id="structureSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ
            </div>
            <div class="landing-title-group__mid">Как устроен курс</div>
        </div>
        <div class="landing-title-medium">
            Курс состоит из трех модулей.
            В каждом модуле по 3 основных урока и бонусные уроки.
        </div>

        <div class="wlm-structure__general-info">
            <div class="wlm-structure__info-item">
                <div class="wlm-structure__info-icon"></div>
                Уроки проходят в формате медитаций и вебинаров.
            </div>
            <div class="wlm-structure__info-item">
                <div class="wlm-structure__info-icon"></div>
                Уроки расположены в такой очередности,
                чтобы ваша трансформация проходила максимально эффективно.
            </div>
        </div>

        <div class="wlm-structure__courses-list">
            <div class="wlm-structure__course-item">
                <div id="structureNoFearScrollMarker"
                     class="landing-title-group__hidden-marker"
                ></div>
                <div class="wlm-structure__course-image">
                    <div class="wlm-structure__title isMobile">
                        <span class="wlm-structure__title-counter yellow">1</span>
                        Модуль<br> “Я ничего не боюсь”
                    </div>
                    <img src="/images/landing/course-1.png">
                    <button class="es-button yellow w-icon"
                            @click="showPlayerPreview(
                              'ya-nichego-ne-boyus',
                              'izbavlenie-ot-straha-obidy-viny-i-dr'
                            )"
                    >
                        Прослушать фрагмент
                        <img src="/images/landing/play-button.svg">
                    </button>
                </div>
                <div class="wlm-structure__course-descr">
                    <div class="wlm-structure__title">
                        <span class="wlm-structure__title-counter yellow">1</span>
                        Модуль<br> “Я ничего не боюсь”
                    </div>
                    <div class="wlm-structure__subtitle">
                        Для тех, кто хочет избавиться от обиды,
                        страха, и любого другого дискомфортного чувства.
                    </div>
                    <div class="wlm-structure__list-title">В первом модуле вы:</div>
                    <ul class="wlm-structure__ul">
                        <li>Проработаете свои страхи, чувства вины, стыда и др. </li>
                        <li>Отпустите все лишнее, что вас сдерживает
                            и мешает осуществлять свои мечты.
                        </li>
                    </ul>
                    <div @click="saveReturnFlag('structureNoFearScrollMarker')">
                        <router-link
                    to="/meditation-group/ya-nichego-ne-boyus/izbavlenie-ot-straha-obidy-viny-i-dr"
                    class="wlm-structure__read-more"
                        >
                            Подробнее про модуль
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="wlm-structure__course-item">
                <div id="structureAcceptMyselfScrollMarker"
                     class="landing-title-group__hidden-marker"
                ></div>
                <div class="wlm-structure__course-image">
                    <div class="wlm-structure__title isMobile">
                        <span class="wlm-structure__title-counter blue">2</span>
                        Модуль<br> “Я принимаю себя”
                    </div>
                    <img src="/images/landing/course-2.png">
                    <button class="es-button lightBlue w-icon "
                            @click="showPlayerPreview(
                              'ya-prinimayu-sebya',
                              'na-blagodarnost-i-prinyatie-sebyas'
                            )"
                    >
                        Прослушать фрагмент
                        <img src="/images/landing/play-button.svg">
                    </button>
                </div>
                <div class="wlm-structure__course-descr">
                    <div class="wlm-structure__title">
                        <span class="wlm-structure__title-counter blue">2</span>
                        Модуль<br> “Я принимаю себя”
                    </div>
                    <div class="wlm-structure__subtitle">
                        Для тех, кто хочет почувствовать себя
                        целостной и наполненной, жить в гармонии и любви.
                    </div>
                    <div class="wlm-structure__list-title">Во втором модуле вы:</div>
                    <ul class="wlm-structure__ul blue">
                        <li>Научитесь принимать себя и жить в гармонии.</li>
                        <li>Начнете слышать свой внутренний голос.</li>
                    </ul>
                    <div @click="saveReturnFlag('structureAcceptMyselfScrollMarker')">
                        <router-link
                        to="/meditation-group/ya-prinimayu-sebya/na-blagodarnost-i-prinyatie-sebyas"
                        class="wlm-structure__read-more"

                        >
                            Подробнее про модуль
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="wlm-structure__course-item">
                <div id="structureFutureMomScrollMarker"
                     class="landing-title-group__hidden-marker"
                ></div>
                <div class="wlm-structure__course-image">
                    <div class="wlm-structure__title isMobile">
                        <span class="wlm-structure__title-counter pink">3</span>
                        Модуль<br> “Я скоро буду мамой”
                    </div>
                    <img src="/images/landing/course-3.png">
                    <button class="es-button pink w-icon "
                            @click="showPlayerPreview(
                              'ya-skoro-budu-mamoy',
                              'nastroy-na-materinstvo'
                            )"
                    >
                        Прослушать фрагмент
                        <img src="/images/landing/play-button.svg">
                    </button>
                </div>
                <div class="wlm-structure__course-descr">
                    <div class="wlm-structure__title">
                        <span class="wlm-structure__title-counter pink">3</span>
                        Модуль<br> “Я скоро буду мамой”
                    </div>
                    <div class="wlm-structure__subtitle">
                        Для тех, кто только планирует
                        беременность и тех, кто увидел свои заветные две полоски.
                    </div>
                    <div class="wlm-structure__list-title">В третьем модуле вы:</div>
                    <ul class="wlm-structure__ul pink">
                        <li>Настроитесь на материнство и благополучную беременность.  </li>
                        <li>Научитесь чувствовать свое тело и главный женский центр - матку. </li>
                    </ul>
                    <div @click="saveReturnFlag('structureFutureMomScrollMarker')">
                        <router-link
                                to="/meditation-group/ya-skoro-budu-mamoy/nastroy-na-materinstvo"
                                class="wlm-structure__read-more"

                        >
                            Подробнее про модуль
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <BaseAudioPlayer v-if="isPlayerVisible"></BaseAudioPlayer>
    </div>
</template>

<script>
import BaseAudioPlayer from '@/components/meditation/BaseAudioPlayer.vue';
import axios from 'axios';

export default {
  name: 'WelcomeLandingStructure',
  components: {
    BaseAudioPlayer,
  },
  data() {
    return {
    };
  },
  computed: {
    isPlayerVisible() {
      return this.$store.state.player.isVisible;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    currentMeditation() {
      return this.$store.state.meditations.currentMeditation;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  methods: {
    showPlayerPreview(groupID, medID) {
      document.documentElement.style.setProperty('--main-color', this.getMainColor(groupID));

      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('showPlayer');
      this.sendFBVueContent();
    },
    sendFBVueContent() {
      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'ViewContent', {
          content_ids: [this.currentMeditation.id.toString()],
          currency: 'USD',
          content_type: 'product',
          value: this.currentMeditation.discrPrice,
        }, {
          eventID: initiateEventID,
        });
      }

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: this.currentMeditation.discrPrice,
          eventName: 'ViewContent',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
    saveReturnFlag(sectionName) {
      this.$store.commit('setUserMovedFromStructure', sectionName);
    },
    getMainColor(groupID) {
      let mainColor = '';

      this.meditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      return mainColor;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.wlm-structure {
    padding: 50px 24px;
    background: $white;

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 40px 24px;
    }

    &__course-descr {
        @media only screen and (max-width: 767px) {
            margin-top: 3px;
        }
    }

    &__list-title {
        font-weight: 600;
        font-size: 18px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__course-item {
        display: flex;
        margin-bottom: 40px;
        position: relative;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 30px;

            .landing-title-group__hidden-marker {
                top: -20px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__ul {
        margin: 23px 0;

        @media only screen and (max-width: 767px) {
            margin: 6px 0;
            margin-bottom: 24px;
        }

        &.pink {
            li:before {
                background: $main-pink;
            }
        }

        &.blue {
            li:before {
                background: $main-light-blue;
            }
        }

        li {
            list-style: none;
            position: relative;
            margin: 8px 0;
            display: block;
            padding-left: 40px;
            line-height: 27px;
            color: $darkGray;

            @media only screen and (max-width: 767px) {
                font-size: 13px;
                padding-left: 26px;
                line-height: 21px;
            }

            &:before {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                content: '';
                position: absolute;
                background: $main-yellow;
                left: 0;
                top: 12px;
            }
        }
    }

    &__course-image {
        width: 270px;
        flex-shrink: 0;
        margin-right: 80px;

        @media only screen and (max-width: 767px) {
            margin-right: 0;
            width: auto;
        }

        & > img {
            display: block;
            width: 100%;
        }

        button {
            margin-top: 20px;
            font-size: 18px;
        }
    }

    &__title {
        font-size: 22px;
        margin-bottom: 15px;
        font-weight: 600;
        position: relative;
        padding-left: 48px;
        line-height: 30px;

        &.isMobile {
            display: none;

            @media only screen and (max-width: 767px) {
                display: block;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 5px;
            }
        }

        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    &__title-counter {
        font-size: 49px;
        margin-right: 20px;
        line-height: 30px;
        position: absolute;
        left: 0;
        top: 12px;

        @media only screen and (max-width: 767px) {
            font-size: 45px;
        }

        &.yellow {
            color: $main-yellow;
        }

        &.blue {
            color: $main-light-blue;
        }

        &.pink {
            color: $main-pink;
        }
    }

    &__subtitle {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 25px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 13px;
        }
    }

    &__general-info {
        margin: 20px 0 65px 0;

        @media only screen and (max-width: 767px) {
            margin-top: -17px;
            margin-bottom: 25px;
        }
    }

    &__info-item {
        position: relative;
        padding-left: 100px;
        font-size: 18px;
        font-family: $textFont;
        line-height: 27px;
        margin-bottom: 35px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
            padding-left: 48px;
            margin-bottom: 15px;
        }
    }

    &__info-icon {
        background: url("/images/landing/icon-info.svg") no-repeat;
        width: 41px;
        height: 41px;
        position: absolute;
        left: 0;
        top: 0;

        @media only screen and (max-width: 767px) {
            width: 20px;
            height: 20px;
            background-size: cover;
            top: 5px;
        }
    }

    &__read-more {
        padding-left: 40px;
        font-size: 16px;
        color: $main-blue;

        @media only screen and (max-width: 767px) {
            font-size: 13px;
            text-align: center;
            display: block;
            padding-left: 0;
        }
    }
}
</style>
