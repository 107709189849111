<template>
    <div class="base-marathon">
        <template v-if="!isThanksPage">
            <template v-if="isShortPage">
                <HeaderShort></HeaderShort>
            </template>
            <template v-else>
                <Header></Header>
                <YouWillKnow></YouWillKnow>
                <Program></Program>
                <Curators></Curators>
                <RegForm></RegForm>
                <Footer></Footer>
            </template>
        </template>
        <template v-else>
            <ThanksPage></ThanksPage>
        </template>
    </div>
</template>

<script>
import Header from '@/components/secondary-pages/marathonHelth/Header.vue';
import HeaderShort from '@/components/secondary-pages/marathonHelth/HeaderLite.vue';
import YouWillKnow from '@/components/secondary-pages/marathonHelth/YouWillKnow.vue';
import Program from '@/components/secondary-pages/marathonHelth/Program.vue';
import Curators from '@/components/secondary-pages/marathonHelth/Curators.vue';
import RegForm from '@/components/secondary-pages/marathonHelth/RegForm.vue';
import ThanksPage from '@/components/secondary-pages/marathonHelth/ThanksPage.vue';
import Footer from '@/components/secondary-pages/marathonHelth/Footer.vue';

export default {
  name: 'BaseMarathon',
  components: {
    HeaderShort,
    Header,
    YouWillKnow,
    Program,
    Curators,
    RegForm,
    Footer,
    ThanksPage,
  },
  data() {
    return {
      isThanksPage: this.$route.name === 'MarathonThanksHealth',
      isShortPage: this.$route.name === 'MarathonHealingShort' || this.$route.name === 'MarathonHealingShort2',
    };
  },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.base-marathon {
    font-family: 'Montserrat';
}
</style>
