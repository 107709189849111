<template>
    <div class="course-author">
        <div class="course-landing-title">Об авторе</div>
        <div class="course-author__image"></div>
        <div class="course-author__content">
            <div class="course-author__name-title">Елена Сягло</div>
            <div class="course-author__insta-title">@elena.siaglo</div>
            <div class="course-author__subtitle">
                Десять лет в счастливом браке,
                мама двух детей после трех лет “бесплодия”
            </div>
        </div>
        <div class="course-author__achievements-list">
            <div class="course-author__achievements-item">
                <div class="course-author__achievements-title">
                    <span class="text">300</span>
                    <span class="icon">
                        <img src="/images/course-landing/about-icon-0.svg">
                    </span>
                </div>
                <div class="course-author__achievements-text">
                    Обучила более 300 человек на курсе “Ключ к материнству”
                </div>
            </div>
            <div class="course-author__achievements-item">
                <div class="course-author__achievements-title">
                    <span class="text">1 000</span>
                    <span class="icon">
                        <img src="/images/course-landing/about-icon-1.svg">
                    </span>
                </div>
                <div class="course-author__achievements-text">
                    Провела более 1000 личных терапий
                    с клиентами по запросу материнства
                </div>
            </div>
            <div class="course-author__achievements-item">
                <div class="course-author__achievements-title">
                    <span class="text">160 000</span>
                    <span class="icon">
                        <img src="/images/course-landing/about-icon-2.svg">
                    </span>
                </div>
                <div class="course-author__achievements-text">
                    Перинатальный психолог, энерготерапевт,
                    специалист по психосоматике и блогер
                    с аудиторией 160.000 в Instagram
                </div>
            </div>
            <div class="course-author__achievements-item">
                <div class="course-author__achievements-title">
                    <span class="text">5 000</span>
                    <span class="icon">
                        <img src="/images/course-landing/about-icon-4.svg">
                    </span>
                </div>
                <div class="course-author__achievements-text">
                    помогла более 5000 женщинам
                    забеременеть и родить,
                    избавиться от страхов, раскрыть потенциал
                </div>
            </div>
            <div class="course-author__achievements-item">
                <div class="course-author__achievements-title">
                    <span class="text">25 000</span>
                    <span class="icon">
                        <img src="/images/course-landing/about-icon-3.svg">
                    </span>
                </div>
                <div class="course-author__achievements-text">
                    Более 25.000 человек купили и
                    успешно проходят авторские медитации
                    и практики от Елены для планирования
                    беременности и благополучных родов
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
  name: 'CourseAuthor',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-author {
    background: $white;
    padding: 50px 23px 10px 23px;
    min-height: 400px;

    @media only screen and (min-width: 767px) {
        background-size: 540px;
    }

    &__image {
        margin: -35px -23px -25px -23px;
        padding: 0 23px 20px 23px;
        background: url("/images/course-landing/about-bg-2.png") no-repeat 50% 0;
        background-size: 360px;
        text-align: center;
        min-height: 250px;

        @media only screen and (min-width: 767px) {
            background-size: 540px;
            margin-top: 0;
            min-height: 400px;
        }

        @media only screen and (min-width: 1024px) {
            background-position: -70px 75px;
            background-size: 671px;
            min-height: 590px;
            margin-top: -140px;
        }
    }

    &__content {
        @media only screen and (min-width: 1024px) {
           text-align: left;
            margin-left: 505px;
            margin-top: -332px;
            position: relative;
            top: -95px;
        }
    }

    &__name-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 170%;
        text-align: center;

        @media only screen and (min-width: 1024px) {
            text-align: left;
            font-size: 24px;
        }
    }

    &__insta-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 170%;
        text-align: center;

        @media only screen and (min-width: 1024px) {
            text-align: left;
        }
    }

    &__subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin: 28px 0 20px 50px;

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            line-height: 150%;
            max-width: 408px;
            margin: 38px auto;
            text-align: center;
        }

        @media only screen and (min-width: 1024px) {
            text-align: left;
            max-width: 330px;
            margin: 20px 0;
            font-size: 22px;
            line-height: 150%;
        }
    }

    &__achievements-list {
        margin: 50px 0 10px 50px;

        @media only screen and (min-width: 767px) {
            max-width: 408px;
            margin-left: 245px;
            margin-top: 80px;
        }

        @media only screen and (min-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
            max-width: 840px;
            width: 820px;
            margin: 120px auto 20px auto;
            justify-content: space-between;
            position: relative;
            left: 46px;
        }
    }

    &__achievements-item {
        margin: 40px 0 20px 0;

        &:nth-child(4) {
            .course-author__achievements-title .icon {
                left: calc(100% - 25px);
            }
        }

        @media only screen and (min-width: 1024px) {
            max-width: 380px;
        }
    }

    &__achievements-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin: 10px 0 0 0;

        @media only screen and (min-width: 767px) {
            font-size: 16px;
            line-height: 150%;
        }
    }

    &__achievements-title {
        display: inline-block;
        position: relative;

        .text {
            position: relative;
            z-index: 2;
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
            color: $main-blue;
        }

        .icon {
            position: absolute;
            z-index: 1;
            bottom: 5px;
            left: calc(100% - 15px);
        }
    }
}
</style>
