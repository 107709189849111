<template>
    <div class="welcome-header"
         :class="{
            'isCabinet': isCabinet,
            'singlePage': isSingleMeditationPayment,
            'isNewMeditation': isNewMeditationLanding,
         }"
    >

        <div class="welcome-header__conspect"
              v-if="isWelcomeWebinarPage"
             @click="scrollToConspact()"
        ></div>

        <div class="welcome-header__content">
            <h1 v-if="isSingleMeditationPayment">
                Медитации<br> для женщин
            </h1>
            <h1 v-else-if="isNewMeditationLanding">
                Медитация "На раскрытие женственности и сексуальности"
            </h1>
            <h1 v-else>
                Медита<span class="dot-top">ц</span>ия<br>
                в н<span class="dot-bottom">о</span>вую жизнь
            </h1>
            <template v-if="!isCabinet">
                <div class="welcome-header__descr" v-if="isUserAuthed">
                    Ваши товары доступны по <router-link to="/my">ссылке</router-link>
                </div>
                <div v-else-if="isSingleMeditationPayment || isNewMeditationLanding"
                     class="welcome-header__descr"
                >
                    Послушайте короткий фрагмент<br>
                    медитации бесплатно
                </div>
                <div class="welcome-header__descr" v-else>
                    Стань новой версией себя и<br class="isMobile"> впусти <br class="isDesktop">
                    малыша в свою жизнь
                </div>
                <div class="welcome-header__start-block"
                     v-if="!isSingleMeditationPayment && !isNewMeditationLanding"
                >
                    <WelcomeStartCounter
                            v-if="isUserFromWebinar || isModulePayemnt"
                    ></WelcomeStartCounter>
                    <div v-else-if="isUserAuthed" class="welcome-header__buy-more">
                        <p>Чтобы получить максимальный эффект мы
                            предлагаем докупить остальные товары до
                            полного курса по <strong>специальной цене.</strong></p>
                        <button class="es-button white " @click="scrollToPayment()">
                            Подробнее
                        </button>
                    </div>
                    <button v-else class="es-button" @click="scrollToPayment()">Начать</button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import WelcomeStartCounter from '@/components/secondary-pages/welcome-landing/WelcomeStartCounter.vue';

export default {
  name: 'WelcomeLandingHeader',
  components: {
    WelcomeStartCounter,
  },
  data() {
    return {
      isModulePayemnt: this.$route.name === 'Supermodule',
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isNewMeditationLanding() {
      return this.$route.name === 'New';
    },
    isSingleMeditationPayment() {
      return this.$route.name === 'Finalprice';
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isWelcomeWebinarPage() {
      return this.$route.name === 'WelcomeWebinar';
    },
  },
  methods: {
    scrollToPayment() {
      this.$store.dispatch('scrollToLandingPayment');
    },
    scrollToConspact() {
      const hiddenElement = document.querySelector('#conspectBanner');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";

.welcome-header {
    min-height: 520px;
    background: url("/images/landing/welcome-header.jpg") center center;
    background-size: cover;
    margin-top: 60px;
    padding-top: 200px;
    box-sizing: border-box;
    text-align: center;
    color: $white;
    position: relative;
    padding-bottom: 1px;

    &__conspect {
        background: url("/images/registration-webinar/conspect-icon.png");
        width: 100px;
        height: 100px;
        background-size: contain;
        position: absolute;
        z-index: 10;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    &.isNewMeditation {
        background: url("/images/landing/welcome-header-new.jpg") center center;
        background-size: cover;

        @media only screen and (max-width: 767px) {
            background-color: #91857c;
            background-repeat: no-repeat;
            background-image: url("/images/landing/welcome-header-new-sm.jpg");
            background-position: 70% 0;
            background-size: 120%;
            padding-top: 80px;
            min-height: 350px;

            &:after {
                position: absolute;
                content: '';
                width: 100%;
                bottom: -1px;
                left: 0;
                height: 250px;
                background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
                z-index: 2;
            }
        }

        h1 {
            @media only screen and (max-width: 767px) {
                font-size: 28px;
                line-height: 32px;
            }
        }
    }

    &.isCabinet {
        min-height: 430px;

        @media only screen and (max-width: 767px) {
            min-height: 350px;
            padding-top: 170px;
        }
    }

    &__buy-more {
        background: $main-blue;
        max-width: 470px;
        margin: 20px auto;
        padding: 20px 40px 10px 40px;
        color: $white;
        text-align: center;
        border-radius: 10px;
        box-sizing: border-box;
        width: calc(100% - 50px);

        @media only screen and (max-width: 767px) {
            padding: 16px;
        }

        p {
            margin-bottom: 1px;
            line-height: 17px;
            font-size: 13px;
            font-weight: 500;

            @media only screen and (max-width: 767px) {
               margin-bottom: 5px;
            }

            strong {
                font-weight: 600;
                color: #eee61e;
            }

            @media only screen and (max-width: 450px) {
               text-align: left;
            }
        }

        button {
            width: 170px!important;

            @media only screen and (max-width: 450px) {
                width: 100%!important;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 5;
    }

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 360px;
        width: 100%;
        content: '';
        background: linear-gradient(1.82deg, rgba(0, 0, 0, 0.35) 70.27%, rgba(0, 0, 0, 0) 89.42%);
    }

    h1 {
        font-weight: 600;
        font-size: 46px;
        color: $white;
        line-height: 54px;
        letter-spacing: 1px;

        @media only screen and (max-width: 767px) {
            font-size: 34px;
            line-height: 40px;
        }

        .dot-top,
        .dot-bottom {
            position: relative;

            &:before {
                width: 7px;
                height: 7px;
                position: absolute;
                content: '';
                top: 3px;
                left: 12px;
                background: $white;
                border-radius: 50%;

                @media only screen and (max-width: 767px) {
                    width: 5px;
                    height: 5px;
                    left: 8px;
                }
            }
        }

        .dot-bottom:before {
            top: auto;
            bottom: -2px;
        }
    }

    &__descr {
        font-size: 15px;
        line-height: 21px;
        max-width: 500px;
        margin: 15px auto;
        font-weight: 500;
        width: calc(100% - 80px);

        br {
            display: none;

            &.isMobile {
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }

            &.isDesktop {
                @media only screen and (min-width: 768px) {
                    display: block;
                }
            }
        }

        a {
            background: $main-blue;
            color: $white;
            padding: 0 5px;

            &.transparent {
                background: transparent;
            }
        }
    }

    &__start-block {
        text-align: center;

        button {
            display: inline-block;
            width: auto;
            padding: 0 25px;
            cursor: pointer;

            @media only screen and (max-width: 450px) {
                width: calc(100% - 50px);
            }
        }
    }
}
</style>
