<template>
    <div class="insta-start">
        <Header></Header>
        <Buttons></Buttons>
    </div>
</template>

<script>
import Header from '@/components/secondary-pages/start-page/Header.vue';
import Buttons from '@/components/secondary-pages/start-page/Buttons.vue';

export default {
  name: 'Insta',
  components: {
    Header,
    Buttons,
  },
  data() {
  },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.insta-start {
    background: #F6F8FE;
    padding: 10px 24px;
}
</style>
