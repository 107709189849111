<template>
    <div class="cabinet-header">
        <div class="cabinet-header__header">
            <div class="cabinet-header__title">Добро пожаловать<br> в личный кабинет</div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CabinetHeader',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.cabinet-header {
    background: $white;
    position: relative;
    margin: 60px 0 0 0;

    &__header {
        text-align: center;
        padding: 60px 23px 0 23px;
    }

    &__email-cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        background: rgb($black, 0.83);
        line-height: 37px;
        padding: 0 24px;
        color: $white;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    &__sub-title {
        font-size: 14px;
        line-height: 20px;
    }
}
</style>
