<template>
  <div class="landing-page">
    <BaseWebinarRegistration></BaseWebinarRegistration>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseWebinarRegistration from '@/components/secondary-pages/BaseWebinarRegistration.vue';

export default {
  name: 'WebinarRegistration',
  components: {
    BaseWebinarRegistration,
  },
};
</script>
