<template>
    <div class="mh-program">
        <div class="mh-content">
            <div class="mh-title">
                <span class="purple">Программа:</span>
            </div>

            <div class="mh-program_list">
                <div class="mh-program_list-item">
                    <div class="mh-program_list-item-image">
                        <img src="/images/marathon/mh-program-1.png">
                    </div>
                    <div class="mh-program_list-item-content">
                        <div class="mh-program_list-item-content-date">День 1</div>
                        <div class="mh-program_list-item-content-text">
                            Сделаете <span class="purple">медитацию от болезней</span>,
                            чтобы исцелить тело и возвратить
                            себя в нормальное здоровое состояние.
                        </div>
                    </div>
                </div>

                <div class="mh-program_list-item">
                    <div class="mh-program_list-item-image">
                        <img src="/images/marathon/mh-program-2.png">
                    </div>
                    <div class="mh-program_list-item-content">
                        <div class="mh-program_list-item-content-date">День 2</div>
                        <div class="mh-program_list-item-content-text">
                            Сделаете
                            <span class="purple">медитацию на богатого мужчину,</span>
                            чтобы улучшить отношения с
                            партнером и привлечь в свою жизнь изобилие.
                        </div>
                    </div>
                </div>
                <div class="mh-program_list-item">
                    <div class="mh-program_list-item-image">
                        <img src="/images/marathon/mh-program-3.png">
                    </div>
                    <div class="mh-program_list-item-content">
                        <div class="mh-program_list-item-content-date">День 3</div>
                        <div class="mh-program_list-item-content-text">
                            Сделаете <span class="purple">медитацию на успех</span>,
                            чтобы укрепить веру в свои силы,
                            повысить самооценку и достичь выдающихся результатов.
                        </div>
                    </div>
                </div>
            </div>

            <div class="mh-program_highlight">
                <div class="mh-program_highlight-image">
                    <img src="/images/marathon/mh-gift-icon.png">
                </div>
                <div class="mh-program_highlight-text">
                    На марафоне вы получите <span class="purple">бонусы и подарки</span>,
                    которые останутся у вас навсегда!
                </div>
                <button
                        class="mh-button mh-button--purple mh-button--shadow"
                        @click="scrollToForm()"
                >
                    Зарегистрироваться
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Program',
  data() {
  },
  computed: {
  },
  methods: {
    scrollToForm() {
      const hiddenElement = document.querySelector('#mh-form');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-program {
    background: #ECDFFF;
    padding: 115px 0;

    @media only screen and (max-width: 768px) {
        padding: 40px 0;
    }

    .mh-content {
        max-width: 1200px;
        margin: auto;
        min-height: 650px;

        @media only screen and (max-width: 1200px) {
            max-width: 730px;
        }

        @media only screen and (max-width: 768px) {
            max-width: 360px;
            padding: 0 10px;
        }
    }

    &_list {
        position: relative;
        margin: 80px auto;
        max-width: 780px;

        @media only screen and (max-width: 768px) {
            margin: 40px auto;
        }
    }

    &_list-item {
        margin: 17px 0;
        background: $white;
        border: 1px solid #E1CDFF;
        box-shadow: 0 10px 30px rgba(135, 66, 235, 0.1);
        border-radius: 30px;
        display: flex;
        align-items: center;
        padding: 23px 30px;
        position: relative;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            margin: 5px 0;
        }

        &:after {
            position: absolute;
            content: '';
            background: url("/images/marathon/mh-arrow-bottom-left.svg") no-repeat;
            height: 132px;
            width: 34px;
            left: -60px;
            top: -30px;

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        &:first-child:after {
            display: none;
        }

        &:nth-child(even):after {
            background: url("/images/marathon/mh-arrow-bottom-right.svg") no-repeat;
            left: auto;
            right: -60px;
        }
    }

    &_list-item-image {
        margin-right: 30px;

        img {
            max-width: 170px;

            @media only screen and (max-width: 768px) {
                max-width: 120px;
            }
        }

        @media only screen and (max-width: 768px) {
            order: 2;
            margin-right: 0;
            margin-top: 15px;
        }
    }

    &_list-item-content {
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;

        .purple {
           color: #742DDB;
        }
    }

    &_list-item-content-date {
        display: inline-block;
        background: $main-purple url("/images/marathon/mh-calendar-icon.svg") no-repeat 13px 50%;
        height: 41px;
        line-height: 41px;
        padding: 0 13px 0 40px;
        border-radius: 40px;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: -2px;
        margin-top: -10px;
    }

    &_highlight {
        font-weight: 500;
        font-size: 28px;
        line-height: 120%;
        text-align: center;
        margin: 150px auto 20px auto;
        max-width: 780px;

        img {
            max-width: 190px;

            @media only screen and (max-width: 768px) {
                max-width: 105px;
            }
        }

        .purple {
            color: $main-purple;
        }

        @media only screen and (max-width: 768px) {
            margin-top: 50px;
            font-size: 20px;
        }
    }

    &_highlight-text {
        margin: -10px 0 40px 0;
    }
}
</style>
