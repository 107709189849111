<template>
    <div class="webinar-descr">
        <div class="webinar-descr__cloud-block">
            <div class="webinar-descr__cloud-text">
                <strong>Устали делать тесты</strong> и ходить по врачам?
            </div>
            <div class="webinar-descr__cloud-text">
                Болезни по женски <strong>только накапливаются?</strong>
            </div>
            <div class="webinar-descr__cloud-text">
                Хотите
                забеременеть, но уже <strong>никому не верите</strong>?
            </div>
        </div>
        <div class="webinar-descr__large-title">У нас есть ответы</div>
        <div class="webinar-descr__text">
            На вебинаре вы узнаете <strong>почему у вас не наступает <br>
            беременность,</strong> поверите в беременность и
            <strong>роды без страхов</strong>
            и <br>
            тревог, вдохновитесь на перемены в жизни.
        </div>
    </div>
</template>

<script>

export default {
  name: 'WebinarDescr',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.webinar-descr {
    padding: 25px 24px;
    background: url('/images/registration-webinar/webinar-text.svg') $white no-repeat 50% 15px;
    background-size: 105%;
    min-height: 200px;
    text-align: center;

    &__cloud-block {
        background: url(/images/registration-webinar/text-cloud.svg) no-repeat 50% 0;
        height: 400px;
        background-size: contain;
        margin: 0 auto 45px auto;
        padding: 1px;
        width: 315px;
    }

    &__large-title {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-weight: 600;
    }

    &__text {
        font-size: 14px;
        margin-top: 15px;
        line-height: 21px;

        strong {
            font-weight: 600;
        }

        @media only screen and (max-width: 767px) {
            br {
                display: none;
            }
        }
    }

    &__cloud-text {
        color: #447DF3;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;

        strong {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 200px;
            margin: 58px auto;
        }

        &:nth-child(2) {
            width: 200px;
            margin: 80px auto 58px 10px;
        }

        &:nth-child(3) {
            width: 200px;
            margin: 65px auto 58px 72px;
        }
    }
}
</style>
