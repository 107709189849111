<template>
    <div class="course-target">
        <div class="course-landing-title">Для кого этот курс?</div>

        <div class="course-target__faq">
            <div class="course-target__faq-item"
                v-for="(item, index) in questions"
                 :class="{'isActive': item.isActive}"
                 :key="index"
            >
                <div class="course-target__faq-title"
                     v-html="item.question"
                     @click="toggleActiveState(index)"
                ></div>
                <div class="course-target__faq-content" v-html="item.answer"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseTarget',
  data() {
    return {
      questions: [
        {
          question: 'У кого никогда <strong>не было беременности</strong>',
          answer: '<p>Забудете про диагноз «бесплодие»,  уйдет страх никогда не забеременеть, перестанете в панике делать анализы и тесты, забудете про секс по расписанию.</p><p>Уйдет обида и злость на беременных подруг и бестактные вопросы «а ты когда?»</p><p>Проработаете «неполноценность» и «насмешки» окружающих.</p>',
          isActive: true,
        },
        {
          question: 'У кого не получается благополучно <strong>выносить малыша</strong>',
          answer: '<p>Отпустите нерожденных деток, проживете боль утраты и потери, уйдет страх никогда не стать мамой, перестанете винить себя и жить в отчаянии.</p><p>Проработаете свою беспомощность, снимите с себя груз и тяжесть за выкидыши и замершие беременности.</p><p>Перестанете читать бесполезные форумы и навешивать на себя чужие страхи.</p>',
          isActive: false,
        },
        {
          question: 'Кто готовится <strong>к ЭКО</strong>',
          answer: '<p>Расслабите тело перед ЭКО, отпустите все страхи, тревоги и сомнения связанные с ЭКО. </p><p>Настроитесь на благополучный результат, проработаете страхи болезней и неизвестности.</p><p>Забудете про свой возраст и мнение окружающих, поверите в свои силы.</p>',
          isActive: false,
        },
        {
          question: 'У кого <strong>есть дети</strong>, но не получается <strong>повторно забеременеть</strong>',
          answer: '<p>Уйдет страх не успеть родить повторно и страх что не хватит денег, проработаете вину «не смогу быть хорошей мамой».</p> <p>Перестанете злиться на бестактные вопросы «когда второго?», уберете гонку и режим «родить любой ценой».</p> <p>Проработаете вторичные выгоды и желание «родить для кого-то».</p>',
          isActive: false,
        },
        {
          question: '<strong>Кто забеременел</strong>, но не чувствует себя <strong>в безопасности</strong>',
          answer: '<p>Расслабите тело и голову, уйдет страх родов, погрузитесь в волшебное состояние материнства и проживете его в своем теле.\n</p> <p>Узнаете как подготовить себя к родам, смените фокус с бесконечных анализов, узи и посещения врачей на свое состояние.\n</p> <p>Наладите связь со своим малышом, дадите ему чувство безопасности и любви.</p>',
          isActive: false,
        },
        {
          question: 'У кого <strong>не получается совместить</strong>, материнство и проф. реализацию',
          answer: '<p>Уйдет страх «не успеть и упустить», поймете как реализоваться в обоих направлениях без ущерба себе и своей семье.\n</p> <p>Научитесь входить в ресурсное состояние и настроение, которое будет наполнять вас и помогать добиваться успешных результатов.</p>',
          isActive: false,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    toggleActiveState(index) {
      this.questions.forEach((item, indexItem) => {
        const currentItem = this.questions[indexItem];

        if (index === indexItem) {
          currentItem.isActive = !currentItem.isActive;
        } else {
          currentItem.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-target {
    padding: 60px 23px 20px 23px;
    background: $landingLightestGray
    url("/images/course-landing/target-section-bg.png") 50% 0 no-repeat;

    @media only screen and (min-width: 767px) {
        padding-top: 80px;
    }

    &__faq-item {
        background: $white;
        border-radius: 15px;
        margin-bottom: 6px;

        @media only screen and (min-width: 767px) {
            max-width: 620px;
            margin: auto auto 6px auto;
        }

        &.isActive {
            .course-target__faq-content {
                max-height: 1200px;
                padding: 4px 15px 15px 15px;

                @media only screen and (min-width: 767px) {
                    padding: 0 25px 25px 25px;
                }
            }

            .course-target__faq-title {
                strong {
                    color: #DB38A4;
                }

                &:after {
                    background:
                            url("/images/course-landing/minus-icon-blue.svg")
                            $landingGray no-repeat 50% 50%;
                }
            }
        }
    }

    &__faq-title {
        padding: 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        position: relative;
        padding-right: 50px;

        @media only screen and (min-width: 767px) {
            padding: 25px;
        }

        strong {
            font-weight: 900;
            color: #7D89A1;
        }

        &:after {
            content: '';
            position: absolute;
            right: 12px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;
        }
    }

    &__faq-content {
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-family: $titleFont;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
