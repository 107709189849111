<template>
    <div class="payment-FAQ" id="paymentFormAnchor">
        <div class="payment-FAQ__handler"
             :class="{'isContentVisible': isContentVisible}"
             @click="toggleVisible"
        >
            <div class="payment-FAQ__handler-title">
                Не можете определиться со способом оплаты?
            </div>
            <div class="payment-FAQ__handler-text">
                Читайте про все способы оплаты, рассрочку
            </div>
        </div>
        <div class="payment-FAQ__full" :class="{'isContentVisible': !isContentVisible}">
            <div class="payment-FAQ__full-section">
                <div class="payment-FAQ__section-title">Какие есть способы оплаты?</div>
                <div class="payment-FAQ__ol">
                    <div class="payment-FAQ__ol-bullet">1</div>
                    <div class="payment-FAQ__title-small">Для карт выпущенных в РФ:</div>
                    <p><b>Оплата через платежную форму Тинькофф, Сбербанк, ВТБ, АльфаБанк</b><br/>
                        Можно оплатить без ввода данных карты или ввести
                        данные карты любого российского банка.</p>
                    <p>
                        <b>Система быстрых платежей СБП.</b><br/>
                        Нужно отсканировать QR-код — оплата откроется
                        в приложении вашего банка. Не нужно вводить данные карты.
                    </p>
                </div>
                <div class="payment-FAQ__ol">
                    <div class="payment-FAQ__ol-bullet">2</div>
                    <div class="payment-FAQ__title-small">Для карт выпущенных не в РФ:</div>
                    <p><b>Оплата в тенге через платежную форму JetPay.</b><br/>
                        Подходит для Казахстана и Беларуси и других стран СНГ.
                        Стоимость конвертируется в тенге по курсу на день оплаты.</p>
                    <p>
                        <b>Оплата в долларах через платежную систему Stripe.</b><br/>
                        Подходит для Украины, стран ЕС, Америки, Азии и других стран.
                        Не подходит для карт выпущенных в России.
                        Для Беларуси оплаты проходят по картам банков,
                        на которые не наложены санкции.
                    </p>
                    <p>
                        <b>Оплата в долларах через платежную систему ИнтеллектМани.</b><br/>
                        Подходит для всех карт, кроме тех, которые выпущены в РФ.
                    </p>
                    <p>
                        <b>Оплата через платежную систему PayPal.</b><br/>
                        Подходит для всех, у кого есть PayPal.
                    </p>

                    <p class="payment-FAQ__highlighted">
                        Выберите тариф, нажмите кнопку “Продолжить” и
                        далее сможете оплатить удобным способом. Способов оплаты очень много,
                        поэтому каждый желающий из любой страны найдет решение для себя.
                    </p>
                </div>
            </div>
            <div class="payment-FAQ__full-section">
                <div class="payment-FAQ__section-title">Какие есть способы рассрочки?</div>

                <div class="payment-FAQ__ol">
                    <div class="payment-FAQ__ol-bullet">1</div>
                    <div class="payment-FAQ__title-small">
                        Беспроцентная рассрочка от банка для граждан РФ:
                    </div>
                    <p><b>Беспроцентная рассрочка от банка Тинькофф.</b><br/>
                        Доступна на 3, 6 и 12 месяца без первоначального взноса.
                    </p>
                    <p><b>Беспроцентная рассрочка «Покупай со Сбером» от Сбербанка.</b><br/>
                        Доступна на 3, 6 и 12 месяца без первоначального взноса.
                    </p>
                    <p><b>Беспроцентная рассрочка «Всегда.Да» от Хоум Кредит Банка.</b><br/>
                        Доступна на 3, 6 и 12 месяца без первоначального взноса.
                    </p>
                </div>
                <div class="payment-FAQ__ol">
                    <div class="payment-FAQ__ol-bullet">2</div>
                    <div class="payment-FAQ__title-small">
                        Беспроцентная рассрочка от банка для граждан
                        Казахстана, Беларуси, Киргизии и Узбекистана:
                    </div>
                    <p><b>Беспроцентная рассрочка от банка РессурсРазвисия (в самом низу)</b>
                        Доступна на 3, 6 и 12 месяца без первоначального взноса.
                    </p>
                    <p class="payment-FAQ__highlighted">
                        Чтобы оформить рассрочку от банка, выберите тариф
                        на сайте и нажмите кнопку “Продолжить”.
                        На странице оплаты выберите подходящую
                        рассрочку и следуйте инструкции.
                    </p>
                </div>
                <div class="payment-FAQ__ol">
                    <div class="payment-FAQ__ol-bullet">3</div>
                    <div class="payment-FAQ__title-small">
                        Внутренняя доверительная рассрочка для всех стран:
                    </div>
                    <p><b>Беспроцентная внутренняя рассрочка.</b><br/>
                        Доступна на 2 или 4 месяцев в зависимости от тарифа.
                        Вся сумма вашего заказа
                        будет разбита на 2 или 4 равные части. Первую часть вы
                        должны внести сразу.
                        Следующая часть — через месяц. В случае просрочки
                        платежей, доступ к курсу будет
                        автоматически закрыт. После оплаты доступ снова
                        откроется. Доверительная рассрочка
                        доступна гражданам всех стран, если все вышеперечисленные банки
                        отказали предоставить рассрочку.
                    </p>
                    <p class="payment-FAQ__highlighted">
                        Чтобы купить курс по внутренней доверительной
                        рассрочке, напишите в Службу заботы и наши
                        менеджеры вышлют ссылку на оплату первой части.
                    </p>
                </div>
            </div>
        </div>
        <!--<div class="payment-FAQ__faq-item"-->
             <!--v-for="(item, index) in questions"-->
             <!--:class="{'isActive': item.isActive}"-->
             <!--:key="index"-->
             <!--@click="toggleActiveState(index)"-->
        <!--&gt;-->
            <!--<div class="payment-FAQ__faq-title"-->
                 <!--v-html="item.question"-->
            <!--&gt;</div>-->
            <!--<div class="payment-FAQ__faq-content" v-html="item.answer"></div>-->
        <!--</div>-->
    </div>
</template>

<script>

export default {
  name: 'CoursePaymentFAQ',
  data() {
    return {
      questions: [
        {
          question: 'Условия оформления рассрочки?',
          answer: '<ol><li>Без процентов на срок до 12 месяцев.</li><li>Первый взнос только через месяц, сейчас ничего не платите.</li><li>Возможно только для граждан России.</li></ol> <br> Если вы не из России, <a href="https://t.me/helpElenaSiaglo_bot" onclick="event.stopPropagation()">напишите в службу заботы</a>, чтобы оформить внутреннюю рассрочку на 3 месяца.',
          isActive: false,
        },
        {
          question: 'Как оформить рассрочку?',
          answer: '<ol class="envelope-bg"><li>Нажимайте кнопку «Оформить рассрочку». На странице оплаты прокрутите вниз до блока “Кредит/рассрочка Тинькофф” и выберите подходящий срок рассрочки. Затем нажмите на кнопку «Оформить рассрочку». Далее выбирайте условия и заполняйте анкету до конца.</li><li>Вашу заявку рассмотрят 2 банка (Тинькофф Банк и МТС Банк). Ждите звонка от менеджера, он согласует дату и время встречи для подписания договора.</li><li>Подпишите договор. После этого банк отправит нам сообщение о подписании, и вы получите на почту письмо с доступом к курсу.</li></ol><br>Если банк вам не одобрил рассрочку или вы не из России, тогда <a href="https://t.me/helpElenaSiaglo_bot" onclick="event.stopPropagation()">напишите в службу заботы</a>, чтобы оформить внутреннюю рассрочку на 3 месяца.',
          isActive: false,
        },
      ],
      isContentVisible: false,
    };
  },
  computed: {
  },
  methods: {
    toggleVisible() {
      this.isContentVisible = !this.isContentVisible;
    },
    toggleActiveState(index) {
      this.questions.forEach((item, indexItem) => {
        const currentItem = this.questions[indexItem];

        if (index === indexItem) {
          currentItem.isActive = !currentItem.isActive;
        } else {
          currentItem.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.payment-FAQ {
    background: $landingLightestGray;
    padding: 5px 23px 20px 23px;

    &__full {
        font-family: 'Montserrat';
        margin-bottom: 50px;
        min-height: 1000px;
        transition: height 200ms 200ms;
        margin-top: -50px;

        &.isContentVisible {
            min-height: 0;
            height: 0;
            overflow: hidden;
            margin: 0;
        }
    }

    &__full-section {
        padding: 50px 150px;

        @media only screen and (max-width: 767px) {
            padding: 40px 30px;
            margin: 0 -20px;
        }

        &:nth-child(even) {
            background: #fff;
        }

        &:nth-child(2) {
            padding-bottom: 150px;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 40px;
        }

        &:first-child {
            padding-top: 30px;
        }
    }

    &__installment-plan-cover {
        position: relative;
        padding: 20px;
        margin-top: -170px;

        @media only screen and (max-width: 767px) {
            margin: -170px -20px 0 -20px;
        }

        &:after {
            background: #FFFFFF;
            opacity: 0.4;
            box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
            border-radius: 20px;
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            left: 0;
            top: 0;
        }
    }

    &__installment-plan {
        background: #E4E8F5;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 20px;
        position: relative;
        z-index: 2;
        padding: 40px 55px 2px 55px;

        .payment-FAQ__section-title {
            text-align: left;
        }

        .payment-FAQ__ol {
            padding-left: 0;
        }
    }

    &__section-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        text-align: center;
        margin-bottom: 40px;
    }

    &__ol {
        position: relative;
        padding-left: 30px;
        margin-bottom: 50px;

        p {
            margin: 20px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;

            &.payment-FAQ__highlighted {
                color: #447DF3;
                font-weight: 600;
            }
        }

        b {
            font-weight: 600;
        }
    }

    &__ol-bullet {
        position: absolute;
        left: 3px;
        top: 3px;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
    }

    &__title-small {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: -5px;
    }

    &__handler {
        background: url("/images/course-landing/payment-faq-bg.png") no-repeat 50% 50%;
        background-size: 670px;
        min-height: 143px;
        text-align: center;
        font-family: 'Montserrat';
        max-width: 670px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 10px auto 40px auto;
        transition: height 200ms 200ms;
        overflow: hidden;

        &.isContentVisible {
            .payment-FAQ__handler-title:after {
                background:
                        url("/images/course-landing/minus-icon-blue.svg")
                        $landingGray no-repeat 50% 50%;
            }
            /*min-height: 0;*/
            /*height: 0;*/
            /*margin: 0;*/
        }
    }

    &__handler-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 140%;
        color: #292935;
        text-decoration: underline;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: -50px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;

            @media only screen and (max-width: 767px) {
                top: auto;
                right: auto;
                margin-left: 15px;
                margin-top: 4px;
            }
        }
    }

    &__handler-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #7D89A1;
    }

    &__faq-item {
        margin-bottom: 6px;
        border-bottom: solid $landingGray 1px;

        @media only screen and (min-width: 767px) {
            text-align: center;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }

        @media only screen and (min-width: 1024px) {
           &:last-child {
               border-bottom: 0;
           }
        }

        &.isActive {
            .payment-FAQ__faq-content {
                max-height: 1200px;
                padding: 5px 0 15px 0;
            }

            .payment-FAQ__faq-title {
                strong {
                    color: #DB38A4;
                }

                &:after {
                    background:
                            url("/images/course-landing/minus-icon-blue.svg")
                            $landingGray no-repeat 50% 50%;
                }
            }
        }
    }

    &__hideButton {
        text-align: center;
        margin: 40px 0 0 0;

        button {
            border: none;
            background: transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: rgba(46, 59, 95, 0.4);
            cursor: pointer;
            font-family: 'Montserrat';
        }
    }

    &__faq-title {
        padding: 15px 50px 15px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        position: relative;

        @media only screen and (min-width: 767px) {
            font-size: 22px;
            line-height: 140%;
            display: inline-block;
        }

        strong {
            font-weight: 900;
            color: #7D89A1;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;
        }
    }

    &__faq-content {
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        ol {
            list-style-type: none;
            counter-reset: num;
            margin: 0 0 0 25px;
            padding: 15px 0 5px 0;

            @media only screen and (min-width: 767px) {
                text-align: left;
                max-width: 520px;
                margin: 0 auto;
            }

            &.envelope-bg {
                background: url("/images/course-landing/mail-bg.svg") no-repeat 50% 97%;
                padding-bottom: 80px;
            }

            li {
                position: relative;
                padding: 0 0 20px 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;

                &:last-child {
                    padding-bottom: 0;
                }

                &:before {
                    content: counter(num)".";
                    counter-increment: num;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: -25px;
                    width: 30px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 150%;
                }
            }
        }
    }
}
</style>
