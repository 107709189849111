<template>
    <div class="insta-header">
        <div class="insta-header__top-img">
            <img src="/images/start-page/insta-header.png">
        </div>
        <div class="insta-header__bottom-img">
            <img src="/images/start-page/insta-logo.svg">
        </div>
    </div>
</template>

<script>
export default {
  name: 'headerInsta',
  data() {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.insta-header {
    position: relative;
    padding: 10px 0;
    text-align: center;

    &__top-img {
        position: relative;
        left: 5px;
        img {
            width: 90%;
            max-width: 620px;
        }
    }

    &__bottom-img {
        margin: 20px 0 30px 0;
        img {
            width: 80%;
            max-width: 450px;
        }
    }
}

</style>
