<template>
    <div class="course-header" id="hiddenTop">
        <div class="course-header__title-bg">
            ПУТЕВОДИТЕЛЬ УЧЕНИКА
        </div>
        <div class="course-header__title-small">Добро пожаловать на</div>
        <div class="course-header__title">
            Ключ к <br> материнству <span class="course-header__title-count"></span>
        </div>

        <div class="course-header__info">
            Это сайт-путеводитель, который поможет понять, что делать<br>
            после покупки, и даст ответы на самые частые вопросы.
        </div>

        <div class="course-header__hr"><span></span></div>

        <div class="course-header__landing-title">
            Что вас ждёт в ближайшее время?
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseHeader',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-header {
    text-align: center;
    min-height: 400px;
    background: url("/images/guidebook/guidebook-header-bg.jpg") 50% -280px no-repeat #f3f6fd;
    background-size: 1200px;
    padding: 0 23px 30px 23px;

    &__title-bg {
        width: 600px;
        height: 112px;
        text-align: center;
        background: #292935;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        font-weight: 400;
        font-size: 30px;
        line-height: 150%;
        font-family: 'Tenor Sans';
        color: $white;
        margin: 0 auto 80px auto;
        padding-top: 45px;
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;

        @media only screen and (max-width: 500px) {
            font-size: 18px;
            width: calc(100% + 46px);
            margin-left: -23px;
            height: 85px;
            padding-top: 30px;
            margin-bottom: 40px;
        }
    }

    &__hr {
        position: relative;
        text-align: center;
        margin: 80px auto;
        max-width: 680px;

        span {
            width: 5px;
            height: 5px;
            display: inline-block;
            background: #D1D6E7;
            border-radius: 50%;
            position: relative;
            top: -9px;
            left: -1px;
        }

        &:before,
        &:after {
            width: 46%;
            position: absolute;
            height: 1px;
            background: #D1D6E7;
            content: '';
            left: 0;
            top: 0;
        }

        &:after {
            left: auto;
            right: 0;
        }

        @media only screen and (max-width: 500px) {
            margin-bottom: 40px;
            margin-top: 40px;

            span  {
                height: 0;
            }

            &:before,
            &:after {
                width: 50%;
            }
        }
    }

    &__title-small {
        font-size: 16px;
        line-height: 24px;
        color: #7D89A1;
        margin-bottom: 10px;
        text-align: left;

        @media only screen and (min-width: 600px) {
            font-weight: 400;
            font-size: 26px;
            line-height: 150%;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    &__title-count {
        &:before {
            background: #292935;
            width: 66px;
            height: 64px;
            border-radius: 12px;
            transform: rotate(-15deg);
            content: '';
            position: absolute;
            z-index: 0;
            top: -16px;
            left: -12px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
        }

        &:after {
            content: '2.0';
            position: relative;
            z-index: 2;
            width: 66px;
        }

        color: $white;
        font-weight: 700;
        font-size: 28px;
        line-height: 110%;
        text-align: center;
        width: 66px;
        position: relative;
        margin-left: 23px;
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        text-align: left;

        @media only screen and (min-width: 600px) {
            font-size: 42px;
            line-height: 110%;
            text-align: center;

            br {
                display: none;
            }
        }

        @media only screen and (min-width: 768px) {
            font-size: 52px;
        }
    }

    &__info {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        margin: 60px 0 0 0;

        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }

        @media only screen and (max-width: 500px) {
            font-size: 14px;
            text-align: left;

            br {
                display: none;
            }
        }
    }

    &__landing-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 110%;
        margin: 30px 0;

        @media only screen and (max-width: 768px) {
            font-size: 26px;
        }

        @media only screen and (max-width: 500px) {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
}
</style>
