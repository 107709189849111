<template>
    <div class="mh-header">
        <div class="mh-content">
            <div class="mh-header_left-side">
                <div class="mh-header_date">Завтра</div>
                <div class="mh-header_sub-title">Бесплатный марафон</div>
                <div class="mh-header_title">«5 дней на пути к материнству»‎</div>
                <div class="mh-header_text">
                    Чувствуете, что пришло время стать мамой, но что-то
                    <br class="desktopOnly">
                    мешает? <b>Профессиональный психолог поможет!</b> Сделайте
                    <br class="desktopOnly">
                    свой путь к материнству легче и быстрее.
                </div>

                <div class="mh-header_main-info">
                    <div class="mh-header_main-info-item">
                        <div class="small">Начало:</div>
                        {{getTomorrowDate()}} - 10:00
                    </div>
                    <div class="mh-header_main-info-item">
                        <div class="small">Формат:</div>
                        Онлайн
                    </div>
                    <div class="mh-header_main-info-item">
                        <div class="small">Ведущая:</div>
                        Елена Сягло <br>
                    </div>
                </div>

                <button class="mh-button" @click="scrollToForm()">Записаться</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Header',
  data() {
    return {
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
    };
  },
  computed: {

  },
  methods: {
    scrollToForm() {
      const hiddenElement = document.querySelector('#mh-form');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    getTomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      let day = tomorrow.getDate();
      let month = tomorrow.getMonth() + 1;
      const year = tomorrow.getFullYear();

      // Добавляем ведущий ноль для дня и месяца, если они состоят из одной цифры
      if (day < 10) {
        day = `0${day}`;
      }
      if (month < 10) {
        month = `0${month}`;
      }

      const formattedDate = `${day}.${month}.${year}`;
      return formattedDate;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-header {
    background: linear-gradient(180deg, #742DDB 50%, #A35BF4 105.88%);
    padding: 115px 0;
    color: $white;

    @media only screen and (max-width: 1200px) {
        padding: 80px 0 20px 0;
    }

    @media only screen and (max-width: 768px) {
        padding: 50px 0 20px 0;
    }

    &:after {
        background-image:  url("/images/marathon/mh-header-bg-line.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 580px;
        width: 100%;
        height: 120px;
        content: '';
        z-index: 1;

        @media only screen and (max-width: 1200px) {
            top: auto;
            bottom: 130px;
        }

        @media only screen and (max-width: 768px) {
            bottom: 140px;
            background-size: contain;
        }
    }

    .mh-content {
        max-width: 1200px;
        margin: auto;
        background: url("/images/marathon/mh-header-bg.png") no-repeat 100% 0;
        background-size: contain;
        min-height: 650px;
        position: relative;
        z-index: 2;

        @media only screen and (max-width: 1200px) {
            max-width: 670px;
            background-size: 370px;
            background-position: 100% 100%;
            padding-bottom: 60px;
        }

        @media only screen and (max-width: 768px) {
            max-width: 360px;
            background-size: 220px;
            background-position: 100% 350px;
            min-height: auto;
            padding-bottom: 10px;
        }
    }

    &_left-side {
        max-width: 720px;

        button {
            @media only screen and (max-width: 1200px) {
               width: 300px;
            }

            @media only screen and (max-width: 768px) {
                width: 230px;
                margin: auto;
                display: block;
            }
        }
    }

    &_date {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 40px;
        padding: 2px 23px;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 15px;
        text-align: center;

        @media only screen and (max-width: 768px) {
            display: block;
            margin: 0 auto 15px auto;
            width: 120px;
        }
    }

    &_sub-title {
        font-weight: 600;
        font-size: 30px;
        line-height: 110%;
        margin: 20px 0;

        @media only screen and (max-width: 768px) {
            font-size: 20px;
            text-align: center;
        }
    }

    &_title {
        font-weight: 600;
        font-size: 50px;
        line-height: 110%;
        text-transform: uppercase;
        color: #F79CFF;

        @media only screen and (max-width: 768px) {
            font-size: 30px;
            text-align: center;
        }
    }

    &_text {
        font-weight: 300;
        font-size: 18px;
        line-height: 130%;
        margin: 40px 0 25px 0;

        b {
            font-weight: 600;
        }

        @media only screen and (max-width: 768px) {
           margin-top: 25px;
        }
    }

    &_main-info {
        display: flex;
        margin: 50px 0 60px 0;

        @media only screen and (max-width: 1200px) {
            flex-direction: column;
            margin-left: -10px;
            margin-top: 45px;
            margin-bottom: 7px;
        }

        @media only screen and (max-width: 768px) {
            margin-top: 21px;
            margin-left: 0;
            margin-bottom: 30px;
        }
    }

    &_main-info-item {
        width: 32%;
        border-left: 1px solid rgba(255, 255, 255, 0.28);
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        padding: 10px 30px;
        box-sizing: border-box;

        @media only screen and (max-width: 1200px) {
            margin-bottom: 18px;
            padding: 0 30px;
            width: 50%;
        }

        @media only screen and (max-width: 768px) {
            padding: 0 10px;
            width: 170px;
        }

        .small {
            font-size: 14px;
            margin-bottom: 8px;

            @media only screen and (max-width: 1200px) {
                margin-bottom: 0;
            }
        }
    }
}
</style>
