<template>
    <div class="mh-curators">
        <div class="mh-content">
            <div class="mh-title">
                <span class="white"> Ведущая марафона:</span>
            </div>
            <div class="mh-curators_list">
                <div class="mh-curators_item">
                    <div class="mh-curators_item-image">
                        <img src="/images/marathon/mh-curator-1.png">
                    </div>
                    <div class="mh-curators_item-content">
                        <div class="mh-curators_item-title purple">Елена Сягло</div>
                        <div class="mh-curators_item-subtitle">
                            10 лет в счастливом браке, мама двух детей после трех лет «бесплодия»
                        </div>
                        <div class="mh-curators_item-text">
                            <ul>
                                <li>
                                    <strong>Перинатальный психолог,</strong>
                                    энерготерапевт,
                                    специалист по психосоматике и блогер
                                    с аудиторией 160.000 в Инстаграv
                                </li>
                                <li>
                                    Провела <strong>1000+</strong>
                                    личных терапий
                                    с клиентами по запросу материнства
                                </li>
                                <li>
                                    Помогла <strong>5000+</strong>
                                    женщинам забеременеть и родить,
                                    избавиться от страхов, раскрыть потенциал
                                </li>
                                <li>
                                    Обучила <strong>250</strong>
                                    человек на курсе «Ключ к материнству»
                                </li>
                                <li>
                                    В данный момент обучает более <strong>500</strong>
                                    человек в клубе "В ожидании чуда"
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Curators',
  data() {
  },
  computed: {
  },
  methods: {
    scrollToForm() {
      const hiddenElement = document.querySelector('#mh-form');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-curators {
    background: $main-purple;
    padding: 115px 0;
    color: $white;

    @media only screen and (max-width: 768px) {
        padding: 40px 0;
    }

    .mh-content {
        max-width: 1200px;
        margin: auto;
        min-height: 650px;

        @media only screen and (max-width: 1200px) {
            max-width: 730px;
        }

        @media only screen and (max-width: 768px) {
            max-width: 360px;
            padding: 0 10px;
        }
    }

    &_list {
        margin: 150px auto 0 auto;
        max-width: 1160px;

        @media only screen and (max-width: 768px) {
            margin-top: 40px;
        }
    }

    &_item {
        display: flex;
        justify-content: space-between;
        margin: 100px 0;

        @media only screen and (max-width: 1200px) {
            margin: 80px 0;
        }

        @media only screen and (max-width: 768px) {
            margin: 40px 0;
        }

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &:last-child {
            margin-bottom: 20px;
        }

        &.inverse {
            .mh-curators_item-image {
                order: 2;
                margin-right: 0;
                margin-left: 130px;

                @media only screen and (max-width: 1200px) {
                    order: 0;
                    margin-left: 0;
                    margin-right: 60px;
                }

                @media only screen and (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
        }
    }

    &_item-content {
        @media only screen and (max-width: 1200px) {
            margin-top: -50px;
        }
    }

    &_item-image {
        margin-right: 130px;

        @media only screen and (max-width: 1200px) {
            margin-right: 60px;

            img {
                width: 220px;
            }
        }

        @media only screen and (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 30px;
        }

    }

    &_item-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 110%;
        margin-bottom: 22px;
        text-transform: uppercase;
        margin-top: 50px;

        &.purple {
            color: #F79CFF;
        }

        @media only screen and (max-width: 768px) {
           text-align: center;
        }
    }

    &_item-subtitle {
        font-weight: 500;
        font-size: 23px;
        line-height: 120%;
        margin-bottom: 45px;

        @media only screen and (max-width: 768px) {
            text-align: center;
        }
    }

    &_item-text {
        font-weight: 300;
        font-size: 18px;
        line-height: 130%;

        li {
            list-style: none;
            display: block;
            margin: 35px 0;
            position: relative;
            padding-left: 60px;

            &:before {
                background: url("/images/marathon/mh-silver-bullet.svg");
                width: 27px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -13px;
                content: '';

            }

            strong {
                font-weight: 600;
            }
        }
    }
}
</style>
