<template>
    <div class="flow">
        <div class="flow__content">
            <div class="landing-title-group">
                <div class="landing-title-group__small">КОНСУЛЬТАЦИЯ ПСИХОЛОГА</div>
                <div class="landing-title-group__mid">Как проходят консультации</div>
            </div>

            <div class="flow__list">
                <div class="flow__item">
                    <div class="flow__item-image">
                        <img src="/images/consultant-page/flow-icon-1.svg">
                    </div>
                    <div class="flow__item-text">Выявление причины, осознание</div>
                </div>
                <div class="flow__item">
                    <div class="flow__item-image">
                        <img src="/images/consultant-page/flow-icon-2.svg">
                    </div>
                    <div class="flow__item-text">
                        Поиск ресурса и проработка внутреннего кризиса
                    </div>
                </div>
                <div class="flow__item">
                    <div class="flow__item-image">
                        <img src="/images/consultant-page/flow-icon-3.svg">
                    </div>
                    <div class="flow__item-text">Освобождение от боли и страха</div>
                </div>
                <div class="flow__item">
                    <div class="flow__item-image">
                        <img src="/images/consultant-page/flow-icon-4.svg">
                    </div>
                    <div class="flow__item-text">Обретение себя и внутренней опоры</div>
                </div>
                <div class="flow__item">
                    <div class="flow__item-image">
                        <img src="/images/consultant-page/flow-icon-5.svg">
                    </div>
                    <div class="flow__item-text">Работа с симптомом через регрессию</div>
                </div>
                <div class="flow__item">
                    <div class="flow__item-image">
                        <img src="/images/consultant-page/flow-icon-6.svg">
                    </div>
                    <div class="flow__item-text">
                        Смена парадигмы мышления, через понимание законов Вселенной
                    </div>
                </div>
            </div>
            <div class="flow__border"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ConsultantLandingRequest',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/landing";

.flow {
    padding: 40px 24px 40px 24px;
    background: $white;

    .landing-title-group__mid {
        line-height: 26px;
        color: $main-blue;
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &__list {
        max-width: 475px;
        margin: 40px auto;
    }

    &__item {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }

    &__item-image {
        width: 70px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        margin-right: 5px;

        @media only screen and (min-width: 767px) {
            width: 120px;
        }

        img {
            display: block;

            @media only screen and (min-width: 767px) {
                width: 75px;
            }
        }
    }

    &__item-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        @media only screen and (min-width: 767px) {
            font-size: 17px;
            line-height: 24px;
        }
    }

    &__border {
        border-top: solid 1px $main-blue;
        width: 100px;
        margin: 50px auto 15px;
    }
}
</style>
