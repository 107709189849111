<template>
    <div class="insta-buttons">
        <a href="https://evo.elena.shop/?utm_source=elenashop-mainpage&utm_medium=button&utm_campaign=evo_motherhood" class="button gradient-red">
            КЛУБ EVO
        </a>

        <a href="https://elena.shop/thanks" class="button pink left hearts">
            сказать<br>
            спасибо елене
        </a>

        <a href="https://wa.me/905549161589" class="button blue left questions">
            задать вопрос
        </a><br><br>

        <!--<router-link to="/courseland3" class="button dark-blue">-->
            <!--КУРС «КЛЮЧ К МАТЕРИНСТВУ»-->
        <!--</router-link>-->

        <a href="https://elena.shop/consultation" class="button dark-blue">
            личная сессия
        </a>

        <a href="/meditations" class="button dark-blue">
            медитации
        </a>
        <!--<a href="#" class="button dark-blue">-->
            <!--вебинары-->
        <!--</a>-->

        <div class="insta-buttons__social-block">
            <a href="https://t.me/elenasiaglo" class="insta-buttons__soc-item"></a>
            <a href="https://www.instagram.com/elena.siaglo/" class="insta-buttons__soc-item insta"></a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'buttonsInsta',
  data() {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.insta-buttons {
    padding: 30px 0;

    .button {
        border-radius: 25px;
        background: radial-gradient(114.71% 31.85% at 49.84% 100.00%,
                rgba(243, 108, 255, 0.40) 0%,
                rgba(246, 143, 255, 0.00) 100%),
        linear-gradient(357deg, #954DEC 0%, #7831E0 100%);
        color: #FFF;
        text-align: center;
        text-shadow: 0 1.6470588445663452px 3.2941176891326904px 0 rgba(0, 0, 0, 0.20);
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
        width: 280px;
        height: 75px;
        margin: 0 auto 15px auto;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        border: solid 3px #bc91f2;
        position: relative;
        text-decoration: none;
        box-sizing: border-box;

        .small-text {
            color: #FFF;
            text-align: center;
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            text-transform: uppercase;
            margin-top: 2px;
        }

        &.pink {
            background: radial-gradient(114.71% 31.85% at 49.84% 100.00%,
                    rgba(254, 239, 255, 0.40) 0%,
                    rgba(246, 143, 255, 0.00) 100%),
            linear-gradient(357deg, #F860D6 0%, #EB29A9 100%);
            border: solid 3px #f9a3e2;
        }

        &.gradient-red {
          background: linear-gradient(357deg, #C21C5D 0%, #E85E96 100%);
          margin-bottom: 40px;
          font-family: Montserrat;
          border: solid 3px #f9a3e2;
        }

        &.hearts {
            &:after {
                background: url("/images/start-page/hearts.png") 50% 50%;
                width: 40px;
                height: 40px;
                background-size: 180px;
                position: absolute;
                right: 20px;
                top: 15px;
                content: '';
            }
        }

        &.questions {
            &:after {
                background: url("/images/start-page/questions.png") 50% 50%;
                width: 40px;
                height: 40px;
                background-size: 180px;
                position: absolute;
                right: 20px;
                top: 15px;
                content: '';
            }
        }

        &.blue {
            background: radial-gradient(114.71% 31.85% at 49.84% 100.00%,
                    rgba(54, 255, 191, 0.40) 0%,
                    rgba(45, 250, 181, 0.00) 100%),
            linear-gradient(357deg, #2CE4C7 0%, #0C94EA 100%);
            border: solid 3px #90e1eb;
        }

        &.left {
            align-items: flex-start;
            text-align: left;
        }

        &.dark-blue {
            background: #2A69EB;
            padding: 0 10px;
            text-align: center;
            border: solid 3px #4f84f2;
            background-clip: padding-box;

            &:before {
                content: '';
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                z-index: -1;
                margin: -3px; /* !importanté */
                border-radius: inherit; /* !importanté */
                background: linear-gradient(to right, red, orange);
            }
        }
    }

    &__social-block {
        display: flex;
        margin: 50px 0;
        justify-content: center;
    }

    &__soc-item {
        display: block;
        width: 60px;
        height: 60px;
        background: url("/images/start-page/telegram.svg");
        background-size: contain;
        margin: 0 20px;

        &.insta {
            background-image: url("/images/start-page/insta.svg");
        }
    }
}

</style>
