<template>
    <div class="mh-reg-form">
        <div class="mh-content" id="mh-form">
            <div class="mh-reg-form_form-conteinier">
                <div class="mh-reg-form_content">
                    <img src="/images/marathon/mh-female-memoji4.png" width="130">
                    <p>Готовы сделать правильные шаги к счастливому материнству?</p>
                    <p>Приходите на марафон — вместе поможем мечте воплотиться в реальность.</p>
                </div>
                <div class="mh-reg-form_form">
                    <!--<input type="text"-->
                           <!--placeholder="Ваше имя"-->
                           <!--class="mh-input"-->
                    <!--&gt;-->
                    <!--<input type="text"-->
                           <!--placeholder="+7 (999) 999-99-99"-->
                           <!--class="mh-input"-->
                    <!--&gt;-->
                    <!--<input type="text"-->
                           <!--placeholder="E-mail"-->
                           <!--class="mh-input"-->
                    <!--&gt;-->
                    <!--<button-->
                  <!--class="mh-button mh-button&#45;&#45;purple mh-button&#45;&#45;shadow"-->
                    <!--&gt;-->
                        <!--Зарегистрироваться-->
                    <!--</button>-->

                    <!--<div class="mh-reg-form_discleimer">-->
                        <!--Нажимая кнопку, Вы соглашаетесь с Политикой Конфиденциальности-->
                    <!--</div>-->
                    <iframe src="https://course.elena.shop/pl/lite/widget/widget?&amp;id=860422&amp;ref=&amp;loc=https%3A%2F%2Felena.shop%2FtestPayemnt.html" allowfullscreen="allowfullscreen" class="789" id="f788c098b2e44668cfeb8751f48c213d8ddf929b" name="789" style="width: 100%; height: 620px; border: none; overflow: hidden;"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Regform',
  data() {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-reg-form {
    background: linear-gradient(131deg, rgba(253,252,255,1) 0%, rgba(251,236,230,1) 100%);
    padding: 115px 0;
    color: $white;

    @media only screen and (max-width: 768px) {
        padding: 40px 10px;
    }

    &_form-conteinier {
        max-width: 1185px;
        margin: auto;
        display: flex;
        border-radius: 40px;
        background: linear-gradient(180deg, #742DDB 36.15%, #AE66FB 105.09%);
        box-shadow: 0 10px 30px rgba(135, 66, 235, 0.1);

        @media only screen and (max-width: 1200px) {
            max-width: 730px;
            flex-direction: column;
        }

        @media only screen and (max-width: 768px) {
            max-width: 360px;
        }
    }

    &_content {
        width: 50%;
        font-weight: 500;
        font-size: 28px;
        line-height: 120%;
        padding: 43px 74px 0 74px;

        @media only screen and (max-width: 1200px) {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            padding: 43px 90px 50px 90px;

            p {
                text-align: left;
            }
        }

        @media only screen and (max-width: 768px) {
           font-size: 20px;
            padding: 30px;
        }

        p {
            margin: 25px 0;
        }
    }

    &_form {
        background: $white;
        width: 50%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        padding: 40px 100px 0 100px;
        box-sizing: border-box;
        min-height: 500px;
        border-radius: 0 38px 39px 0;

        @media only screen and (max-width: 1200px) {
            width: 100%;
            box-sizing: border-box;
            border-radius: 0 0 38px 39px;
            text-align: center;

            button {
                margin: auto;
            }
        }

        @media only screen and (max-width: 768px) {
            padding: 30px;
        }

        button {
            margin-top: 20px;
            width: 300px;

            @media only screen and (max-width: 768px) {
                text-align: center;
                padding: 0;
                box-sizing: border-box;
                width: 100%;
                display: block;
            }
        }
    }

    &_discleimer {
        margin-top: 80px;
        font-weight: 400;
        font-size: 11px;
        line-height: 130%;
        color: #282828;
        white-space: nowrap;

        @media only screen and (max-width: 1200px) {
             margin-top: 50px;
            padding-bottom: 30px;
        }

        @media only screen and (max-width: 768px) {
           white-space: normal;
            padding-bottom: 10px;
        }
    }

}
</style>
