<template>
    <div class="base-cards">
        <div class="main-card-item">
            <div class="main-card-item__empty-text"
                v-if="cardsSettedCount === 0"
            >
                Для вас выбраны 5 случайных метафорических карт.
                Эти карты помогут отпределить 5 замков (проблем),
                с которыми нужно работать в первую очередь,
                чтобы исцелиться. Нажмите на кнопку,
                чтобы открыть первую карту.
            </div>
            <img :src="activeImageUrl" v-else>
        </div>
        <div class="cards-list">
            <div class="card-item"
                 v-for="(card, index) in cardList"
                 :key="index"
                 :class="{'isActive': card.isActive}"
                 @click="setActiveCard(index)"
            >
                <img :src="card.image" v-if="card.image !== ''">
                <div class="card-item__empty-image" v-else>
                    <div class="card-item__count">{{index + 1}}</div>
                    <img src="/images/cards/card-backside.jpg">
                </div>
            </div>
        </div>
        <div class="cards-actions">
            <div class="es-button"
                 v-if="cardsSettedCount < 5"
                 @click="setNextCard()"
            >
                Показать карту {{cardsSettedCount + 1}}
            </div>
            <div class="es-button red"
                 v-else
                 @click="clearResults()"
            >
                Начать заново
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BaseCards',
  data() {
    return {
      cardList: [
        {
          isActive: false,
          image: '',
        },
        {
          isActive: false,
          image: '',
        },
        {
          isActive: false,
          image: '',
        },
        {
          isActive: false,
          image: '',
        },
        {
          isActive: false,
          image: '',
        },
      ],
      alreadySettedCards: [],
    };
  },
  computed: {
    cardsSettedCount() {
      let cardIndex = 0;

      this.cardList.forEach((item, indexItem) => {
        const currentItem = this.cardList[indexItem];

        if (currentItem.image !== '') {
          cardIndex += 1;
        }
      });

      return cardIndex;
    },
    activeImageUrl() {
      let activeImageUrl = '';

      this.cardList.forEach((item) => {
        if (item.isActive) {
          activeImageUrl = item.image;
        }
      });

      return activeImageUrl;
    },
  },
  methods: {
    clearResults() {
      this.cardList.forEach((item, indexItem) => {
        const currentItem = this.cardList[indexItem];

        currentItem.image = '';
        currentItem.isActive = false;
      });
    },
    setActiveCard(index) {
      if (this.cardList[index].image === '') {
        return;
      }

      this.cardList.forEach((item, indexItem) => {
        const currentItem = this.cardList[indexItem];

        if (indexItem === index) {
          currentItem.isActive = true;
        } else {
          currentItem.isActive = false;
        }
      });
    },
    setNextCard() {
      let randomLink = '';
      let randomNumber = 0;
      let isCardUser = false;

      randomNumber = this.getRandomNumber();

      /* Проверим уникальность */
      this.alreadySettedCards.forEach((item) => {
        if (item === randomNumber) {
          isCardUser = true;
        }
      });

      if (isCardUser) {
        this.setNextCard();

        return;
      }

      this.alreadySettedCards.push(randomNumber);

      randomLink = `/images/cards/00${randomNumber}_resize.jpg`;

      this.cardList[this.cardsSettedCount].image = randomLink;
      this.setActiveCard(this.cardsSettedCount - 1);
    },
    getRandomNumber() {
      let min = 1;
      let max = 89;

      min = Math.ceil(min);
      max = Math.floor(max);

      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/forms";

.base-cards {
    padding: 10px;
}

.cards-list {
    display: flex;
    margin: 30px 0 30px 0;
    justify-content: center;
    align-items: center;
}

.main-card-item {
    width: 320px;
    margin: 0 auto;
    height: 440px;
    border: solid $lightGray 1px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: url("/images/loader.gif") no-repeat 50% 50%;
    background-size: 50px;

    &__empty-text {
        padding: 10px;
        text-align: center;
        font-size: 13px;
        line-height: 21px;
        background: $white;
    }

    img {
        max-width: 100%;
    }
}

.card-item {
    border: solid $lightGray 1px;
    border-radius: 8px;
    margin: 0 5px;
    overflow: hidden;
    background: url("/images/loader.gif") no-repeat 50% 50%;
    background-size: 20px;
    position: relative;
    height: 70px;
    width: 51px;

    &__empty-image {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    &__count {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.2);
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }

    &.isActive {
        border: solid $main-red 1px;
    }

    img {
        max-width: 100%;
        display: block;
        transition: all 200ms;
    }
}

</style>
