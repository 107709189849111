<template>
    <div class="welcome-page">
        <BaseHeaderLandingInfo v-if="isCourseInfoPage"></BaseHeaderLandingInfo>
        <Header isNotFixed="true" v-if="!isCourseInfoPage"></Header>
        <WelcomeLandingHeader v-if="!isCourseInfoPage"></WelcomeLandingHeader>
        <WelcomeLandingAbout></WelcomeLandingAbout>
        <WelcomeLandingAudience></WelcomeLandingAudience>
        <WelcomeLandingAdvantages></WelcomeLandingAdvantages>
        <WelcomeLandingReviews></WelcomeLandingReviews>
        <WelcomeLandingStructure></WelcomeLandingStructure>
        <WelcomeLandingAdvice></WelcomeLandingAdvice>
        <WelcomeLandingAuthor></WelcomeLandingAuthor>
        <WelcomeLandingHowItWorks></WelcomeLandingHowItWorks>
        <WelcomeLandingPayment v-if="!isCourseInfoPage"></WelcomeLandingPayment>
        <InviteBanner v-if="isInviteBannerVisible"></InviteBanner>
        <ConsultationBanner v-if="isInviteBannerVisible"></ConsultationBanner>
        <WelcomeLandingFAQ v-if="!isCourseInfoPage"></WelcomeLandingFAQ>
        <div class="welcome-page__back" v-if="isCourseInfoPage">
            <a @click="$router.go(-1)">Назад</a>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import BaseHeaderLandingInfo from '@/components/header/BaseHeaderLandingInfo.vue';
import Footer from '@/components/general/Footer.vue';
import WelcomeLandingHeader from '@/components/secondary-pages/welcome-landing/WelcomeLandingHeader.vue';
import WelcomeLandingAbout from '@/components/secondary-pages/welcome-landing/WelcomeLandingAbout.vue';
import WelcomeLandingAudience from '@/components/secondary-pages/welcome-landing/WelcomeLandingAudience.vue';
import WelcomeLandingAdvantages from '@/components/secondary-pages/welcome-landing/WelcomeLandingAdvantages.vue';
import WelcomeLandingReviews from '@/components/secondary-pages/welcome-landing/WelcomeLandingReviews.vue';
import WelcomeLandingStructure from '@/components/secondary-pages/welcome-landing/WelcomeLandingStructure.vue';
import WelcomeLandingAdvice from '@/components/secondary-pages/welcome-landing/WelcomeLandingAdvice.vue';
import WelcomeLandingAuthor from '@/components/secondary-pages/welcome-landing/WelcomeLandingAuthor.vue';
import WelcomeLandingHowItWorks from '@/components/secondary-pages/welcome-landing/WelcomeLandingHowItWorks.vue';
import WelcomeLandingPayment from '@/components/secondary-pages/welcome-landing/WelcomeLandingPayment.vue';
import WelcomeLandingFAQ from '@/components/secondary-pages/welcome-landing/WelcomeLandingFAQ.vue';
import InviteBanner from '@/components/general/InviteBanner.vue';
import ConsultationBanner from '@/components/general/ConsultationBanner.vue';

export default {
  name: 'BaseWelcomePage',
  components: {
    Header,
    WelcomeLandingHeader,
    WelcomeLandingAbout,
    WelcomeLandingAudience,
    WelcomeLandingAdvantages,
    WelcomeLandingReviews,
    WelcomeLandingStructure,
    WelcomeLandingAdvice,
    WelcomeLandingAuthor,
    WelcomeLandingHowItWorks,
    WelcomeLandingPayment,
    WelcomeLandingFAQ,
    Footer,
    BaseHeaderLandingInfo,
    InviteBanner,
    ConsultationBanner,
  },
  data() {
    return {
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      webinarInvite: '',
      isInviteShortCounterUsed: false,
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    isUserMovedFromStructure() {
      return this.$store.state.general.isUserMovedFromStructure;
    },
    isUserFromWebinar() {
      return this.$store.state.user.landingUserType === 'baseFromWebinar';
    },
    isCourseInfoPage() {
      return this.$route.name === 'CourseInfo';
    },
    isWelcomeWebinarPage() {
      return this.$route.name === 'WelcomeWebinar';
    },
    isInviteBannerVisible() {
      return this.$route.name === 'Home' || this.$route.name === 'CourseInfo';
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.isUserMovedFromStructure) {
        const hiddenElement = document.querySelector(`#${this.isUserMovedFromStructure}`);

        hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }

      if (this.$route.params.section) {
        const hiddenElement = document.querySelector(`#${this.$route.params.section}`);

        hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }

      this.$store.commit('setUserMovedFromStructure', false);
    }, 200);

    if (this.isCourseInfoPage) {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    isElementInViewport(element) {
      let el = element;
      let top = el.offsetTop;
      let left = el.offsetLeft;
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < (window.pageYOffset + window.innerHeight)
        && left < (window.pageXOffset + window.innerWidth)
        && (top + height) > window.pageYOffset
        && (left + width) > window.pageXOffset
      );
    },
  },
  beforeDestroy() {
    this.$store.commit('setLandingUserType', '');
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    const routeName = this.$route.name;
    let userType = 'base';

    if (routeName === 'WelcomeWebinar') {
      userType = 'baseFromWebinar';
    }

    if (routeName === 'WelcomeWebinar36') {
      userType = 'baseFromWebinar';
    }

    if (this.getUserMail !== '') {
      userType = 'baseAuthed';
    }

    this.$store.commit('setLandingUserType', userType);

    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

body {
    background: red;
}

.welcome-page {
    .base-header {
        background: $white;
    }

    .invite-banner {
        margin: 24px;
    }

    &__back {
        line-height: 70px;
        text-align: center;

        a {
            font-size: 14px;
            color: $main-blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
