<template>
    <div class="cart-cover">
        <div class="cart"
             @click="showPaymentForm()"
             v-if="cartList.length"
        >
            {{cartList.length}}
        </div>
    </div>
</template>

<script>

export default {
  name: 'BaseCart',
  components: {},
  computed: {
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
  },
  methods: {
    showPaymentForm() {
      this.$store.commit('showPayment');

      this.cartList.forEach((id) => {
        this.$store.commit('markMeditationForPayByID', { id });
      });

      this.$store.commit('setCartMeditationPagePayment', true);
      this.$store.commit('setLandingPaymentFullPrice', this.infoForMeditationsInCart.price);
      this.$store.commit('setLandingPaymentPriceToPay', this.infoForMeditationsInCart.priceWithSale);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
@import "../../assets/scss/common/variables";

.cart {
    position: fixed;
    bottom: 52px;
    right: 20px;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    z-index: 40;
    background: url("/images/cart-icon-white.svg") 50% 70% no-repeat #f3444e;
    background-size: 17px;
    color: $white;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 6px;
    font-weight: 500;

    @media only screen and (max-width: 374px) {
        bottom: 110px;
    }

    @media only screen and (min-width: 768px) {
        right: auto;
        left: 50%;
        margin-left: 321px;
    }
}

</style>
