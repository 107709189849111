<template>
  <div class="landing-page">
    <BaseClubMarathon></BaseClubMarathon>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseClubMarathon from '@/components/secondary-pages/BaseClubMarathon.vue';

export default {
  name: 'ClubMarathonPage',
  components: {
    BaseClubMarathon,
  },
};
</script>
