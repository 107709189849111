<template>
    <div class="wlm-payment">
        <BasePayment></BasePayment>
        <BaseAudioPlayerBlue
                v-if="isPlayerVisible"
        ></BaseAudioPlayerBlue>

        <div class="wlm-payment__packages">
            <SinglePaymentCartItem
                    v-for="(groupData, index) in fullMeditationInfo"
                    :data="groupData"
                    :key="index"
                    :dataKey="index"
            ></SinglePaymentCartItem>
        </div>
        <BaseCart></BaseCart>

        <ConsultationBanner></ConsultationBanner>
    </div>
</template>

<script>
import BasePayment from '@/components/payment/BasePayment.vue';
import BaseCart from '@/components/general/BaseCart.vue';
import ConsultationBanner from '@/components/general/ConsultationBanner.vue';
import SinglePaymentCartItem from '@/components/secondary-pages/welcome-landing/SinglePaymentCartItem.vue';
import BaseAudioPlayerBlue from '@/components/meditation/BaseAudioPlayerBlue.vue';

export default {
  name: 'ModulePayment',
  components: {
    BasePayment,
    SinglePaymentCartItem,
    BaseCart,
    BaseAudioPlayerBlue,
    ConsultationBanner,
  },
  data() {
    return {
      complete: false,
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      priceBase: 17700,
      priceBaseSale: 17700,
      pricePremium: 32700,
      pricePremiumSale: 32700,
    };
  },
  computed: {
    isPlayerVisible() {
      return this.$store.state.player.isVisible;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    isSingleMeditation() {
      return this.$route.name === 'Finalprice';
    },
    isNewMeditationLanding() {
      return this.$route.name === 'New';
    },
    fullMeditationInfo() {
      const data = this.$store.state.meditations.meditationsList;

      /* Если это тсраница медитаций, берем только медитации, вебинары исключаем */
      if (this.isSingleMeditation) {
        const sortData = [];

        sortData.push(data[0]);
        sortData.push(data[1]);
        sortData.push(data[2]);

        return sortData;
      }

      return data;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isAuthed() {
      return this.$store.getters.getUserMail !== '';
    },
  },
  methods: {
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.wlm-payment {
    padding: 30px 24px 15px 24px;
    position: relative;
    background: $white;

    &.smallPadding {
        padding-top: 10px;
    }

    @media only screen and (max-width: 767px) {
        padding: 10px 13px 20px 13px;
    }

    #paymentSectionScrollMarker {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__start-block {
        text-align: center;
        background: $main-blue;
        padding: 20px 15px 15px 15px;
        border-radius: 10px;
        color: $white;
    }

    &__reload-descr {
        font-size: 17px;
        line-height: 28px;
        color: $darkGray;
        margin: 15px auto 30px auto;

        @media only screen and (max-width: 767px) {
            font-size: 13px;
            line-height: 21px;
        }

        img {
            display: inline-block;
            width: 18px;
            margin-left: 8px;
            position: relative;
            top: 4px;

            @media only screen and (max-width: 767px) {
                width: 16px;
            }
        }

        strong {
            font-weight: 600;
        }
    }

    &__buy-more {
        font-size: 17px;
        line-height: 22px;
        max-width: 600px;
        margin: auto;

        @media only screen and (max-width: 767px) {
            font-size: 19px;
            line-height: 24px;
        }

        strong {
            font-weight: 600;
            color: #eee61e;
        }
    }

    &__packages {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
}
</style>
