<template>
    <div class="base-payment" :class="{'opened': isVisible}">
        <div class="base-payment__cover" @click="hidePayment"></div>
        <div class="base-payment__content">
            <div class="base-payment__hide-handler" @click="hidePayment"></div>
            <div class="base-payment__content-scroll">
                <div class="base-payment__title">{{mainTitle}}</div>
                <AdvantagesListFlowWebinar
                        :hasEmail="isUserDataForm"
                        v-if="!isConsultantPaymentPage"
                ></AdvantagesListFlowWebinar>

                <form class="base-payment__user-data"
                      v-if="isUserDataForm"
                      @submit.prevent="getUserData()"
                >
                    <template v-if="isTelegramPracticePage">
                        <div class="es-input-cover">
                            <input class="es-input"
                                   placeholder="Ваш номер телефона"
                                   v-model="userPhone"
                                   type="number"
                                   required
                            >
                        </div>
                        <div class="es-input-cover">
                            <input class="es-input"
                                   placeholder="Ваш телеграм"
                                   v-model="userTelegram"
                                   type="text"
                                   required
                            >
                        </div>
                    </template>
                    <template v-else>
                        <div class="es-input-cover">
                            <input class="es-input"
                                   placeholder="Ваше имя"
                                   v-model="userName"
                                   type="text"
                                   required
                            >
                        </div>
                        <div class="es-input-cover">
                            <input class="es-input"
                                   placeholder="Ваш email"
                                   v-model="userEmail"
                                   type="email"
                                   required
                            >
                        </div>
                    </template>

                    <button type="submit" class="es-button">Перейти к оплате</button>
                </form>

                <template v-else>
                    <PriceBlockLanding v-if="isVisible"></PriceBlockLanding>
                    <SelectPaymentType v-if="!isStripePayVisible"></SelectPaymentType>

                    <CardForm v-if="isVisible && isStripePayVisible"></CardForm>

                    <div class="base-payment__get-troubles">
                        <div class="base-payment__error"
                             v-if="cardErrorText !== ''"
                        >
                            {{cardErrorText}}
                        </div>
                        <div class="base-payment__get-troubles-text">
                            Не получается оплатить?
                        </div>
                        <div class="base-payment__get-troubles-descr">
                            Опишите проблему в директ инстаграмм @elena.siaglo
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <YaCard></YaCard>
    </div>
</template>

<script>
import CardForm from './CardForm.vue';
import PriceBlockLanding from './PriceBlockLanding.vue';
import AdvantagesListFlowWebinar from './AdvantagesListFlowWebinar.vue';
import YaCard from './YaCard.vue';
import SelectPaymentType from './SelectPaymentType.vue';

export default {
  name: 'BasePayment',
  components: {
    CardForm,
    YaCard,
    PriceBlockLanding,
    AdvantagesListFlowWebinar,
    SelectPaymentType,
  },
  props: {
    mainTitle: String,
  },
  data() {
    return {
      complete: false,
      fullText: 'Получите все товары курса по скидке',
      fullTextNotPayed: 'Получите недостающие товары курса по скидке',
      singleText: 'Получите этот товар без скидки',
      isUserAuthed: this.$store.getters.getUserMail !== '',
      isPromoEnabled: false,
      userName: '',
      lastName: '',
      userEmail: '',
      userPhone: '',
      userTelegram: '',
      isUserDataForm: true,
    };
  },
  computed: {
    getStripeKey() {
      return 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
    },
    isVisible() {
      return this.$store.state.payment.isOpened;
    },
    isFullPackageSelected() {
      return this.$store.state.payment.isFullPackageSelected;
    },
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    isStripePayVisible() {
      return this.$store.state.payment.isStripePayVisible;
    },
    cardErrorText() {
      return this.$store.state.payment.cardErrorText;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isLandingPageCoursePayment() {
      return this.$store.getters.isLandingPageCoursePayment;
    },
    isSinglePagePayment() {
      return this.$store.state.payment.isSinglePagePayment;
    },
    isConsultationPayment() {
      return this.$store.state.payment.isConsultationPayment;
    },
    isModulePagePayment() {
      return this.$store.state.payment.isModulePagePayment;
    },
    isModulePaymentFull() {
      return this.$store.state.payment.isModulePaymentFull;
    },
    landingPaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
    landingPaymentFullPrice() {
      return this.$store.state.payment.landingPaymentFullPrice;
    },
    landingPaymentPriceToPay() {
      return this.$store.state.payment.landingPaymentPriceToPay;
    },
    isSaleEnabled() {
      return this.landingPaymentFullPrice > this.landingPaymentPriceToPay;
    },
    isTelegramPracticePage() {
      return this.$route.name === 'TelegramMeeting';
    },
    isConsultantPaymentPage() {
      return this.$route.name === 'ConsultatantPayment';
    },
  },
  mounted() {
    this.setCustomVh();

    if (this.isConsultantPaymentPage) {
      this.isUserDataForm = false;
    }
  },
  methods: {
    getUserData() {
      this.isUserDataForm = false;
      this.$store.commit('setWebinarPaymentData', {
        userName: this.userName,
        userPhone: this.userPhone,
        userTelegram: this.userTelegram,
        lastName: this.lastName,
        userEmail: this.userEmail,
      });
    },
    setCustomVh() {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      document.documentElement.style.setProperty('--custom-vh', `${vh}px`);
    },
    hidePayment() {
      this.$store.dispatch('hidePayment');
      this.$store.commit('clearAllPaymentFlags', false);
    },
    showYaPay() {
      this.$store.commit('showYaPay');
    },
    cabinetUrl() {
      let url;

      if (this.getUserMail === '') {
        url = '/auth';
      } else {
        url = '/my';
      }

      return url;
    },
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.base-payment {
    &.opened {
        .base-payment__cover {
            opacity: 1;
            transition: opacity 200ms;
            visibility: visible;
        }

        .base-payment__content {
            transform: translate(0);
        }
    }
    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 20px;
    }
    &__hide-handler {
        background: url("/images/hide-cover-handler.svg") 50% 50% no-repeat;
        width: 100%;
        height: 40px;
    }
    &__cover {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($black, 0.78);
        z-index: 15;
        opacity: 0;
        visibility: hidden;
    }

    &__content {
        background: $light-blue;
        border-radius: 20px 20px 0 0;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px 0 24px;
        position: fixed;
        z-index: 43;
        transition: transform 200ms;
        transform: translate(0, 100%);
        max-width: 767px;

        /* ipad */
        @media only screen and (min-width: 768px) {
            left: calc(50% - 384px);
        }
    }

    &__content-scroll {
        margin: 0 -20px;
        padding: 0 20px 24px 20px;
        max-height: calc(var(--custom-vh) - 100px);
        overflow-y: auto;
        overflow-x: hidden;

        @media only screen and (min-width: 768px) {
            max-width: 462px;
            margin: auto;
        }

      /*iphone 6 и другие с хорошой шириной экрана но высотой меньше чем у iphone 11*/
      @media only screen and (max-height: 700px) and (min-width: 374px) {
        max-height: calc(var(--custom-vh) - 80px);
      }

      /*iphone 5*/
        @media only screen and (max-width: 374px) {
            max-height: calc(var(--custom-vh) - 70px);
        }
    }

    &__disclamer {
        font-size: 10px;
        text-align: center;
        line-height: 17px;

        a {
            color: $black;
        }
    }

    &__get-troubles {
        line-height: 20px;
        margin: 16px -24px;
        padding: 15px 24px;
        background: rgba($main-blue, 0.05);
        text-align: center;
        font-size: 12px;

        &:empty {
            padding: 0;
            margin-bottom: 20px;
        }
    }

    &__get-troubles-text {
        font-weight: 600;
        text-decoration: underline;
        color: $black;
    }

    &__error {
        color: $main-red-bright;
        text-decoration: none;
        margin-bottom: 5px;

        &:before {
            background: url(/images/error-icon.svg) no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            vertical-align: top;
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}
</style>
