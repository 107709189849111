<template>
  <div class="cards-page">
    <BaseCards3></BaseCards3>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseCards3 from '@/components/cards/BaseCards3.vue';

export default {
  name: 'CardsPage',
  components: {
    BaseCards3,
  },
};
</script>
