<template>
    <div class="join-url">
      <form @submit.prevent="createWebinarLink()">
        <div class="es-input-cover">
          <input type="text"
                 required
                 v-model="webinarID"
                 placeholder="Id вебинара"
                 class="es-input"
          >
        </div>

        <div class="es-input-cover">
          <input type="text"
                 required
                 v-model="name"
                 placeholder="Имя пользователя"
                 class="es-input"
          >
        </div>

        <div class="es-input-cover">
          <input type="email"
                 required
                 v-model="mail"
                 placeholder="Почта"
                 class="es-input"
          >
        </div>

        <button type="submit" class="es-button" :disabled="isLoading">
          Получить ссылку
        </button>
      </form>

      <div class="join-url__show-links" v-if="joinUrl !== ''">
        <div class="join-url__result-title">Ссылка для пользователя {{name}}:</div>
        {{joinUrl}}

        <button
                @click="copyContent(joinUrl)"
                class="es-button es-button--bordered"
        >
          Скопировать ссылку
        </button>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import copy from 'clipboard-copy';

export default {
  name: 'JoinUrl',
  components: {},
  data() {
    return {
      webinarID: this.$store.getters.flowWebinarZoomID,
      name: '',
      mail: '',
      joinUrl: '',
      isLoading: false,
    };
  },
  computed: {
    adminToken() {
      return this.$store.state.user.adminToken;
    },
  },
  methods: {
    createWebinarLink() {
      const currentUrl = this.$store.getters.getLocalUrl;

      this.isLoading = true;

      axios
        .post(`${currentUrl}/v2/api/createWebinarLink.php`, {
          adminToken: this.adminToken,
          name: this.name,
          mail: this.mail,
          webinarID: this.webinarID,
        })
        .then((response) => {
          this.isLoading = false;
          if (response.data.success) {
            this.joinUrl = response.data.result.join_url;
          }
        });
    },
    copyContent(content) {
      const message = 'Ссылка скопирована';

      copy(content).then(() => {
        this.$store.commit('showGlobalMessage', message);
      });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.join-url {
  &__result-title {
    font-weight: 600;
    margin: 15px 0 15px 0;
  }

  &__show-links {
    margin: 30px 0 80px 0;
    font-size: 14px;
    line-height: 21px;
    word-break: break-all;
  }
}
</style>
