<template>
    <div class="mh-footer">
        <div class="mh-content">
            © Елена Сягло. 2023
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-Footer',
  data() {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

.mh-footer {
    background: #1D1D1D;
    text-align: center;
    padding: 25px 0;
    line-height: 24px;
    color: #9B9B9B;
}
</style>
