<template>
    <div class="request">
        <div class="request__top-circle"></div>
        <div class="request__content">
            <div class="landing-title-group">
                <div class="landing-title-group__small">КОНСУЛЬТАЦИЯ ПСИХОЛОГА</div>
                <div class="landing-title-group__mid">С каким запросом<br> можно обратиться</div>
            </div>

            <div class="request__list">
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-1.png">
                        <div class="request__item-image-text">Бесплодие</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-2.png">
                        <div class="request__item-image-text">Перинатальные<br> потери</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-3.png">
                        <div class="request__item-image-text">Работа с<br> беременными</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-4.png">
                        <div class="request__item-image-text">Послеродовая <br> депрессия</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-5.png">
                        <div class="request__item-image-text">Страхи</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-6.png">
                        <div class="request__item-image-text">Болезни и<br> симптомы</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-7.png">
                        <div class="request__item-image-text">Отношения с<br> партнером</div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-8.png">
                        <div class="request__item-image-text">
                            Отношения с<br> родителями и<br> детьми
                        </div>
                    </div>
                </div>
                <div class="request__item">
                    <div class="request__item-image">
                        <img src="/images/consultant-page/cloud-9.png">
                        <div class="request__item-image-text">Финансы и<br> карьера</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="request__bottom-circle"></div>
    </div>
</template>

<script>
export default {
  name: 'ConsultantLandingRequest',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/landing";

.request {
    padding: 1px 24px 1px 24px;
    background: $main-blue;
    color: $white;
    margin-top: 50px;
    margin-bottom: 50px;

    .landing-title-group {
        margin-top: 4px;
    }
    .landing-title-group__mid {
        line-height: 26px;
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &__top-circle {
        height: 50px;
        overflow: hidden;
        margin: -20px -24px 0 -24px;
        display: flex;
        justify-content: center;
        margin-top: -50px;

        &:before {
            width: 150%;
            height: 100vw;
            background: $main-blue;
            content: '';
            display: block;
            border-radius: 50%;
            flex-shrink: 0;

            @media only screen and (min-width: 767px) {
                width: 1850px;
            }
        }
    }

    &__bottom-circle {
        height: 50px;
        overflow: hidden;
        margin: -20px -24px 0 -24px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: -50px;

        &:before {
            width: 150%;
            height: 100vw;
            background: $main-blue;
            content: '';
            display: block;
            border-radius: 50%;
            flex-shrink: 0;

            @media only screen and (min-width: 767px) {
                width: 1850px;
            }
        }
    }

    &__list {
        max-width: 425px;
        margin: 85px auto 20px auto;
    }

    &__item {
        &:nth-child(odd) {
            text-align: right;
        }
        &:nth-child(1) {
            img {
                width: 166px;

                @media only screen and (min-width: 425px) {
                    width: 255px;
                }
            }
        }
        &:nth-child(2) {
            img {
                width: 228px;

                @media only screen and (min-width: 425px) {
                    width: 351px;
                }
            }
        }
        &:nth-child(3) {
            margin-right: 20px;

            img {
                width: 158px;

                @media only screen and (min-width: 425px) {
                    width: 243px;
                }
            }
        }
        &:nth-child(4) {
            img {
                width: 188px;

                @media only screen and (min-width: 425px) {
                    width: 289px;
                }
            }
        }
        &:nth-child(5) {
            margin-right: 30px;

            img {
                width: 137px;

                @media only screen and (min-width: 425px) {
                    width: 211px;
                }
            }
        }
        &:nth-child(6) {
            img {
                width: 182px;

                @media only screen and (min-width: 425px) {
                    width: 280px;
                }
            }
        }
        &:nth-child(7) {
            margin-right: 25px;

            img {
                width: 154px;

                @media only screen and (min-width: 425px) {
                    width: 237px;
                }
            }
        }
        &:nth-child(8) {
            img {
                width: 206px;

                @media only screen and (min-width: 425px) {
                    width: 317px;
                }
            }
        }
        &:nth-child(9) {
            margin-right: 20px;

            img {
                width: 154px;

                @media only screen and (min-width: 425px) {
                    width: 237px;
                }
            }
        }

        @media only screen and (min-width: 425px) {
            margin-right: 0!important;
        }
    }

    &__item-image {
        display: inline-block;
        position: relative;

        img {
            display: block;
            margin-top: -40px;
        }
    }

    &__item-image-text {
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        top: -25px;
        color: $main-blue;

        @media only screen and (min-width: 425px) {
            font-size: 13px;
        }
    }
}
</style>
