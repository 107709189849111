<template>
    <div class="base-payment" :class="{'opened': isVisible}">
        <div class="base-payment__cover" @click="hidePayment"></div>
        <div class="base-payment__content">
            <div class="base-payment__hide-handler" @click="hidePayment"></div>
            <div class="base-payment__content-scroll">
                <div class="base-payment__title" v-html="getTitleText"></div>
                <CartProductsList></CartProductsList>

                <iframe :src="getUrl"
                        allowfullscreen="allowfullscreen"
                        class="767"
                        id="2d10cce45087217fdc62498d4cf4890860727e60_767"
                        name="767"
                        style="width: 100%; height: 560px; border: none; overflow: hidden;"
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import CartProductsList from './CartProductsListGetCourse.vue';

export default {
  name: 'BasePaymentGetCourse',
  components: {
    CartProductsList,
  },
  data() {
    return {
      complete: false,
      fullText: 'Получите все товары курса по скидке',
      fullTextNotPayed: 'Получите недостающие товары курса по скидке',
      singleText: 'Получите этот товар без скидки',
      isUserAuthed: this.$store.getters.getUserMail !== '',
      isIframeReady: false,
    };
  },
  computed: {
    isVisible() {
      return this.$store.state.payment.isOpenedGetCourse;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isCartMeditationPagePayment() {
      return this.$store.state.payment.isCartMeditationPagePayment;
    },
    getUrl() {
      if (this.infoForMeditationsInCart.list.length) {
        return `https://course.elena.shop/pl/lite/widget/widget?id=${this.infoForMeditationsInCart.list[0].getCourseIframeID}`;
      }
      return '';
    },
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
    getTitleText() {
      const finalTitle = 'Оформление заказа';

      return finalTitle;
    },
  },
  mounted() {
    this.setCustomVh();
    setTimeout(() => {
      this.isIframeReady = true;
    }, 800);
  },
  methods: {
    setCustomVh() {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      document.documentElement.style.setProperty('--custom-vh', `${vh}px`);
    },
    hidePayment() {
      this.$store.dispatch('hidePayment');
      this.$store.commit('clearAllPaymentFlags', false);
    },
    showYaPay() {
      this.$store.commit('showYaPay');
    },
    cabinetUrl() {
      let url;

      if (this.getUserMail === '') {
        url = '/auth';
      } else {
        url = '/my';
      }

      return url;
    },
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";

.base-payment {
    &.opened {
        .base-payment__cover {
            opacity: 1;
            transition: opacity 200ms;
            visibility: visible;
        }

        .base-payment__content {
            transform: translate(0);
        }
    }
    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 20px;
    }
    &__hide-handler {
        background: url("/images/hide-cover-handler.svg") 50% 50% no-repeat;
        width: 100%;
        height: 40px;
    }
    &__cover {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($black, 0.78);
        z-index: 41;
        opacity: 0;
        visibility: hidden;
    }

    &__content {
        background: $light-blue;
        border-radius: 20px 20px 0 0;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px 0 24px;
        position: fixed;
        z-index: 43;
        transition: transform 200ms;
        transform: translate(0, 100%);
        max-width: 767px;

        /* ipad */
        @media only screen and (min-width: 768px) {
            left: calc(50% - 384px);
        }
    }

    &__content-scroll {
        margin: 0 -20px;
        padding: 0 20px 24px 20px;
        max-height: calc(var(--custom-vh) - 100px);
        overflow-y: auto;
        overflow-x: hidden;

        @media only screen and (min-width: 768px) {
            max-width: 462px;
            margin: auto;
        }

      /*iphone 6 и другие с хорошой шириной экрана но высотой меньше чем у iphone 11*/
      @media only screen and (max-height: 700px) and (min-width: 374px) {
        max-height: calc(var(--custom-vh) - 80px);
      }

      /*iphone 5*/
        @media only screen and (max-width: 374px) {
            max-height: calc(var(--custom-vh) - 70px);
        }
    }

    &__disclamer {
        font-size: 10px;
        text-align: center;
        line-height: 17px;

        a {
            color: $black;
        }
    }

    &__get-troubles {
        line-height: 20px;
        margin: 16px -24px;
        padding: 15px 24px;
        background: rgba($main-blue, 0.05);
        text-align: center;
        font-size: 12px;

        &:empty {
            padding: 0;
            margin-bottom: 20px;
        }
    }

    &__get-troubles-text {
        font-weight: 600;
        color: $black;
        font-size: 14px;
        margin-bottom: 5px;
    }

    &__get-troubles-descr {
        font-size: 11px;
        color: $black;
    }

    &__error {
        color: $main-red-bright;
        text-decoration: none;
        margin-bottom: 5px;

        &:before {
            background: url(/images/error-icon.svg) no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            vertical-align: top;
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}
</style>
