<template>
    <div class="format-item__list">
        <div class="format-item">
            <div class="format-item__inc">
                <div class="format-item__image">
                    <img src="/images/course-landing/choose-format-key1.png">
                </div>
                <div class="format-item__uptitle">
                    Тариф
                </div>
                <div class="format-item__title noBorder">
                    Cеребряный ключик
                </div>
                <div class="format-item__title-after ">
                    *быстро
                </div>

                <div class="format-item__content-list">
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/6-1.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяц</b> участия
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/6-2.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>модуля</b> с уроками
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/6-3.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>уроков</b> в записи на платформе Геткурс
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/6-4.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>техник</b> и практик для проработки своих запросов
                        </div>
                    </div>
                    <div class="format-item__content-list-item minus">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-5.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> доступа в чат для общения участниц
                        </div>
                    </div>
                    <div class="format-item__content-list-item minus">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-6.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> поддержки куратора и перинатального психолога в чате
                        </div>
                    </div>
                    <div class="format-item__content-list-item minus">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-7.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых эфиров</b> в Zoom с автором
                            курса Еленой Сягло с ответами на вопросы
                        </div>
                    </div>
                    <div class="format-item__content-list-item minus">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-8.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых практик</b> в Zoom c куратором
                            для проработки своих запросов
                        </div>
                    </div>
                </div>

                <div class="format-item__price-block">
                    <div class="format-item__price-title">Купить в  рассрочку от</div>
                    <div class="format-item__price-current">
                        1 991  ₽ <span class="small">/мес</span>
                    </div>
                    <div class="format-item__price-title">Или внести предоплату</div>
                    <div class="format-item__price-current">3 000 ₽</div>
                    <div class="format-item__price-title">Или внести сразу всю сумму</div>
                    <span class="format-item__price-current">23 900 ₽</span>
                    <span class="format-item__price-old">29 900 ₽</span>
                </div>
                <button
                        class="es-button black"
                        @click="showPaymentFormSilver()"
                >
                    Продолжить
                </button>
                <div class="format-item__discleimer">
                    *переходите по кнопке, чтобы выбрать способ оплаты
                </div>
            </div>
        </div>
        <div class="format-item">
            <div class="format-item__inc dark">
                <div class="format-item__image">
                    <img src="/images/course-landing/choose-format-key2.png">
                </div>
                <div class="format-item__uptitle">
                    Тариф
                </div>
                <div class="format-item__title ">
                    Золотой ключик
                </div>
                <div class="format-item__title-after ">
                    *эффективно
                </div>

                <div class="format-item__plus-content-list handler">
                    Условия тарифа<br>
                    “Серебряный ключик”
                </div>

                <div class="format-item__content-list mobileHidden">
                    <div class="format-item__content-list-item no-mobile-radius">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/5-1.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> участия
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-2.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>модуля</b> с уроками
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image x-large">
                            <img src="/images/course-landing/format-images-3/4-3.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>уроков</b> в записи на платформе Геткурс
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-4.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>техник</b> и практик для проработки своих запросов
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-5.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> доступа в чат для общения участниц
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-6.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> поддержки куратора и перинатального психолога в чате
                        </div>
                    </div>
                    <div class="format-item__content-list-item minus">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/8-4.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых эфиров</b> в Zoom с автором
                            курса Еленой Сягло с ответами на вопросы
                        </div>
                    </div>
                    <div class="format-item__content-list-item minus">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-8.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых практик</b> в Zoom c куратором
                            для проработки своих запросов
                        </div>
                    </div>
                </div>

                <div class="format-item__plus-content-list">+</div>

                <div class="format-item__content-list dark additionalList">
                    <div class="format-item__content-list-item no-mobile-radius">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/7-1.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяц</b> участия
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/7-2.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>модуль</b> с уроками
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/7-3.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>уроков</b> в записи на платформе Геткурс
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/7-4.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>техник</b> и практик для проработки своих запросов
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/7-5.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> доступа в чат для общения участниц
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/7-6.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> поддержки куратора и перинатального психолога в чате
                        </div>
                    </div>
                    <div class="format-item__content-list-item transparent">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-7.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых эфиров</b> в Zoom с автором
                            курса Еленой Сягло с ответами на вопросы
                        </div>
                    </div>
                    <div class="format-item__content-list-item transparent">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-8.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых практик</b> в Zoom c куратором
                            для проработки своих запросов
                        </div>
                    </div>
                </div>

                <div class="format-item__price-block">
                    <div class="format-item__price-title">Купить в  рассрочку от</div>
                    <div class="format-item__price-current">
                        2 491  ₽ <span class="small">/мес</span>
                    </div>
                    <div class="format-item__price-title">Или внести предоплату</div>
                    <div class="format-item__price-current">3 000 ₽</div>
                    <div class="format-item__price-title">Или внести сразу всю сумму</div>
                    <span class="format-item__price-current">29 900 ₽</span>
                    <span class="format-item__price-old">39 900 ₽</span>
                </div>
                <button
                        class="es-button black"
                        @click="showPaymentFormGold()"
                >
                    Продолжить
                </button>
                <div class="format-item__discleimer">
                    *переходите по кнопке, чтобы выбрать способ оплаты
                </div>
            </div>
        </div>
        <div class="format-item">
            <div class="format-item__inc">
                <div class="format-item__image">
                    <img src="/images/course-landing/choose-format-key3.png">
                </div>
                <div class="format-item__uptitle">
                    Тариф
                </div>
                <div class="format-item__title noBorder">
                    Ключик с бриллиантом
                </div>
                <div class="format-item__title-after ">
                    *всё включено
                </div>

                <div class="format-item__plus-content-list handler">
                    Условия тарифа<br>
                    “Золотой ключик”
                </div>

                <div class="format-item__content-list  mobileHidden">
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-1.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> участия
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/4-2.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>модуля</b> с уроками
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image x-large">
                            <img src="/images/course-landing/format-images-3/4-3.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>уроков</b> в записи на платформе Геткурс
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-4.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>техник</b> и практик для проработки своих запросов
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/9-5.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b>  доступа в чат для общения участниц
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/9-6.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> поддержки куратора и перинатального психолога в чате
                        </div>
                    </div>
                    <div class="format-item__content-list-item ">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-7.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых эфиров</b> в Zoom с автором
                            курса Еленой Сягло с ответами на вопросы
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/4-8.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых практик</b> в Zoom c куратором
                            для проработки своих запросов
                        </div>
                    </div>
                </div>

                <div class="format-item__plus-content-list">+</div>

                <div class="format-item__content-list dark additionalList">
                    <div class="format-item__content-list-item no-mobile-radius">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/8-1.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяцa</b> участия
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/8-2.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b>  доступа в чат для общения участниц
                        </div>
                    </div>

                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-3/8-3.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>месяца</b> поддержки куратора и перинатального психолога в чате
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/8-4.png">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых эфиров</b> в Zoom с автором
                            курса Еленой Сягло с ответами на вопросы
                        </div>
                    </div>
                    <div class="format-item__content-list-item ">
                        <div class="format-item__content-image large">
                            <img src="/images/course-landing/format-images-3/8-5.svg">
                        </div>
                        <div class="format-item__content-text">
                            <b>живых практик</b> в Zoom c куратором
                            для проработки своих запросов
                        </div>
                    </div>
                </div>

                <div class="format-item__price-block">
                    <div class="format-item__price-title">Купить в  рассрочку от</div>
                    <div class="format-item__price-current">
                        3 325  ₽ <span class="small">/мес</span>
                    </div>
                    <div class="format-item__price-title">Или внести предоплату</div>
                    <div class="format-item__price-current">3 000 ₽</div>
                    <div class="format-item__price-title">Или внести сразу всю сумму</div>
                    <span class="format-item__price-current">39 900 ₽</span>
                    <span class="format-item__price-old">59 900 ₽</span>
                </div>
                <button
                        class="es-button black"
                        @click="showPaymentFormDiamond()"
                >
                    Продолжить
                </button>
                <div class="format-item__discleimer">
                    *переходите по кнопке, чтобы выбрать способ оплаты
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseFormatItem',
  data() {
    return {
      isGoldTermsHidden: true,
      isDiamondTermsHidden: true,
      isDiamondAdvHidden: false,
      isGoldAdvHidden: false,
    };
  },
  computed: {
    isShortPage() {
      return this.$route.name === 'Courseland20Short';
    },
  },
  mounted() {
    if (window.innerWidth > 767) {
      this.isGoldTermsHidden = false;
      this.isDiamondTermsHidden = false;
    }
  },
  methods: {
    showPaymentFormSilver() {
      this.$store.commit('setCurrentTemplate', 'get-course-form-silver');
      this.$store.commit('showDialog');
    },
    showPaymentFormGold() {
      this.$store.commit('setCurrentTemplate', 'get-course-form-gold');
      this.$store.commit('showDialog');
    },
    showPaymentFormDiamond() {
      this.$store.commit('setCurrentTemplate', 'get-course-form-diamond');
      this.$store.commit('showDialog');
    },
    showGoldAdvatages() {
      this.isGoldTermsHidden = false;
    },
    showDiamondAdvatages() {
      this.isDiamondTermsHidden = false;
    },
    showGoldFullAdv() {
      this.isGoldAdvHidden = !this.isGoldAdvHidden;
    },
    showDiamondFullAdv() {
      this.isDiamondAdvHidden = !this.isDiamondAdvHidden;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.format-item {
    background: rgba(255,255,255,0.4);
    box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
    border-radius: 20px;
    padding: 8px;
    font-family: $titleFont;
    margin-bottom: 40px;

    @media only screen and (min-width: 767px) {
        margin-right: 15px;
        flex-shrink: 0;
        width: 280px;
    }

    &__list {
        @media only screen and (min-width: 767px) {
            display: flex;
            overflow-x: auto;
            justify-content: center;

        }

        @media only screen and (min-width: 1024px) {
            position: relative;
            left: 30px;
        }
    }

    &:last-child {
        margin-bottom: 25px;

        @media only screen and (min-width: 767px) {
           margin-bottom: 40px;
        }
    }

    &:nth-child(2) {
        .format-item__image img {
            margin-right: -59px;
            margin-bottom: 12px;
        }
    }

    &__inc {
        background: #FFFFFF;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 20px;
        padding: 13px;
        min-height: calc(100% - 26px);

        &.dark {
            background: #E4E8F5;

            .format-item__content-title {
                color: $landingBlue;
            }

            .format-item__price-old {
                color: #7D89A1;
            }
        }
    }

    &__image {
        text-align: center;

        @media only screen and (min-width: 767px) {
            display: flex;
            height: 161px;
            align-items: center;
            justify-content: center;
        }

        img {
            margin-right: -45px;
            max-width: 164px;
        }
    }

    &__uptitle {
        width: 66px;
        height: 28px;
        background: $landingBlue;
        border-radius: 15px;
        margin: 0 auto;
        line-height: 28px;
        font-weight: 400;
        font-size: 12px;
        color: $white;
        text-align: center;
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        position: relative;
        /*padding-bottom: 25px;*/
        margin: 3px 0 8px 0;
        text-align: center;

        &-after {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.02em;
            text-align: center;
            margin-bottom: 25px;
        }

        @media only screen and (min-width: 767px) {
            white-space: nowrap;
            /*margin-bottom: 30px;*/
        }

        &.noBorder {
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
    }

    &__content-title {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $landingGray;
    }

    &__modules-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        display: flex;
        margin-bottom: 18px;
        align-items: center;
    }

    &__availability {
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.02em;
        margin: 15px 0 25px 0;
        text-align: center;
        color: #7D89A1;
    }

    &__modules-item-count {
        font-weight: 500;
        font-size: 26px;
        line-height: 18px;
        color: #538BFF;
        margin-right: 15px;
    }

    &__modiles-list {
        margin: 15px 0;
    }

    &__content-list {
        margin: 15px 0;

        &.additionalList {
            @media only screen and (min-width: 767px) {
                display: none
            }
        }

        &.mobileHidden {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        &.collapsed {
            @media only screen and (min-width: 767px) {
                max-height: 0;
                overflow: hidden;
            }

            @media only screen and (max-width: 767px) {
                max-height: 0;
                overflow: hidden;
                transition: max-height 300ms;

                &.isVisible {
                    max-height: 1000px;
                }
            }
        }
    }

    &__content-list-collapser {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid #D1D6E7;
        border-radius: 20px 20px 0 0;
        padding: 20px 40px 20px 20px;
        cursor: pointer;
        color: #292935;
        margin-bottom: 15px;

        &.gray {
            @media only screen and (max-width: 767px) {
                background: #F3F6FC;
            }
        }

        @media only screen and (min-width: 767px) {
            display: none
        }

        &:after {
            background: url("/images/course-landing/arrow-bottom-gray.svg") no-repeat;
            width: 13px;
            height: 7px;
            position: absolute;
            top: 50%;
            margin-top: -4px;
            right: 18px;
            content: '';
        }
    }

    &__content-image {

        &.x-large {
            img {
                position: relative;
                left: -16px;
                max-width: 64px;
            }
        }
        &.large {
            img {
                position: relative;
                left: -8px;
                max-width: 56px;
            }
        }

        img {
            max-width: 45px;
        }
    }

    &__content-image {
        width: 40px;
    }

    &__content-list-item {
        display: flex;
        gap: 19px;
        align-items: center;
        border: 1px solid #D1D6E7;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #292935;
        border-top: 0;

        &:first-child {
            border-radius: 20px 20px 0 0;
            border-top: 1px solid #D1D6E7;
        }

        &.no-mobile-radius {
            @media only screen and (max-width: 767px) {
                border-radius: 0 0 0 0;
            }
        }

        &:last-child {
            border-radius: 0 0 20px 20px;
        }

        &.noBorderRadius {
            border-radius: 0;
        }

        .small {
            font-size: 10px;
            line-height: 14px;
            display: block;
            margin-top: 5px;
        }

        strong, b {
            font-weight: 600;

            &.blue {
                color: $main-blue;
            }
        }

        &.gray {
            background: #F3F6FC;
        }

        &.mobileGray {
            @media only screen and (max-width: 767px) {
                background: #F3F6FC;
            }
        }

        &.mobileHidden {
            display: none;

            @media only screen and (min-width: 767px) {
                display: flex;
            }
        }

        &.fixedHeight115 {
            @media only screen and (min-width: 767px) {
                height: 85px;
            }
        }

        &.fixedHeight230 {
            @media only screen and (min-width: 767px) {
                height:198px;
            }
        }

        &.transparent {
            position: relative;
            overflow: hidden;

            &:after {
                width: 100%;
                height: 100%;
                background: #e4e8f5;
                position: absolute;
                display: block;
                opacity: 0.8;
                content: '';
                left: 0;
            }
        }

        &.minus {
            position: relative;

            &:before {
                position: absolute;
                background: url("/images/course-landing/minus-icon-gray-large.svg");
                width: 22px;
                height: 22px;
                left: calc(50% - 11px);
                top: calc(50% - 11px);

                @media only screen and (min-width: 767px) {
                    content: '';
                }
            }

            &:after {
                width: 100%;
                height: 100%;
                background: #fff;
                position: absolute;
                display: block;
                opacity: 0.8;
                left: 0;

                @media only screen and (max-width: 767px) {
                    content: '';
                }
            }

            @media only screen and (min-width: 767px) {
                .format-item__content-text {
                    opacity: 0;
                }

                .format-item__content-image {
                    opacity: 0;
                }
            }
        }

        &.plus {
            position: relative;

            &:before {
                position: absolute;
                background: url("/images/course-landing/plus-icon-blue-large.svg");
                width: 22px;
                height: 22px;
                left: calc(50% - 11px);
                top: calc(50% - 11px);

                @media only screen and (min-width: 767px) {
                    content: '';
                }
            }

            @media only screen and (min-width: 767px) {
                .format-item__content-text {
                    opacity: 0;
                }

                .format-item__content-image {
                    opacity: 0;
                }
            }
        }
    }

    &__plus-content-list {
        font-size: 40px;
        line-height: 120%;
        text-align: center;
        color: $landingBlue;

        @media only screen and (min-width: 767px) {
            display: none;
        }

        &.handler {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            border: 1px solid #D1D6E7;
            border-radius: 20px 20px 0 0;
            padding: 20px 30px;
            position: relative;
            cursor: pointer;
            margin-bottom: 10px;

            &.isOpened:after {
                transform: rotate(180deg);
            }

            &:after {
                position: absolute;
                width: 13px;
                height: 7px;
                background: url('/images/course-landing/format-images-3/arrow.svg');
                content: '';
                right: 20px;
            }

            @media only screen and (min-width: 767px) {
                display: none;
            }

        }
    }

    &__price-block {
        margin: 40px 0 25px 10px;
    }

    .es-button--link.es-button--link.lh20 {
        line-height: 20px;
    }

    &__price-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 6px;
        margin-top: 15px;
    }

    &__price-old {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-decoration-line: line-through;
        color: $landingGray;
        margin: 2px 0;
        margin-left: 8px;
    }

    &__price-current {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #292935;

        .small {
            font-size: 20px;
        }
    }

    &__discleimer {
        letter-spacing: 0.02em;
        color: rgba(46, 59, 95, 0.4);
        font-weight: 500;
        font-size: 10px;
        line-height: 120%;
        margin: 20px 0 20px;
    }

    .es-button {
        border-radius: 40px;
        text-transform: uppercase;

        &:disabled {
            background: #D1D6E7;
        }
    }
}
</style>
