<template>
    <div class="course-page">
        <CourseHeader></CourseHeader>
        <CourseDescription></CourseDescription>
        <CourseTargetGroup></CourseTargetGroup>
        <CoursePollResult></CoursePollResult>
        <CourseChooseFormat></CourseChooseFormat>
        <CoursePaymentFAQ></CoursePaymentFAQ>
        <CourseQuestionsForm></CourseQuestionsForm>
        <CourseProgram></CourseProgram>
        <CourseAboutAuthor></CourseAboutAuthor>
        <CourseReviews></CourseReviews>
        <CourseFAQ></CourseFAQ>
        <Footer></Footer>
    </div>
</template>

<script>
import CourseHeader from '@/components/secondary-pages/course-landing/CourseHeader.vue';
import CourseDescription from '@/components/secondary-pages/course-landing/CourseDescription.vue';
import CourseTargetGroup from '@/components/secondary-pages/course-landing/CourseTargetGroup.vue';
import CoursePollResult from '@/components/secondary-pages/course-landing/CoursePollResult.vue';
import CourseChooseFormat from '@/components/secondary-pages/course-landing/CourseChooseFormat.vue';
import CoursePaymentFAQ from '@/components/secondary-pages/course-landing/CoursePaymentFAQ.vue';
import CourseQuestionsForm from '@/components/secondary-pages/course-landing/CourseQuestionsForm.vue';
import CourseProgram from '@/components/secondary-pages/course-landing/CourseProgram.vue';
import CourseAboutAuthor from '@/components/secondary-pages/course-landing/CourseAboutAuthor.vue';
import CourseReviews from '@/components/secondary-pages/course-landing/CourseReviews.vue';
import CourseFAQ from '@/components/secondary-pages/course-landing/CourseFAQ.vue';
import Footer from '@/components/secondary-pages/course-landing/Footer.vue';

export default {
  name: 'BaseConsultatantList',
  components: {
    CourseHeader,
    CourseDescription,
    CourseTargetGroup,
    CoursePollResult,
    CourseChooseFormat,
    CoursePaymentFAQ,
    CourseQuestionsForm,
    CourseProgram,
    CourseAboutAuthor,
    CourseReviews,
    CourseFAQ,
    Footer,
  },
  data() {
    return {
    };
  },
  computed: {
    isPaymentPage() {
      return this.$route.name === 'ConsultatantPayment';
    },
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#f2f5fc');
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

body {
    background: red;
}

.course-page {
}
</style>
