<template>
    <div class="course-header" id="hiddenTop">
        <div class="course-header__title-small">Авторский курс Елены Сягло</div>
        <div class="course-header__title">Ключ к <br> материнству</div>

        <div class="course-header__info">
            <div class="course-header__info-item">
                <div class="course-header__info-header">Формат</div>
                Онлайн
            </div>
            <div class="course-header__info-item">
                <div class="course-header__info-header">Длительность</div>
                6 недель
            </div>
            <div class="course-header__info-item isActive">
                <div class="course-header__info-header">Старт</div>
                1 июля
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseHeader',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-header {
    text-align: center;
    min-height: 400px;
    background: url("/images/course-landing/course-header-bg.jpg") 50% 95px no-repeat #f3f6fd;
    background-size: 336px;
    padding: 40px 23px 30px 23px;

    @media only screen and (min-width: 1024px) {
        padding-bottom: 120px;
    }

    @media only screen and (min-width: 767px) {
        background:
                url("/images/course-landing/course-header-bg-lg.jpg") 50% 103px no-repeat #f3f6fd;
        background-size: 776px;
    }

    @media only screen and (min-width: 1024px) {
        background:
                url("/images/course-landing/course-header-bg-lg.jpg") 50% 60px no-repeat #f3f6fd;
        background-size: 1024px;
    }

    &__title-small {
        font-size: 16px;
        line-height: 24px;
        color: #7D89A1;
        margin-bottom: 10px;

        @media only screen and (min-width: 767px) {
            font-weight: 400;
            font-size: 26px;
            line-height: 150%;
            margin-bottom: 20px;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 35px;

        @media only screen and (min-width: 767px) {
            font-size: 52px;
            line-height: 110%;

            br {
                display: none;
            }
        }
    }

    &__info {
        display: flex;
        background: #FBFBFE;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 15px;
        color: $landingBlue;
        font-weight: 600;
        font-size: 14px;
        line-height: 48px;
        margin: 253px auto 0 auto;
        max-width: 512px;

        @media only screen and (min-width: 767px) {
            margin-top: 500px;
        }

        @media only screen and (min-width: 1024px) {
            margin-top: 617px;
        }
    }

    &__info-item {
        border-radius: 15px;
        position: relative;
        height: 48px;
        width: 100%;

        &.isActive {
            background: $landingBlue;
            color: $white;
        }
    }

    &__info-header {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -25px;
        color: #7D89A1;
    }
}
</style>
