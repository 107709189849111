<template>
    <div class="wlm-review">
        <div class="landing-title-group">
            <div id="reviewSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ
            </div>
            <div class="landing-title-group__mid">Видео-отзывы о курсе</div>
        </div>

        <div class="wlm-review__video" @click="console.log(1)">
            <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/9-m5bQQw07E"
                    frameborder="0"
                    modestbranding='1'
                    allowfullscreen

            ></iframe>
        </div>

        <div class="wlm-review__insta">
            Больше отзывов в инстаграмм <br>
            <a href="https://www.instagram.com/elena.siaglo">
                @elena.siaglo
            </a>
        </div>
    </div>
</template>

<script>

export default {
  name: 'WelcomeLandingReviews',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";

.wlm-review {
    padding: 30px 24px 50px 24px;
    background: $light-blue;
    position: relative;
    z-index: 5;

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 20px 24px;
    }

    &__video {
        max-width: 320px;
        margin: auto;
        position: relative;

        &:before {
            display: block;
            content: "";
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__insta {
        text-align: center;
        margin: 40px 0 1px 0;
        line-height: 21px;
        font-size: 14px;

        a {
            text-decoration: none;
            color: $black;
        }
    }
}
</style>
