<template>
    <div class="registration-header">
        <div class="registration-header__content">
            <div class="registration-header__title-small">Регистрация</div>
            <div class="registration-header__title-large">на вебинар</div>
            <div class="registration-header__descr">
                После вебинара вы поверите<br>
                в беременность и роды<br>
                без страхов и тревог
            </div>

            <div class="registration-header__start-label">
                <div class="registration-header__start-label-inc">
                    <div class="registration-header__start-label-title">Начало</div>
                    <div class="registration-header__start-label-date">{{getDate()}}</div>
                    <div class="registration-header__start-label-month">{{getMonth()}}</div>
                    <div class="registration-header__start-label-today">сегодня</div>
                </div>
            </div>

            <RegistrationStartCounter></RegistrationStartCounter>
        </div>
    </div>
</template>

<script>
import RegistrationStartCounter from '@/components/secondary-pages/webinar-registration/RegistrationStartCounter.vue';

export default {
  name: 'SelectTime',
  components: {
    RegistrationStartCounter,
  },
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
    getDate() {
      return new Date().getDate();
    },
    getMonth() {
      const month = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      return month[new Date().getMonth()];
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.registration-header {
    background: url("/images/registration-webinar/header-bg.jpg") repeat-x;
    text-align: center;
    color: $white;
    position: relative;
    overflow: hidden;

    &__start-label {
        position: absolute;
        left: 35px;
        width: 140px;
        height: 140px;
        border: dashed 1px $white;
        padding: 6px;
        border-radius: 50%;
        top: 250px;

        @media only screen and (min-width: 767px) {
            left: 50%;
            margin-left: -290px;
            top: 190px;
            width: 168px;
            height: 168px;
        }
    }

    &__start-label-today {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        background: #CC4FC7;
        border-radius: 8px;
        padding: 0 15px;
        display: inline-block;
        color: $white;
    }

    &__start-label-inc {
        width: 100%;
        height: 100%;
        background: $white;
        border-radius: 50%;
        text-align: center;
        color: #155FFF;
        padding-top: 18px;
        box-sizing: border-box;
    }

    &__start-label-title {
        font-size: 14px;
    }

    &__start-label-date {
        font-size: 45px;
        font-weight: 900;
        margin-top: 0;
    }

    &__start-label-month {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 4px 0;
    }

    @media only screen and (min-width: 767px) {
        padding: 60px 0 0 0;
    }

    &__content {
        padding: 86px 0 0 0;
        min-height: 400px;
        position: relative;
        z-index: 4;

        @media only screen and (min-width: 767px) {
            padding: 100px 0 0 0;
        }
    }

    &:after {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background: url("/images/registration-webinar/clouds-mobile.png") 50% 100% no-repeat ;
        background-size: 100%;
        content: '';
        left: 0;
        z-index: 1;

        @media only screen and (min-width: 767px) {
            bottom: -140px;
            background: url("/images/registration-webinar/clouds-desktop.png") 50% 100% no-repeat ;
        }
    }

    &:before {
        width: 100%;
        position: absolute;
        bottom: 150px;
        height: 100%;
        background: url("/images/registration-webinar/elena-mobile.png") 67% 100% no-repeat ;
        background-size: 285px;
        content: '';
        right: 0;
        z-index: 2;

        @media only screen and (min-width: 767px) {
            background-size: 385px;
            background-position: 70% 100%;
        }
    }

    &__title-small {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 5px;
    }

    &__title-large {
        font-weight: 600;
        font-size: 34px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__descr {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 230px;
    }
}
</style>
