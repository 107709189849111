<template>
    <div class="footer">
        <div class="footer__to-top" @click="scrollTop()"></div>
        <div class="footer__content">
            <div class="footer__left-side">
                <a href="https://course.elena.shop/page4" target="_blank">
                    Политика конфиденциальности
                </a><br>
                <a href="https://course.elena.shop/page5" target="_blank">
                    Договор-оферта
                </a>
            </div>
            <div class="footer__right-side">
                <div class="footer__right-side-inc-1">
                    ИП ГОСТЕВА ЕЛЕНА ВЯЧЕСЛАВОВНА,<br>
                    ИНН 673110487432,<br>
                    ОГРН 318673300023780,
                </div>
                <div class="footer__right-side-inc-2">
                    Р/С: 40802810959000005927<br>
                    Банк: ПАО Сбербанк<br>
                    БИК: 046614632<br>
                    К/С: 30101810000000000632<br>
                </div>
            </div>
        </div>
        <div class="footer__bottom">
            2024. Все права защищены.
        </div>
    </div>
</template>

<script>

export default {
  name: 'Footer',
  components: {},
  methods: {
    scrollTop() {
      const hiddenElement = document.querySelector('#hiddenTop');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
@import "../../../assets/scss/common/variables";

.footer {
    min-height: 74px;
    background: #1d1d1d;
    color: #C5CBD7;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 150%;
    position: relative;

    a {
        color: $white;
    }

    div {
        width: 100%;
    }

    &__right-side-inc-1 {
        margin-bottom: 20px;
    }

    div.footer__to-top {
        width: 38px;
        height: 38px;
        right: 15px;
        top: -25px;
        background: rgba(41, 41, 53, 0.65)
        url("/images/course-landing/arrow-white-top.svg") no-repeat 50% 50%;
        border: 1px solid #7D89A1;
        backdrop-filter: blur(4px);
        border-radius: 50%;
        position: absolute;
        cursor: pointer;

        @media only screen and (min-width: 767px) {
            display: none;
        }

        &:after {
            position: absolute;
            width: calc(100% + 10px);
            bottom: -18px;
            left: -5px;
            content: 'Наверх';
            font-weight: 500;
            font-size: 10px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.02em;
        }
    }

    &__left-side {
        margin-bottom: 25px;
    }

    &__right-side {
        position: relative;
        left: 20px;

        @media only screen and (min-width: 1024px) {
            display: flex;
        }
    }

    &__bottom {
        padding: 15px 0 20px 0;
        border-top: solid #595967 1px;
        text-align: center;
    }

    &__content {
        font-size: 10px;
        line-height: 17px;
        padding: 20px;
        text-align: center;

        @media only screen and (min-width: 767px) {
            display: flex;
            text-align: left;
            max-width: 520px;
            margin: 0 auto;
            justify-content: space-between;
            font-size: 12px;
            line-height: 150%;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 920px;
        }
    }
}

</style>
