<template>
  <div class="landing-page">
    <BaseTelegramMeeting></BaseTelegramMeeting>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseTelegramMeeting from '@/components/secondary-pages/BaseTelegramMeeting.vue';

export default {
  name: 'HomeWTelegramMeeting',
  components: {
    BaseTelegramMeeting,
  },
};
</script>
