<template>
    <div class="base-marathon">
        <Header></Header>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/secondary-pages/club-marathon/Header.vue';
import Footer from '@/components/general/Footer.vue';

export default {
  name: 'BaseMarathon',
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      clubFull: this.$route.name === 'ClubMarathonFull',
    };
  },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.base-marathon {
    font-family: 'Montserrat';
}
</style>
