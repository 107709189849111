<template>
    <div class="consultant-header">
        <div class="consultant-header__content">
            <h1 >
                Личная консультация психолога
            </h1>
            <div class="consultant-header__descr">
                Профессиональная помощь в<br>
                решении проблемной ситуации
            </div>
            <div class="consultant-header__start-block">
                <button class="es-button" @click="scrollToList()">
                    Выбрать психолога
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ConsultantLandingHeader',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    scrollToList() {
      const hiddenElement = document.querySelector('#chooseSectionScrollMarker');
      hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";

.consultant-header {
    min-height: 520px;
    background: url("/images/consultant-page/consultant-page-header.jpg") center center;
    background-size: cover;
    margin-top: 60px;
    padding-top: 200px;
    box-sizing: border-box;
    text-align: center;
    color: $white;
    position: relative;
    padding-bottom: 1px;

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 360px;
        width: 100%;
        content: '';
        background: linear-gradient(1.82deg, rgba(0, 0, 0, 0.35) 70.27%, rgba(0, 0, 0, 0) 89.42%);
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    h1 {
        font-weight: 600;
        font-size: 46px;
        color: $white;
        line-height: 54px;
        letter-spacing: 1px;
        position: relative;

        @media only screen and (max-width: 767px) {
            font-size: 34px;
            line-height: 40px;
        }

        &:before {
            width: 180px;
            height: 98px;
            border: 1px solid rgba(255, 255, 255, 0.44);
            content: '';
            border-radius: 50%;
            transform: rotate(33.89deg);
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -180px;

            @media only screen and (min-width: 767px) {
                margin-left: 0;
                left: 50px;
            }
        }
    }

    &__descr {
        font-size: 15px;
        line-height: 21px;
        max-width: 500px;
        margin: 15px auto;
        font-weight: 500;
        width: calc(100% - 80px);
    }

    &__start-block {
        text-align: center;

        button {
            display: inline-block;
            width: auto;
            padding: 0 45px;
            cursor: pointer;
            font-size: 18px;
        }
    }
}
</style>
