<template>
    <div class="insta-page" :class="{'extraPadding': isAuthed}">
        <div class="insta-page__banner" v-if="IsWebinarBannerEnabled">
            <div id="forFlow3"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="insta-page__banner-image">
                <img src="/images/landing/insta-page-banner-4.jpg">
            </div>

            <div class="insta-page__banner-content">
                <div class="landing-title-group">
                    <div class="landing-title-group__small">
                        Поток исцеления
                    </div>
                    <div class="landing-title-group__mid">
                        Волшебный эфир, который разделит вашу жизнь на до и после
                    </div>
                </div>

                <div class="insta-page__form">
                    <router-link to="/flow4" class="es-button">Зарегистрироваться</router-link>
                </div>
            </div>
        </div>
        <div class="insta-page__banner">
            <div id="forPregnancy"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="insta-page__banner-image">
                <img src="/images/landing/insta-page-banner-3.jpg">
            </div>

            <div class="insta-page__banner-content">
                <div class="landing-title-group">
                    <div class="landing-title-group__small">
                        Медитации для женщин
                    </div>
                    <div class="landing-title-group__mid">
                        Послушайте короткий фрагмент медитации бесплатно
                    </div>
                </div>

                <div class="insta-page__form">
                    <router-link to="/meditations" class="es-button">Слушать</router-link>
                </div>
            </div>
        </div>
        <div class="insta-page__banner no-margin-no-shadow">
            <div id="personalConsultation"
                 class="landing-title-group__hidden-marker"
            ></div>
            <ConsultationBanner></ConsultationBanner>
        </div>
    </div>
</template>

<script>
import ConsultationBanner from '@/components/general/ConsultationBanner.vue';

export default {
  name: 'Insta',
  components: {
    ConsultationBanner,
  },
  data() {
    return {
      IsWebinarBannerEnabled: false,
    };
  },
  computed: {
    isAuthed() {
      return this.$store.getters.getUserMail !== '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/landing";
@import "../../assets/scss/common/forms";

.insta-page {
    padding: 80px 24px 0 24px;

    &.extraPadding {
        padding-top: 120px;
    }

    .invite-banner {
        width: 100%;
    }

    &__course-title {
        font-weight: 600;
        font-size: 30px;
        line-height: 110%;
        margin-bottom: 25px;

        @media only screen and (min-width: 767px) {
            text-align: center;
        }
    }

    &__course-descr {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 25px;

        @media only screen and (min-width: 767px) {
            text-align: center;
        }
    }

    &__course-actions .es-button{
        font-size: 14px;
    }

    &__course-button-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #7D89A1;
        text-align: center;
    }

    &__course-image {
        margin: 0 -20px 25px -20px;
        padding: 0 20px;
        background: url("/images/course-banner-image-clouds.png") 50% 50% no-repeat;
        background-size: 90%;

        img {
            max-width: 100%;
        }

        @media only screen and (min-width: 767px) {
            background-size: 100%;
        }
    }

    &__banner {
        color: $white;
        margin-bottom: 24px;
        display: flex;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        .landing-title-group__hidden-marker {
            top: 0;
        }

        &:after {
            position: absolute;
            content: '';
            width: 100%;
            bottom: 0;
            left: 0;
            height: 100%;
            background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
            z-index: 1;
        }

        &.no-margin-no-shadow {
            margin: -24px 0 0 0;

            &:after {
                display: none;
            }
        }

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }

        .landing-title-group__mid {
            font-size: 24px;
            line-height: 30px;
            text-align: left;

            @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .landing-title-group__small {
            text-align: left;
            font-size: 14px;
            margin-bottom: 10px;

            &.yellow {
                background: $main-yellow;
                display: inline-block;
                padding: 0 10px;
                line-height: 22px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    &__banner-content {
        padding: 1px 24px 14px 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        z-index: 2;

        &.blue {
            background: $main-blue;
        }

        .es-button {
            max-width: 250px;

            @media only screen and (max-width: 767px) {
                max-width: none;
            }
        }
    }

    &__banner-image {
        margin: 0;
        overflow: hidden;
        width: 100%;
        max-height: 400px;
        flex-shrink: 0;
        background: $white;

        @media only screen and (max-width: 767px) {
            margin: 0;
            max-height: 250px;
            width: auto;
        }

        img {
            object-fit: cover;
            display: block;
            width: 100%;

            &.isMobile {
                display: none;

                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }

            &.isDesktop {
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
    }

    &__banner-text {
        font-size: 14px;
        line-height: 21px;
    }
}
</style>
