<template>
    <div class="available-list">
        <BasePayment></BasePayment>
        <BasePaymentGetCourse></BasePaymentGetCourse>

        <div class="available-list__course gray">
            <div class="available-list__course-header banner"
                 @click="toggleBannerContent()"
                 :class="{'isOpened': isBannerContentVisible}"
            >
                <div class="available-list__course-header-toogle-handler"
                     :class="{'isActive': isBannerContentVisible}"
                ></div>
                <div class="available-list__course-header-title">
                    КЛУБ EVO
                </div>

                <div class="available-list__course-descr">
                    Эволюция на пути<br>
                    к твоему счастливому<br>
                    материснтву
                </div>
            </div>

            <div class="available-list__course-content"
                 :class="{'visible': isBannerContentVisible}"
            >
                <div class="available-list__course-actions">
                    <a href="https://evo.elena.shop/?utm_source=elenashop-lk&utm_medium=banner&utm_campaign=evo_motherhood"
                       class="es-button black"
                       target="_blank"
                    >
                        Узнать подробнее
                    </a>
                    <div class="available-list__course-button-text">
                        Вступить по акции на 7 дней за 900 рублей.
                    </div>
                </div>
            </div>
        </div>

        <div class="available-list__course brown">
            <BaseAudioPlayerBlue
                    v-if="isPlayerVisible"
            ></BaseAudioPlayerBlue>

            <div class="available-list__course-header"
                 @click="toggleCourseContent()"
            >
                <div class="available-list__course-header-toogle-handler"
                     :class="{'isActive': isCourseContentVisible}"
                ></div>
                <div class="available-list__course-header-title">
                    Ваши <br> покупки
                </div>
                <div class="available-list__course-header-info">
                    {{getCounts.payed}} товаров из
                    {{getCounts.total}}
                </div>
            </div>
            <div class="available-list__course-content"
                :class="{'visible': isCourseContentVisible}"
            >
                <SinglePaymentCartItem
                        v-for="(groupData, index) in fullMeditationInfo"
                        :data="groupData"
                        :key="index"
                        :dataKey="index"
                        :hideNotPayed="isNotPayedInCourseHidden"
                ></SinglePaymentCartItem>

                <div class="available-list__course-content-counts">
                    Доступны {{getCounts.payed}} из {{getCounts.total}} товаров
                </div>

                <button class="es-button"
                        @click="toggleCourseNotPyaedVisability()"
                        v-if="getCounts.payed !== getCounts.total"
                >
                    {{courseShowMoreBtnText}}
                </button>

                <div class="available-list__course-toggler"
                     @click="toggleCourseContent()"
                ></div>
            </div>
        </div>
        <BaseCart></BaseCart>
    </div>
</template>

<script>
import BasePayment from '@/components/payment/BasePayment.vue';
import BasePaymentGetCourse from '@/components/payment/BasePaymentGetCourse.vue';
import BaseCart from '@/components/general/BaseCart.vue';
import SinglePaymentCartItem from '@/components/secondary-pages/welcome-landing/SinglePaymentCartItem.vue';
import BaseAudioPlayerBlue from '@/components/meditation/BaseAudioPlayerBlue.vue';

export default {
  name: 'AvailableList',
  components: {
    BasePayment,
    BasePaymentGetCourse,
    SinglePaymentCartItem,
    BaseCart,
    BaseAudioPlayerBlue,
  },
  data() {
    return {
      isBannerContentVisible: false,
      isCourseContentVisible: false,
      isNotPayedInCourseHidden: true,
      isNewMeditationContentVisible: false,
      isNotPayedInNewMeditationsHidden: true,
    };
  },
  computed: {
    isPlayerVisible() {
      return this.$store.state.player.isVisible;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    newFullMeditationInfo() {
      return this.$store.state.meditations.newMeditationsList;
    },
    fullMeditationInfo() {
      return this.$store.state.meditations.meditationsList;
    },
    meditationInfoSingle() {
      return this.$store.getters.getMeditationsInfoForSingle;
    },
    getCounts() {
      return {
        payed: this.meditationInfoSingle.payedMeditations.length,
        total:
        this.meditationInfoSingle.payedMeditations.length
        + this.meditationInfoSingle.noPayedMeditations.length,
      };
    },
    courseShowMoreBtnText() {
      let text = 'Докупить остальные ';

      if (this.getCounts.payed === 0) {
        text = 'Купить';
      }

      if (!this.isNotPayedInCourseHidden) {
        text = 'Показать только купленные';
      }

      return text;
    },
  },
  methods: {
    toggleCourseContent() {
      this.isCourseContentVisible = !this.isCourseContentVisible;
    },
    toggleBannerContent() {
      this.isBannerContentVisible = !this.isBannerContentVisible;
    },
    toggleCourseNotPyaedVisability() {
      this.isNotPayedInCourseHidden = !this.isNotPayedInCourseHidden;
    },
    toggleNewMediContent() {
      this.isNewMeditationContentVisible = !this.isNewMeditationContentVisible;
    },
    toggleNewMediNotPyaedVisability() {
      this.isNotPayedInNewMeditationsHidden = !this.isNotPayedInNewMeditationsHidden;
    },

  },
};
</script>

<style  lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/landing";
 @import "../../assets/scss/common/forms";

 .playerIsActive .available-list{
     position: relative;
     z-index: 13;
 }

.available-list {
    padding: 13px;
    background: $white;
    padding-top: 25px;
    overflow: hidden;

    &__course-descr {
        font-size: 13px;
        line-height: 19px;
    }

    &__course-actions .es-button{
        font-size: 14px;
        max-width: 70%;
        margin: 5px auto 25px auto;
    }

    &__course-button-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #7D89A1;
        text-align: center;
    }

    &__course-content-counts {
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 21px;
        padding: 20px 0 12px 0;
    }

    &__course-content-aatantion {
        padding: 20px 20px 0 20px;
        font-size: 14px;
        line-height: 20px;
    }

    &__course {
        border-radius: 10px;
        background: #f2e1d0;
        position: relative;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        .module-card__item-title {
            margin-bottom: 8px;
            display: block;
        }

        &.gray {
            background: #f2f5fc;
            overflow: hidden;
            border: solid #292935 1px;

            .available-list__course-header {
                background: #f2f5fc url("/images/courae_banner_PC_2.png") no-repeat 85% 5px;
                background-size: 37%;
                color: $black;
                margin-top: 14px;
                padding-top: 1px;
                height: 150px;

                @media only screen and (min-width: 460px) {
                    background: #f2f5fc url("/images/courae_banner_PC_2.png") no-repeat 93% -2px;
                    background-size: contain;
                    margin-bottom: 15px;
                }

                &.isOpened {
                    position: static;
                }
            }

            .available-list__course-header-toogle-handler {
                background: rgba(65, 65, 65, 0.2);
                z-index: 4;

                &:before {
                    background: url("/images/arrow-black-small.svg") no-repeat;
                }

                &.isActive {
                    bottom: -22px;
                    top: auto;
                    left: 50%;
                    margin-left: -22px;

                    &:before {
                        left: 19px;
                        top: 11px;
                    }
                }
            }

            .available-list__course-header-title {
                line-height: 28px;
                margin-top: 15px;
                font-size: 22px;
                margin-bottom: 10px;
            }
        }

        &.purple {
            background: #f6e6f8;

            .available-list__course-header {
                background:
                        #ccabcf url("/images/cabinet/cabinet-course-image-2.png") no-repeat 95% 0;
                background-size: 185px;
            }

            .available-list__course-header-info {
                background: #c29cc6;
            }

            .module-card__title {
                display: none;
            }

            .module-card__list {
                padding-top: 0;
            }
        }
    }

    &__course-header {
        background: #cea376 url("/images/cabinet/cabinet-course-image.png") no-repeat 95% 0;
        background-size: 185px;
        color: $white;
        position: relative;
        height: 150px;
        padding: 20px;
        border-radius: 10px;
    }

    &__course-header-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        margin: 20px 0;
    }

    &__course-header-subtitle {
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        margin-top: 15px;
    }

    &__course-header-info {
        background: #c79968;
        line-height: 27px;
        padding: 0 15px;
        font-size: 12px;
        border-radius: 10px;
        display: inline-block;
    }

    &__course-header-toogle-handler {
        position: absolute;
        width: 45px;
        height: 45px;
        top: 50%;
        right: -20px;
        background: rgba(255,255,255,0.36);
        border-radius: 50%;
        margin-top: -22px;

        &:before {
            background: url("/images/arrow-white-small.svg") no-repeat;
            width: 5px;
            height: 8px;
            content: '';
            position: absolute;
            left: 12px;
            top: 19px;
            transform: rotate(90deg) scale(1.1);
        }

        &.isActive {
            &:before {
                transform: rotate(-90deg) scale(1.1);
            }
        }
    }

    &__course-content {
        max-height: 0;
        overflow: hidden;
        transition: all 300ms;
        padding: 0 8px;
        position: relative;

        &.visible {
            max-height: 3800px;
            padding-bottom: 30px;
        }
    }

    &__course-toggler {
        position: absolute;
        width: 45px;
        height: 45px;
        bottom: -20px;
        left: 50%;
        background: rgba(255,255,255,0.36);
        border-radius: 50%;
        margin-left: -22px;

        &:before {
            background: url("/images/arrow-right-small.svg") no-repeat;
            width: 5px;
            height: 8px;
            content: '';
            position: absolute;
            left: 20px;
            top: 12px;
            transform: rotate(-90deg) scale(1.1);
        }
    }

    .module-card__content {
        background: transparent;
    }

    .module-card__title {
        padding: 0 20px;
    }

    .module-card__item {
        border-radius: 10px;
        background: #DFE7FF;

        &.isPayed {
            background: rgba(255,255,255,0.7);
        }
    }
}
</style>
